/* eslint-disable camelcase */
import React, { Fragment, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Col, Row, Icon,Tooltip } from "../../common/UIComponents";
import I18n from "../../common/I18n";
import { formatByTimeConfig } from "../../helpers/common";
import AppConfig from "../../config/AppConfig";
import { OrgContext } from "../../context/OrgContext";
import { isEmpty } from "../../common/Common";

const OrderAppointments = (props) => {
  const { appointments, timezone, showConfirmedTime, showPDLabels = false, locations = [], isMultiStop = false, screen, tooltip } = props;
  const { orgSettings : organizationSettings  }= useContext(OrgContext);
  const [isMilitaryTime , setIsMilitaryTime] = useState(false);
  useEffect(() => {
    if (organizationSettings && organizationSettings.is_military_time ===
      "true") {
      setIsMilitaryTime(true);
    } else {
      setIsMilitaryTime(false);
    }
  }, [ organizationSettings ]);

  const getLocTypeLabel = (appointment) => {
    if(!isEmpty(appointment.type_of_loc)){
      if(appointment.type_of_loc === 'PICKUP'){
        return "PICKUP: ";
      }else{
        return "DELIVERY: ";
      }
    }
    return "";
  }

  const timeFormat = !_.isEmpty(screen) ? "HH:mm" : "DD MMM HH:mm";
  const defaultFormat = !_.isEmpty(screen) ? "hh:mm A" : "DD MMM hh:mm A";

  return (
  <Row>
    <Col xs={24} sm={24} md={24} lg={24}>
      {
        appointments
            .map((appointment, index) => (
              <Row key={appointment.slot_name}>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Row>
                    <Col sm={24} xs={24} md={24} lg={24}>
                      <div className="smallContent">
                        <Tooltip
                          overlayStyle={{ width: 200 }}
                          mouseEnterDelay={AppConfig.tooltipDelay}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          placement="top"
                          title={`${
                            appointment.start_datetime
                              ? formatByTimeConfig(moment.utc(appointment.start_datetime) , isMilitaryTime , "DD MMM HH:mm" , "DD MMM hh:mm A") : ""
                          } ${
                            appointment.end_datetime ? formatByTimeConfig(moment.utc(appointment.end_datetime) , isMilitaryTime , "HH:mm" , "hh:mm A") : ""
                          }`}
                          visible={tooltip}
                        >
                          <span>
                            {isMultiStop ? <strong>{`Stop ${index + 1}- `}</strong> : ''}{showPDLabels === true ? getLocTypeLabel(appointment) : ''}{appointment.start_datetime
                              ? formatByTimeConfig(moment.utc(appointment.start_datetime) , isMilitaryTime , timeFormat , defaultFormat) : "NA"}{" "}
                            -{" "}
                            {appointment.end_datetime
                              ? formatByTimeConfig(moment.utc(appointment.end_datetime) , isMilitaryTime , "HH:mm" , "hh:mm A") : "NA"}
                          </span>{" "}
                          <strong>{timezone}</strong>
                        </Tooltip>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))
      }
    </Col>
  </Row>

  );
}

export default OrderAppointments;

OrderAppointments.propTypes = {
  appointments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  timezone: PropTypes.string.isRequired,
  showConfirmedTime: PropTypes.bool.isRequired,
};

OrderAppointments.defaultProps = {
  screen : null,
  tooltip : false
}