import React from "react";
import moment from "moment";
import _, { isEmpty } from "lodash";
import { Icon, Row, Col, Popover, Typography } from "../../common/UIComponents";
import { Link } from "react-router-dom";
import BaseList from "../BaseList";
import {  ellipseText } from "../../common/Common";
import { momentTime } from "../../helpers/date_functions";
import { formatByTimeConfig } from "../../helpers/common";
import DisplayTime from "../common/DisplayTime";
import AppConfig from "../../config/AppConfig";
import CopyableContent from "../common/CopyableContent";
import I18n from "../../common/I18n";
import expandIcon from '../../../assets/images/file-expand-icon.png'; // Import expand icon
import activeExpandIcon from '../../../assets/images/activeExpand.png';
import disabledExpandIcon from '../../../assets/images/disabledExpand.png';
import BaseModal from "../BaseModal";
import BillingActivitiesModal from "./BillingActivitiesModal";

class InvoiceActivitiesList extends BaseList {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      currentActivityType: '',
      differences: [],
      emails: [],
    };
    this.columns = [];
    this.setColumns(props);
  }
  setColumns = (props) => {
    const columns = [      
      {
        title: I18n.t("general.table_s_no"),
        key: "index",
        render: (text, record, index) => index + 1,
        width: 55,
      },
      {
        key: "activity_type",
        title: "Description",
        dataIndex: "description",
        // className: "textCaptilize",
        width: 300,
      },
      {
        key: "activity_by",
        title: I18n.t("general.activity_by"),
        dataIndex: "activity_by",
        className: "textCaptilize",
        width: 150,
      },
      {
        key: "activity_on",
        title: I18n.t("general.activity_on"),
        dataIndex: "activity_on",
        render: (data) =>
          data
          ? 
          <DisplayTime
          dateTimeString={data}
          timeFormat={AppConfig.dateTimeFormat}
          displayWithDate={true}
          isUTC={false}
          />
            : "",
        sorter: (a, b) => new Date(a.created_on) - new Date(b.created_on),
        width: 200,
      },
      {
        key: "remark",
        title: I18n.t("deductions.remark"),
        dataIndex: "remark",
        className: "textCaptilize",
        width: 200,
      },
      {
        key: "detail_view",
        title: "Detail View",
        render: (text, record) => {
          if (
            record?.difference && !isEmpty(record?.activity_type) &&
            (Array.isArray(record?.difference?.invoice_difference) &&
            record?.difference?.invoice_difference.length > 0) || (record?.emails?.length>0)
          ) {
            return (
              <img
                src={activeExpandIcon}
                style={{ marginLeft: "10px", cursor: "pointer", width: "20px", height: "20px" }}
                alt="Detail View"
                onClick={() => this.handleExpandIconClick(record?.activity_type, record?.difference?.invoice_difference,record?.emails)}
              />
            );
          } else {
            return (
              <span
              style={{ marginLeft: "10px" }}
              >N/A</span>
            );
          }
        },
      }
    ];
    this.columns = [...columns];
  };

  handleExpand = (event, record) => {
    event.stopPropagation();
    const { expandedRowKeys } = this.state;
    const index = expandedRowKeys.indexOf(record?.activity_on);
    if (index > -1) {
      this.setState({
        expandedRowKeys: expandedRowKeys.filter((key) => key !== record?.activity_on),
      });
    } else {
      this.setState({
        expandedRowKeys: [...expandedRowKeys, record?.activity_on],
      });
    }
  };


  handleExpandIconClick = (actionType, differences, emails) => {
    this.setState({
        isModalVisible: true,
        currentActivityType: actionType,
        differences: differences,
        emails: emails
    });
};

  handleModalClose = () => {
    this.setState({
      isModalVisible: false,
      currentActivityType: '',
    });
  };

  render() {
    const { expandedRowKeys } = this.state;
    return (
      <div>
        {super.render()}
        <BillingActivitiesModal
          visible={this.state.isModalVisible}
          onCancel={this.handleModalClose}
          currentActionType={this.state.currentActivityType}
          differences={this.state.differences}
          emails={this.state.emails}
        />
      </div>
    );
  }
  componentWillReceiveProps(nextProps) {
    this.columns = [];
    this.setColumns(nextProps);
  }
}

export default InvoiceActivitiesList;
