import React from "react";

const NoteMessage = ({ message, styles = {} }) => {
  const { container, messageText, sup } = styles; 
  return (
    <div className="note" style={container}>
      <p className="note-message" style={messageText}>
        <sup style={{ color: "red", fontSize: 22, top: 3, ...sup }}>*</sup>{" "}
        <strong>Note:</strong> {message}
      </p>
    </div>
  );
};

export default NoteMessage;
