/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// TODO: import each component separetly from antd to avoid including entire antd into packs
// other wise use babel-plugin-import for this and remove style import in App.jsx. Check antd documentation
import React from 'react';
import PropTypes from 'prop-types';
import {
  Affix,
  Alert,
  Anchor,
  Avatar,
  Badge,
  Button,
  Col,
  Collapse,
  Card as DefaultCard,
  DatePicker,
  Divider,
  Dropdown,
  Drawer,
  Empty,
  Form,
  Icon,
  Input,
  InputNumber,
  Layout,
  List,
  Menu,
  Mentions,
  message,
  Modal,
  Pagination,
  Popconfirm,
  Popover,
  Progress,
  Radio,
  Row,
  Rate,
  Result,
  Select,
  Spin,
  Statistic,
  Steps,
  Switch,
  Table,
  Tabs,
  Tag,
  TimePicker,
  Timeline,
  Tooltip,
  Upload,
  Checkbox,
  Typography,
  Tree,
  TreeSelect,
  Skeleton,
  notification,
} from 'antd';
import Text from "antd/lib/typography/Text";

const {
  Content,
  Header,
  Sider,
} = Layout;

const { Option } = Select;
const { Search } = Input;
const { TextArea } = Input;
const InputGroup = Input.Group;
const { SubMenu } = Menu;


const defaultFormItemStyle = {
  marginBottom: 10,
  label: {
    fontWeight: 700,
  },
};

function FormItem(props) {
  return (
    <div style={props.style || defaultFormItemStyle} className={props.className || ""}>
      <span style={defaultFormItemStyle.label}>
        {props.require ? <sup className="textRed">*</sup> : ''} { props.label }{props.requireColumn !== false ? ':' : ''}
      </span>
      <br />
      {props.children}
    </div>
  );
}

function FormItemData(props) {
  return (
    <div style={props.style || defaultFormItemStyle} className={props.className || ""}>
      <span style={defaultFormItemStyle.label}>
        {props.require ? <sup className="textRed">*</sup> : ''} { props.label }{props.requireColumn !== false ? '' : ''}
      </span>
      <br />
      {props.children}
    </div>
  );
}
function MaterialFormItem(props) {
  return (
    <div style={props.style || defaultFormItemStyle} className={props.className || ""}>
      <span style={defaultFormItemStyle.label}>
        {props.require ? <sup className="textRed">*</sup> : ''} { props.label }{props.requireColumn !== false ? ':' : ''}
      </span>
      <div className='marginTop5'></div>
      <span>{props.children}</span>
    </div>
  );
}
FormItem.propTypes = {
  style: PropTypes.shape(),
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
FormItem.defaultProps = {
  style: defaultFormItemStyle,
  children: '',
};

MaterialFormItem.propTypes = {
  style: PropTypes.shape(),
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
MaterialFormItem.defaultProps = {
  style: defaultFormItemStyle,
  children: '',
};

const defaultLinkStyle = {
  cursor: 'pointer',
  // fontSize: '14px',
  fontWeight: '400',
};


const Link = props => (
  <span
    style={Object.assign({}, defaultLinkStyle, props.style)}
    onClick={props.onClick}
    className= {props.className ? `anchor_cursor ${props.className}` : "anchor_cursor"}
  >
    {props.children}
  </span>
);
Link.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  style: PropTypes.shape(),
  onClick: PropTypes.func.isRequired,
};
Link.defaultProps = {
  style: defaultLinkStyle,
};

const Card = props => (
  <DefaultCard
    headStyle={{ color: '#9C1E22', textAlign: 'center' }}
    bodyStyle={{ textAlign: 'center' }}
    hoverable
    {...props}
  >
    {props.children}
  </DefaultCard>
);
Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

message.config({
  top: 20,
  duration: 1,
  maxCount: 2,
});

function SideBySideFormItem(props) {
  const labelCol = props.inOneLine ? {} : {
    xs: 24,
    sm: 24,
    md: 10,
    lg: 9,
    xl: 9,
    xxl: 9,
    ...props.labelCol 
  };
  const wrapperCol= props.inOneLine ? {} :{
    xs: 24,
    sm: 24,
    md: 24,
    lg: 15,
    xl: 15,
    xxl: 15,
    ...props.wrapperCol
  };
  
return (
  !props.inOneLine ? <Row>
    <Col {...labelCol} style={props.style || defaultFormItemStyle} className={props.className || ""}>
    <span style={defaultFormItemStyle.label}>
      {props.require ? <sup className="textRed">*</sup> : ''} { props.label }{props.requireColumn !== false ? ':' : ''}
    </span>
    </Col>
    <Col {...wrapperCol}>
      {props.children}
    </Col>
  </Row> :
  <Row type="flex" align="middle">
    <Col style={props.style || defaultFormItemStyle} className={props.className || ""}>
    {/* Label */}
    <span style={defaultFormItemStyle.label}>
       {props.require ? <sup className="textRed">*</sup> : ''} { props.label }{props.requireColumn !== false ? ':' : ''}
    </span>
    </Col>
    <Col flex="auto">
    {props.children}
    </Col>
  </Row>
);
}

SideBySideFormItem.propTypes = {
  style: PropTypes.shape(),
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
SideBySideFormItem.defaultProps = {
  style: defaultFormItemStyle,
  children: '',
};
export {
  Affix,
  Alert,
  Anchor,
  Avatar,
  Badge,
  Button,
  Col,
  Card,
  Collapse,
  Content,
  DatePicker,
  Divider,
  Drawer,
  Dropdown,
  Empty,
  Form,
  FormItem,
  Header,
  Icon,
  Input,
  InputGroup,
  InputNumber,
  Layout,
  Link,
  List,
  MaterialFormItem,
  Menu,
  Mentions,
  message,
  Modal,
  Option,
  Pagination,
  Popconfirm,
  Popover,
  Progress,
  Result,
  Radio,
  Row,
  Rate,
  Search,
  Select,
  Sider,
  Spin,
  Statistic,
  Steps,
  SubMenu,
  Switch,
  Table,
  Tag,
  TextArea,
  Text,
  TimePicker,
  Timeline,
  Tooltip,
  Typography,
  Upload,
  Tabs,
  Checkbox,
  Tree,
  Skeleton,
  notification,
  TreeSelect,
  SideBySideFormItem,
  FormItemData
};
