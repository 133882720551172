import {
  Call,
  ApiUrl,
  PageParams,
  ParseGeneralResponse,
  setObjectToFormData,
  GenerateURL,
  optionalPageParams
} from "./ApiUtils";
import AppConfig from "../config/AppConfig";
import I18n from "../common/I18n";
import { isEmpty } from "../common/Common";
import userStore from "../stores/UserStore";

export function fetchCurrentUser() {
  const url = ApiUrl("v1/users/me?operation_code=UME");
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { user: "", message: 'message' },
  });
}

export function fetchUsersList(
  type = "user",
  page = 1,
  perPage = null,
  sortBy = "none",
  sortOrder = "ascend",
  signIn = false,
  searchText = "",
  requireHelper = false,
  org_id = null,
  status
) {
  const isReqPagination = !perPage ? false : true;
  let url = ApiUrl(`v1/users/user_listing_details?type=${type}&${optionalPageParams(page, perPage, isReqPagination)}`);
  if (signIn) {
    url += "&sign_in=true";
  }
  if (!isEmpty(sortBy) && sortBy !== "none") {
    url = `${url}&sort_by=${sortBy}&sort_order=${I18n.t(
      `general.sort_${sortOrder}`
    )}`;
  }
  if (!isEmpty(searchText)) {
    url = `${url}&search=${encodeURIComponent(searchText)}`;
  }
  if(requireHelper){
    url = `${url}&require_helper=${requireHelper}`;
  }
  if(!isEmpty(status)){
    url = `${url}&status=${status}`;
  }
  if(org_id){
    url = `${url}&org_id=${org_id}`
  }
  url = `${url}&operation_code=UI`
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { users: "users", pagination: "meta.pagination" },
  });
}

export function fetchUsers(
  type = "driver",
  page = 1,
  perPage = null,
  sortBy = "none",
  sortOrder = "ascend",
  signIn = false,
  searchText = "",
  requireHelper = false,
  org_id = null,
  status
) {
  const isReqPagination = !perPage ? false : true;
  let url = ApiUrl(`v1/users?type=${type}&${optionalPageParams(page, perPage, isReqPagination)}`);
  if (signIn) {
    url += "&sign_in=true";
  }
  if (!isEmpty(sortBy) && sortBy !== "none") {
    url = `${url}&sort_by=${sortBy}&sort_order=${I18n.t(
      `general.sort_${sortOrder}`
    )}`;
  }
  if (!isEmpty(searchText)) {
    url = `${url}&search=${encodeURIComponent(searchText)}`;
  }
  if(requireHelper){
    url = `${url}&require_helper=${requireHelper}`;
  }
  if(!isEmpty(status)){
    url = `${url}&status=${status}`;
  }
  if(org_id){
    url = `${url}&org_id=${org_id}`
  }
  url = `${url}&operation_code=UI`
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { users: "users", pagination: "meta.pagination" },
  });
}

export function fetchDriverList() {
  let url = ApiUrl(`v2/drivers/get_drivers_short_list?`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}


export function fetchDrivers(
  type = "driver",
  page = 1,
  perPage = null,
  sortBy = "none",
  sortOrder = "ascend",
  signIn = false,
  searchText = "",
  requireHelper = false,
  org_id = null,
  status
) {
  const isReqPagination = !perPage ? false : true;
  let url = ApiUrl(`v1/users/driver_listing_details?type=${type}&${optionalPageParams(page, perPage, isReqPagination)}`);
  if (signIn) {
    url += "&sign_in=true";
  }
  if (!isEmpty(sortBy) && sortBy !== "none") {
    url = `${url}&sort_by=${sortBy}&sort_order=${I18n.t(
      `general.sort_${sortOrder}`
    )}`;
  }
  if (!isEmpty(searchText)) {
    url = `${url}&search=${encodeURIComponent(searchText)}`;
  }
  if(requireHelper){
    url = `${url}&require_helper=${requireHelper}`;
  }
  if(!isEmpty(status)){
    url = `${url}&status=${status}`;
  }
  if(org_id){
    url = `${url}&org_id=${org_id}`
  }
  url = `${url}&operation_code=UI`
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { users: "users", pagination: "meta.pagination" },
  });
}

export function fetchUserDetailsById(id) {
  const url = ApiUrl(`v1/users/${id}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { user: 'user' },
  });
}

export function fetchOrderDriverUsers(
  page = 1,
  perPage = null,
  searchText = "",
  org_id = null,
  driverId = null
) {
  const isReqPagination = !perPage ? false : true;
  let url = ApiUrl(`v2/gross_order_reports?${optionalPageParams(page, perPage, isReqPagination)}&driver_id=${driverId}`);
  
  if (!isEmpty(searchText)) {
    url = `${url}&customer_order_number=${encodeURIComponent(searchText)}`;
  }
  if(org_id){
    url = `${url}&org_id=${org_id}`
  }
  url = `${url}&operation_code=UI`
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { driver_pay_reports: "driver_pay_reports", pagination: "meta.pagination" },
  });
}

export function fetchFilteredUser() {
  const organization_id = userStore.getStateValue("selectedOrg")
  const url = ApiUrl(
    `v1/users/get_filtered_users?organization_id=${organization_id}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { users: "users"},
  });
}

export function fetchActiveUsers(
  type = "driver",
  page = 1,
  perPage = AppConfig.perPage
) {
  const url = ApiUrl(
    `v1/users?active=true&type=${type}&${PageParams(page, perPage)}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { users: "users", pagination: "meta.pagination" },
  });
}

export function fetchTechnicians(
) {
  const organization_id = userStore.getStateValue('selectedOrg')
  const url = ApiUrl(
    `v2/driver_rates/get_technicians?organization_id=${organization_id}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { users: "drivers" },
  });
}

export function fetchUsersForWeeklyReport(
) {
  const organization_id = userStore.getStateValue('selectedOrg')
  const url = ApiUrl(
    `v2/driver_rates/get_driver_technicians?organization_id=${organization_id}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { users: "drivers" },
  });
}

export function fetchDriversForPreplan(
  type = "driver",
  preplanId,
  page = 1,
  perPage = AppConfig.perPage
) {
  const url = ApiUrl(
    `v1/users?active=true&type=${type}&${PageParams(
      page,
      perPage
    )}&preplan_id=${preplanId}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { users: "users", pagination: "meta.pagination" },
  });
}

export function saveUser(isNew, data) {
  if (
    data.mobile_number &&
    data.mobile_number.length > 0 &&
    data.mobile_number[0] !== "+"
  ) {
    data.mobile_number = `+${data.mobile_number}`;
  }
  data.operation_code = isNew ? 'UC' : 'UU';
  // data.is_hazmat_certified = is_hazmat_certified
  const formData = data;
  const url = isNew ? ApiUrl("v1/users") : ApiUrl(`v1/users/${data.id}`);
  const method = isNew ? "post" : "put";
  return Call(
    method,
    url,
    formData,
    ParseGeneralResponse,
    { responseDataKeys: { users: "" } },
    true
  );
}

export function deleteUser(id) {
  const url = ApiUrl(`v1/users/${id}`);
  return Call('delete', url, { operation_code: 'UD' }, ParseGeneralResponse);
}

export function changePassword(data) {
  data.operation_code = 'UCP';
  const formData = setObjectToFormData(data);
  const url = ApiUrl("v1/users/change_password");
  const method = "patch";
  return Call(method, url, formData, ParseGeneralResponse);
}

export function setUserStatus(data) {
  data.operation_code = 'RSU';
  const formData = setObjectToFormData(data);
  const url = ApiUrl("v1/users/resume_suspend_user");
  const method = "put";
  return Call(
    method,
    url,
    formData,
    ParseGeneralResponse,
    { responseDataKeys: { users: "users" } },
    false
  );
}

export function fetchOtp(user_id) {
  const url = ApiUrl(`v1/users/regen_otp?user_id=${user_id}&operation_code=UROTP`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: "" },
  });
}

export function fetchOrgRoles() {
  const url = ApiUrl(`v2/rbac/org_roles`);
  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    { responseDataKeys: { roles: 'roles' } },
  );
}

// Fetch tracking Users

export function fetchTrackedUsers(org_id,wh) {
  const url = GenerateURL(`drivers_tracking/location_details?organization_id=${org_id}&location_code=${wh}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { users: "", pagination: "meta.pagination" },
  });
} 

//Send password reset link to user
export function sendPasswordResetLink(email) {
  const url = GenerateURL(
    `api/authentication/forgot_password`
  );
  return Call("POST", url, { email }, ParseGeneralResponse, {
    responseDataKeys: {},
  });
}
export function fetchToken() {
  const url = GenerateURL(
    `api/v1/users/fetch_dash_token`
  );
  return Call("GET", url, {}, ParseGeneralResponse, {
    responseDataKeys: {
      data: "",
    },
  });
}
export function sendUserPasswordReset(data) {
  // const url = GenerateURL(
  //   `v2/authentication/send_password_reset_instructions`
  // );
  const url = ApiUrl(`authentication/send_password_reset_instructions`);
  return Call("POST", url, data, ParseGeneralResponse, {
    responseDataKeys: {},
  });
}

export function appInstallNotification(payload){
  const url = ApiUrl(`v1/users/send_app_install_notification`);
  return Call("PUT", url, payload, ParseGeneralResponse, {
    responseDataKeys: { data: '' }
  });
}

export const UserServices = {
  saveScreenConfiguration : (data)=>{
    const url = ApiUrl(`v1/users/save_screen_configuration`);
    return Call("post", url, data, ParseGeneralResponse, {
      responseDataKeys: { data: "" }
    });
  }
}

export function fetchUserActivities(userId, organization_id, page, perPage) {
  const url = ApiUrl(
    `v1/users/get_user_activities?user_id=${userId}&organization_id=${organization_id}&page=${page}&per_page=${perPage}`
  );
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { activities: "user_activities", pagination: "meta.pagination", },
  });
}

export const getCompletedOrderPod = (payload) => {
  const { order_id = "", org_id = "" } = payload
  const url = ApiUrl(`v2/order_details/get_order_pod_details?order_id=${order_id}&org_id=${org_id}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: "" }
  });
}

export const emailInsuranceDocs = (payload) => {
  const url = ApiUrl(`v1/users/send_insurance_docs`);
  return Call("post", url, payload, ParseGeneralResponse, {
    responseDataKeys: { data: "" }
  });
}


export const getDriverDocuments = (user_id = "") => {
  const url = ApiUrl(`v1/users/get_user_documents?user_id=${user_id}`);
  return Call("get", url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: "" }
  });
}


export const uploadDocuments = (payload) => {
  // use object to formdata
  const formData = setObjectToFormData(payload);
  const url = ApiUrl(`v1/users/upload_user_documents`);
  return Call("post", url, formData, ParseGeneralResponse, {
    responseDataKeys: { data: "" }
  });
}

export const printDocuments = (payload) => {
  const url = ApiUrl(`v1/users/download_user_attachments`);
  return Call("post", url, payload, ParseGeneralResponse, {
    responseDataKeys: { data: "" }
  });
}

export const deleteAttachmentInDocuments = (payload) => {
  const { user_id = '', document_id = '', selected_ids = '' } = payload;
  const url = ApiUrl(`/v1/users/delete_user_documents?user_id=${user_id}&document_id=${document_id}&selected_ids=${selected_ids}`);
  return Call("delete", url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: "" }
  });
}

export const sendDocsEmails = (payload) => {
  const url = ApiUrl(`/v1/users/send_user_documents`);
  return Call("post", url, payload, ParseGeneralResponse, {
    responseDataKeys: { data: "" }
  });
}