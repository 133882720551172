import { isEmpty } from "lodash";
import AppConfig from "../config/AppConfig";
import { Call, ApiUrl, PageParams, ParseGeneralResponse, optionalPageParams } from "./ApiUtils";

export const BillingConsolidationApi = {
  verifyOrdersToConsolidate: (data) => {
    data.operation_code = "BLCO";
    const url = ApiUrl("v2/billing_consolidations/verify_consolidated_orders_copy")
    const method = "post";
    return Call(method, url, data, ParseGeneralResponse, {
      responseDataKeys: { consolidated_orders: "consolidated_orders", account: "account", consolidation_number: "consolidation_number"},
    });
  },
  applyConsolidation: (data) => {
    data.operation_code = "BLACO";
    const url = ApiUrl("v2/billing_consolidations/apply_consolidation_copy")
    const method = "post";
    return Call(method, url, data, ParseGeneralResponse, {
      responseDataKeys: { 
        consolidated_orders: "consolidated_orders", 
        accessorial_consolidations: "accessorial_consolidations",
        primary_order_id: 'primary_order_id'
      },
    });
  },
  confirmConsolidation: (data) => {
    data.operation_code = "BLACO";
    const url = ApiUrl("v2/billing_consolidations/save_consolidated_orders_copy")
    const method = "post";
    return Call(method, url, data, ParseGeneralResponse, {
      responseDataKeys: { consolidated_order: "consolidate_order", account: 'account' },
    });
  },
  fetchConsolidationDetails: ({ id = "", consolidation_number = ""}) => {
    let url = ApiUrl(
      `v2/billing_consolidations/get_consolidated_orders`
    );
    let searchParams = ""
    if(!isEmpty(id)){
      searchParams = `?consolidate_order_id=${id}`
    }
    if(!isEmpty(consolidation_number)){
      searchParams = `${searchParams ? '&' : '?'}consolidation_number=${consolidation_number}`
    }
    url = `${url}${searchParams}`
    return Call("get", url, "", ParseGeneralResponse, {
      responseDataKeys: {
        consolidate_order: "consolidate_order",
        account: 'account',
        status: "status"
      },
    });
  },
  updateConsolidationDetails: (data) => {
    data.operation_code = "BLACOU";
    const url = ApiUrl("v2/billing_consolidations/update_consolidated_orders")
    const method = "post";
    return Call(method, url, data, ParseGeneralResponse, {
      responseDataKeys: { consolidated_order: "consolidate_order", account: 'account',
    },
    });
  },
  revertConsolidation: ({ id = ""}) => {
    let url = ApiUrl(
      `v2/billing_consolidations/revert_consolidation?consolidate_order_id=${id}`
    );
    return Call("get", url, "", ParseGeneralResponse, {
      responseDataKeys: {
        message: 'message'
      },
    });
  }, 
  approveConsolidationOrderInvoices: (data) => {
    const url = ApiUrl("v2/billing_consolidations/approved_consolidate_order_invoices")
    const method = "post";
    return Call(method, url, data, ParseGeneralResponse, {
      responseDataKeys: { data: "" },
    });
  }, 
  verifyBulkInvoiceLines: (data) => {
    data.operation_code = "BLIV";
    const url = ApiUrl("v2/billing/verify_bulk_invoice_lines");
    const method = "put";
    return Call(method, url, data, ParseGeneralResponse, {
      responseDataKeys: { 
        success: "success", 
        errors: "errors",
        bills: "bills",
        old_bills_total: "old_bills_total"
      },
    });
  },
  confirmBulkInvoiceLines: (data) => {
    const url = ApiUrl("v2/billing/save_invoice_lines");
    const method = "put";
    return Call(method, url, data, ParseGeneralResponse, {
      responseDataKeys: { 
        success: "success", 
        errors: "errors",
      },
    });
  },
  updateBulkInvoiceLines: (data) => {
    const url = ApiUrl("v2/billing/update_bulk_invoice_lines");
    const method = "put";
    return Call(method, url, data, ParseGeneralResponse, {
      responseDataKeys: { 
        success: "success", 
        errors: "errors",
        bills: "bills",
          old_bills_total: "old_bills_total",
        updated_bills_totoal: "updated_bills_totoal",
        difference: "difference",
        updated_invoice_lines: "updated_invoice_lines",
        adjustment_mode: "adjustment_mode",
        adjustment_type: "adjustment_type",
        adjustment_number: "adjustment_number"
      },
    });
  },
  revertAdjustedInvoiceLines: (data) => {
    const url = ApiUrl("v2/billing/revert_adjusted_invoice_lines");
    const method = "put";
    return Call(method, url, data, ParseGeneralResponse, {
      responseDataKeys: { success: "success", message: "message" },
    });
  },

  verifyAdjustedInvoiceLines: (data) => {
    const url = ApiUrl(`v2/billing/get_adjusted_bills?adjustment_number=${data.adjustment_number}&organization_id=${data.organization_id}`);
    const method = "get";
    return Call(method, url, {}, ParseGeneralResponse, {
      responseDataKeys: { 
        adjusted_bills: "adjusted_bills"
      },
    });
  },
};

