import { Col, Divider, Row, Spin } from "antd";
import React, { Fragment } from "react";
import { borderCollapseInherit } from "../helpers/invoice_styles";
import Signature from "./Signature";
import { fetchBillOfRating } from "../api/OrdersApi";
import I18n from "../common/I18n";
import EndUserTitle from "../components/common/EndUserTitle";
import { fetchBolLabels } from "./BillOfRatingModal.helpers";
import _ from 'lodash';

// New helper components/functions
const BorderedSection = ({ heading, content }) => (
	<div style={{
		borderLeft: "1px dashed #000",
		paddingRight: "80px"
	}}>
		<h4>{heading}</h4>
		<h5>{content}</h5>
	</div>
);

const HeaderSection = ({ vehicle_no, hawb, mawb, date, branch }) => (
	<div style={{ display: "flex", justifyContent: "space-between" }}>
		<BorderedSection heading="ROUTE NO/DRIVER" content={vehicle_no} />
		<BorderedSection
			heading="HAWB/MAWB"
			content={<>HAWB: {hawb}<br />MAWB: {mawb}</>}
		/>
		<div className="toInfo" style={{ display: "flex" }}>
			<BorderedSection heading={I18n.t("BillOfRating.date")} content={date} />
			<BorderedSection heading={I18n.t("BillOfRating.branch")} content={branch} />
		</div>
	</div>
);

const SignatureRow = ({ title, text, image, showDatetime }) => (
	<div className="signature-row">
		<Signature title={`${title} Name`} text={text} />
		<Signature title="Signature" image={image} />
		{showDatetime && <Signature title="Date: Time" text={text} />}
	</div>
);

const SignatureBlock = ({ type, driverName, signBy, signature, signatureDate }) => (
	<div>
		<div className="driver-row">
			<Signature
				title={`${type} Driver Name`}
				text={driverName !== '-NA-' ? driverName : ''}
			/>
		</div>
		<div className="signature-row">
			<Signature
				title={type === 'Delivery' ? 'Received By Name' : 'Shipped By Name'}
				content={signBy !== 'N/A' ? signBy : ''}
			/>
			<Signature
				title="Signature"
				image={signature}
			/>
			<Signature
				title="Date: Time:"
				content={signatureDate}
			/>
		</div>
	</div>
);

class BillOfRatingModal extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			orderId: this.props.orderId,
			billOfRating: {},
			isLoading: true,
		};
	}

	componentDidMount() {
		this.fetchbillOfRatingData(this.state.orderId);
	}

	async fetchbillOfRatingData(orderId) {
		try {
			const data = await fetchBillOfRating(orderId);
			const { success, bill_of_rate } = data;
			if (success) {
				this.setState({
					billOfRating: bill_of_rate,
					isLoading: false,
				});
			} else {
				this.setState({
					isLoading: false,
				});
				throw new Error(data.message || "Something went wrong");
			}
		} catch (error) {
			console.error(error);
		}
	}
	renderAddressRow = (obj) => {
		return (
			<td>
				{obj.name ? obj.name : ""}
				{obj.name ? <br /> : ""}
				{obj.company_name ? <Fragment><span>{obj.company_name}<br /> </span></Fragment> : ""}
				{obj.address?.address_line1 ? obj.address.address_line1 : ""}
				{obj.address?.address_line1 ? <br /> : ""}
				{obj.address?.address_line2 ? obj.address.address_line2 : ""}
				{obj.address?.address_line2 ? <br /> : ""}
				{obj.address?.city && obj.address?.state
					? obj.address.city + obj.address.state
					: ""}
			</td>
		);
	};
	renderQuestions = (questions) => {
		return questions.map(({ question, question_type, answers }, index) => {
			return (
				<tr key={ index }>
					<td>
						{ index + 1 }. { question }
					</td>
					<td>
						{ question_type === "signature" ? (
							<Signature
								image={ `data:image/png;base64,${answers}` }
							/>
						) :
							Array.isArray(answers)
								? answers.map((answer, index) => {
									return <div key={ index }>{ answer }</div>;
								})
								: answers }
					</td>
				</tr>
			);
		});
	};
	renderDescriptionRows = (descriptions, template = 'default') => {
		if (descriptions.length === 0) {
			return (
				<tr>
					<td colSpan="6" style={ { textAlign: 'center', verticalAlign: 'middle' } }>No item Data available</td>
				</tr>
			);
		}

		return descriptions.map((description, index) => {
			const name = _.get(description, 'name', '');
			const serialNumber = _.get(description, 'serial_number', '');
			const itemModel = _.get(description, 'item_model', '');
			const customerOrderNumber = _.get(description, 'customer_order_number', '');
			const whDock = _.get(description, 'wh_dock', '');
			const itemPieces = _.get(description, 'item_pieces');
			const itemWeight = Number(_.get(description, 'item_weight', 0)).toFixed(2);
			const weightDisplay = `${itemWeight} ${itemPieces ? `(${itemPieces}PCS)` : ""}`;
			const dimensions = `${description.item_length}x${description.item_width}x${description.item_height}`

			return (
				<tr key={index}>
					<td>{ name }</td>
					<td>{template !== "lkt" && serialNumber}</td>
					{template !== "lkt" && <td>{itemModel}</td>}
					{template !== "lkt" && <td>{customerOrderNumber}</td>}
					<td>{template !== "lkt" && whDock}</td>
					<td>{template == "lkt" && dimensions}</td>
					{template == "lkt" && <td></td>}
					<td>{ weightDisplay }</td>
				</tr>
			);
		});
	};

	formatHoursData = (hour) => {
		return hour;
	};

	renderInstructions = (instructions, isLykesOrg) => {
		const other = _.get(instructions, 'other', "N/A");
		const hours = instructions?.hours ? this.formatHoursData(instructions.hours) : "N/A";
		return (
			<table className="instructionsTable" style={ { width: "100%", maxWidth: "70vw" } }>
				<thead>
					<tr>
						<th colSpan="2" style={ { textAlign: "center", verticalAlign: "middle" } }>
							{ I18n.t(`BillOfRating.instructions`) }
						</th>
					</tr>
				</thead>
				<tbody>
					{ isLykesOrg ? (
						<>
							{ this.renderRow("general.notes", other) }
							{ this.renderRow("BillOfRating.hours", hours) }
						</>
					) : (
						<>
							{ this.renderRow("BillOfRating.other", other) }
							{ this.renderRow("BillOfRating.hours", hours) }
						</>
					) }
				</tbody>
			</table>
		);
	};

	renderRow = (headingKey, content) => (
		<tr>
			<td className="heading" style={ { fontWeight: "bold" } }>
				{ I18n.t(headingKey) }
			</td>
			<td>
				{ content }
			</td>
		</tr>
	);


	renderSignature = (sign_by, title_by_relation, signature, signature_date) => {
		return (
			<div
				style={ {
					display: "flex",
					justifyContent: "space-between",
				} }
			>
				<div
					style={ {
						display: "flex",
						flexDirection: "column",
						justifyContent: "end",
						maxWidth: "60%",
					} }
				>
					<div
						style={ {
							display: "flex",
							justifyContent: "start",
							gap: "5px",
							minWidth: "22rem",
						} }
					>
						<Signature
							title={ I18n.t("BillOfRating.shipper") }
						/>
						<Signature
							title={ I18n.t("BillOfRating.date") }
						/>
					</div>
				</div>
				<div
					style={ {
						display: "grid",
						gridTemplateColumns: "repeat(2, 1fr)",
						gap: "5px",
						minWidth: "22rem",
					} }
				>
					<Signature
						title={ I18n.t("BillOfRating.print_name") }
						text={ sign_by }
					/>
					<Signature title={ I18n.t("BillOfRating.title") }
						text={ title_by_relation }
					/>
					<Signature
						title={ I18n.t("BillOfRating.signature") }
						image={ signature }
					/>
					<Signature
						title={ I18n.t("BillOfRating.date") }
						content={ signature_date }
					/>
				</div>
			</div>);
	};

	renderLykesSignature = (labelsForBillOfRating) => {
		const { PU_PERSON_NAME, RECEIVER_NAME, DRIVER_NAME } = labelsForBillOfRating;
		return (
			<div style={ { display: "flex", flexDirection: "column", height: "100%", justifyContent: "center" } }>
				<div style={ { display: "flex", justifyContent: "space-between", flex: "1" } }>
					<Signature title={ PU_PERSON_NAME } />
					<Signature title={ RECEIVER_NAME } />
					<Signature title={ DRIVER_NAME } />
				</div>
				<div style={ { display: "flex", justifyContent: "space-between", flex: "1" } }>
					<Signature title={ labelsForBillOfRating.PU_PERSON_SIGN } />
					<Signature title={ labelsForBillOfRating.PU_TIME } />
					<Signature title={ labelsForBillOfRating.RECEIVER_SIGN } />
					<Signature title={ labelsForBillOfRating.RECEIVED_TIME } />
					<Signature title={ labelsForBillOfRating.DRIVER_SIGN } />
					<Signature title={ labelsForBillOfRating.DRIVER_DROP_TIME } />
				</div>
			</div>
		);
	}

	renderNonLktBol = () => {

		const { billOfRating } = this.state;
		const { order } = this.props;
		const { hawb, organization_id, type_of_order } = order;
		const labelsForBillOfRating = fetchBolLabels(organization_id);
		const isLykesOrg = organization_id === "602a8070822dd6ce12a49435";

		const {
			vehicle_no,
			date,
			branch,
			from,
			bill_to,
			ship_to,
			customer_phone,
			customer_reference,
			zone,
			service_date,
			description,
			instructions,
			barcode,
			customer_order_number,
			sign_by,
			title_by_relation,
			signature,
			signature_date,
			form,
			organization_logo
		} = billOfRating;

		return (
			<>
				<div
					style={{
						display: "grid",
						gridTemplateColumns: "repeat(3, 1fr)",
					}}
				>

					<div>
						{organization_logo && <img src={organization_logo} alt="Logo" style={{ height: 40, marginTop: 3 }} />}
						<h3 >{from.address?.city && from.address?.state ? `${from.address.city} , ${from.address.state}` : ""}</h3>
					</div>
					<div
						className="barcodeInfo"
						style={{
							textAlign: "center",
						}}
					>
						<img
							style={{
								height: "45px",
								width: "110px",
							}}
							src={barcode}
						/>
						<h4>{customer_order_number}</h4>
					</div>
				</div>
				{isLykesOrg && (
					<div className="hawbOrder" style={{ textAlign: "end" }}>
						<h4><b>#{labelsForBillOfRating.HAWB}: {hawb}</b></h4>
						<h4><b>#{labelsForBillOfRating.ORDER_LABEL}: {customer_order_number}</b></h4>
					</div>
				)}
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
					}}
				>
					<div style={{
						borderRight: "1px dashed #000",
						borderLeft: "1px dashed #000",
						paddingRight: "80px"
					}}>
						<h4>{I18n.t("BillOfRating.vehicle_no")}</h4>
						<h5>{vehicle_no}</h5>
					</div>
					<div
						className="toInfo"
						style={{
							display: "flex",
						}}
					>
						<div style={{
							borderRight: "1px dashed #000",
							borderLeft: "1px dashed #000",
							paddingRight: "80px"
						}}>
							<h4>{I18n.t("BillOfRating.date")}</h4>
							<h5>{date}</h5>
						</div>
						<div style={{
							borderRight: "1px dashed #000",
							borderLeft: "1px dashed #000",
							paddingRight: "80px"
						}}>
							<h4>{I18n.t("BillOfRating.branch")}</h4>
							<h5>{branch}</h5>
						</div>
					</div>
				</div>
				<table className="addressTable" width="100%" style={{}}>
					<thead>
						<tr>
							<td className="borHeading">
								{I18n.t("BillOfRating.from")}
							</td>
							<td className="borHeading">
								{I18n.t("BillOfRating.bill_to")}
							</td>
							<td className="borHeading">
								{I18n.t("BillOfRating.ship_to")}
							</td>
						</tr>
					</thead>
					<tbody>
						{this.renderAddressRow(from)}
						{this.renderAddressRow(bill_to)}
						{this.renderAddressRow(ship_to)}
					</tbody>
				</table>
				<table width="100%" style={{}}>
					<thead>
						<tr>
							<td className="borHeading">
								{isLykesOrg ? labelsForBillOfRating.CUSTOMER_PHONE :
									<EndUserTitle stringToAppend={I18n.t("general.phone")} />}
							</td>
							<td className="borHeading">
								{I18n.t("BillOfRating.zone")}
							</td>
							<td className="borHeading">
								{I18n.t("BillOfRating.service_date") +
									"/" +
									I18n.t("BillOfRating.spread_date")}
							</td>
							<td className="borHeading">
								{isLykesOrg ? labelsForBillOfRating.CUSTOMER_REFERENCE : <EndUserTitle stringToAppend={I18n.t("order.reference")} />}
							</td>
						</tr>
					</thead>
					<tbody>
						<td>{customer_phone}</td>
						<td>{zone}</td>
						<td>{service_date}</td>
						<td>{customer_reference}</td>
					</tbody>
				</table>
				<div className="descriptionTable">
					<table width="100%" style={{}}>
						<thead>
							<tr>
								<td></td>
								<td></td>
								<td
									style={{
										fontWeight: "bold",
									}}
								>
									{I18n.t("BillOfRating.description")}
								</td>
								<td></td>
								<td></td>
								<td
									style={{
										fontWeight: "bold",
									}}
								>
									{I18n.t("BillOfRating.weight")}
								</td>
							</tr>
						</thead>
						<tbody>
							{this.renderDescriptionRows(description)}
						</tbody>
					</table>
				</div>
				<Divider
					dashed
					style={{
						margin: "0",
						display: form.length ? "auto" : "none",
					}}
				/>
				<div className="modalQuestionare">
					<table width="50%" style={{ ...borderCollapseInherit }}>
						<thead>
							<tr></tr>
						</thead>
						<tbody>
							{form.length > 0 && this.renderQuestions(form)}
						</tbody>
					</table>
				</div>
				<Divider
					dashed
					style={{
						margin: "0",
						display: form.length ? "auto" : "none",
					}}
				/>
				{this.renderInstructions(instructions, isLykesOrg)}
			</>
		)
	}

	renderLktBol = () => {
		const { billOfRating } = this.state;
		const { order, organizationSettings } = this.props;
		const { type_of_order } = order;
		const isLktTemplate = organizationSettings?.bol_pdf_template == "lkt_bol_template";

		// If it's a transfer order and LKT template
		if (type_of_order === 'T' && isLktTemplate) {
			const { pickup_bill_of_rate, delivery_bill_of_rate } = billOfRating;
			return (
				<>
					{/* Only render pickup BOL if data exists */}
					{pickup_bill_of_rate && Object.keys(pickup_bill_of_rate).length > 0 && (
						<div className="lkt-bol">
							{this.renderLktBolContent(pickup_bill_of_rate)}
						</div>
					)}

					{/* Add page break only if both BOLs exist */}
					{pickup_bill_of_rate && delivery_bill_of_rate && (
						<div 
							style={{ 
								pageBreakBefore: 'always',
								borderTop: '2px dashed #000',
								margin: '20px 0',
								padding: '10px 0',
								textAlign: 'center'
							}} 
						/>
					)}

					{/* Only render delivery BOL if data exists */}
					{delivery_bill_of_rate && Object.keys(delivery_bill_of_rate).length > 0 && (
						this.renderLktBolContent(delivery_bill_of_rate)
					)}
				</>
			);
		}

		// For non-transfer orders, render single BOL only if data exists
		return billOfRating && Object.keys(billOfRating).length > 0 ? (
			<div className="lkt-bol" style={{ height: "100%" }}>
				{this.renderLktBolContent(billOfRating)}
			</div>
		) : null;
	}

	renderLktBolContent = (billOfRatingData) => {
		const {
			vehicle_no, date, branch, from, hawb, mawb,
			bill_to, ship_to, customer_phone, zone,
			service_date, customer_reference, description,
			driver_name, sign_by, signature, signature_date,
			instructions, form, organization_logo, barcode,
			customer_order_number
		} = billOfRatingData;

		return (
			<div className="billOfRatingContainer" style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", minHeight: "50rem" }}>
				<div>
					{/* Add logo and barcode section */}
					<div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)" }}>
						<div>
							{organization_logo && <img src={organization_logo} alt="Logo" style={{ height: 40, marginTop: 3 }} />}
							<h3>{from.address?.city && from.address?.state ? `${from.address.city}, ${from.address.state}` : ""}</h3>
						</div>
						<div className="barcodeInfo" style={{ textAlign: "center" }}>
							<img style={{ height: "45px", width: "110px" }} src={barcode} />
							<h4>{customer_order_number}</h4>
						</div>
					</div>

					<HeaderSection
						vehicle_no={vehicle_no}
						hawb={hawb}
						mawb={mawb}
						date={date}
						branch={branch}
					/>

					{/* Address Table */}
					<div className="addresses">
						<table className="addressTable" width="100%">
							<thead>
								<tr>
									<td className="borHeading">FROM</td>
									<td className="borHeading">BILL TO</td>
									<td className="borHeading">SHIP TO</td>
								</tr>
							</thead>
							<tbody>
								<tr>
									{this.renderAddressRow(from)}
									{this.renderAddressRow(bill_to)}
									{this.renderAddressRow(ship_to)}
								</tr>
							</tbody>
						</table>
					</div>

					{/* Order Reference Table */}
					<table width="100%">
						<thead>
							<tr>
								<td className="borHeading">CUSTOMER PHONE</td>
								<td className="borHeading">ZONE</td>
								<td className="borHeading">SERVICE DATE/SPREAD DATE</td>
								<td className="borHeading">CUSTOMER REFERENCE</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{customer_phone}</td>
								<td>{zone}</td>
								<td>{service_date}</td>
								<td>{customer_reference}</td>
							</tr>
						</tbody>
					</table>

					{/* Description Section */}
					<div className="descriptionTable">
						<table width="100%">
							<thead>
								<tr>
									<td></td>
									<td></td>
									<td style={{ fontWeight: "bold" }}>
										{I18n.t("BillOfRating.description")}
									</td>
									<td></td>
									<td></td>
									<td style={{ fontWeight: "bold" }}>
										{I18n.t("BillOfRating.weight")}
									</td>
								</tr>
							</thead>
							<tbody>
								{this.renderDescriptionRows(description, "lkt")}
							</tbody>
						</table>
					</div>

					{/* Form & Instructions */}
					{form.length > 0 && (
						<>
							<Divider dashed style={{ margin: 0 }} />
							<div className="modalQuestionare">
								<table width="50%" style={{ ...borderCollapseInherit }}>
									<thead>
										<tr></tr>
									</thead>
									<tbody>
										{this.renderQuestions(form)}
									</tbody>
								</table>
							</div>
							<Divider dashed style={{ margin: 0 }} />
						</>
					)}

					{this.renderInstructions(instructions, false)}
				</div>

				{/* Signature Section */}
				{this.renderSignatureSection(billOfRatingData)}
				<br />
				{this.renderLktFooter()}
			</div>
		);
	}

	renderSignatureSection = (billOfRatingData) => {
		const { order } = this.props;
		const { type_of_order } = order;

		const containerStyle = {
			borderTop: '2px solid black',
			paddingTop: "35px",
			marginTop: "15em"
		};

		const signatureData = {
			driverName: billOfRatingData?.driver_name,
			signBy: billOfRatingData?.sign_by,
			signature: billOfRatingData?.signature,
			signatureDate: billOfRatingData?.signature_date
		};

		// For LH orders, show both pickup and delivery signatures
		if (["LH", "MS"].includes(type_of_order)) {
			return (
				<div className="signature-container" style={containerStyle}>
					<div style={{ marginBottom: "2em" }}>
						<SignatureBlock type="Pickup" {...signatureData} />
					</div>
					<SignatureBlock type="Delivery" {...signatureData} />
				</div>
			);
		}

		// For other order types
		const type = {
			T: billOfRatingData === this.state.billOfRating.pickup_bill_of_rate ? "Pickup" : "Delivery",
			D: "Delivery",
			R: "Pickup",
			TD: "Delivery",
			TR: "Pickup"
		}[type_of_order] || "";

		return (
			<div className="signature-container" style={containerStyle}>
				<SignatureBlock
					type={type}
					{...signatureData}
				/>
			</div>
		);
	};

	renderFooter = (isLykesOrg, type_of_order, labelsForBillOfRating) => {
		const { billOfRating } = this.state;
		const { sign_by, title_by_relation, signature, signature_date } = billOfRating;

		return (
			<div className="modalFooter">
				<div>
					<hr />
					<h5>
						This equipment has been received, put in use, is in good working order and is satisfactory and acceptable.
					</h5>
					<small style={{ fontSize: "7px" }}>
						PICK UP TENDERED: THE ABOVE EQUIPMENT WAS TENDERED IN GOOD CONDITION EXCEPT AS NOTED ON THE ATTACHED INVENTORY.
					</small>
				</div>
				{(isLykesOrg && (type_of_order === 'R' || type_of_order === 'T'))
					? this.renderLykesSignature(labelsForBillOfRating)
					: this.renderSignature(sign_by, title_by_relation, signature, signature_date)
				}
				<small style={{ fontSize: "7px" }}>
					SHIPPER AGREES WITH THE TERMS OF THIS BILL OF LADING AND TENDERS ARTICLES LISTED ABOVE TO CARRIER IN CONDITION SHOWN.
					SHIPPER EXPRESSLY RELEASES THE SHIPMENT TO A VALUE OF $ .60 PER POUND PER ARTICLE
				</small>
				<small style={{ fontSize: "7px" }}>
					TOTAL VALUE OF SHIPMENT IS $ {"___ "} AND REQUEST EXCESS COVERAGE FOR FULL VALUE, ONLY IF CONTROL NO IS ISSUED
				</small>
			</div>
		);
	}

	renderLktFooter = () => {
		return (
			<div className="modalFooter">
				<small style={{ fontSize: "13px" }}>
					Shipper: This is to certify that the above-named materials are properly classified, described,
					packaged, marked and labeled, and are in proper condition for transportation according to the
					applicable regulations of the Department of Transportation.
				</small>
				<br />
				<small style={{ fontSize: "13px" }}>
					Consignee: This is to certify that the above-named materials are received in full without notation
					of loss or damage.
				</small>

			</div>
		);
	};

	render() {
		if (this.state.isLoading) {
			return <Spin tip="Loading..." spinning={true} />;
		}

		const { billOfRating } = this.state;
		if (!billOfRating) {
			return null;
		}

		const { order, organizationSettings } = this.props;
		const { organization_id, type_of_order } = order;
		const isLykesOrg = organization_id === "602a8070822dd6ce12a49435";
		const isLktTemplate = organizationSettings?.bol_pdf_template == "lkt_bol_template";
		const labelsForBillOfRating = fetchBolLabels(organization_id);

		return (
			<div id="billOfRatingModalContainer">
				<div className="billOfRatingModalBody">
					{isLktTemplate && this.props.currentOrg.code !== "LYKES" ? this.renderLktBol() : this.renderNonLktBol()}
				</div>
				{(!isLktTemplate || this.props.currentOrg.code == "LYKES") && this.renderFooter(isLykesOrg, type_of_order, labelsForBillOfRating)}
			</div>
		);
	}
}
export default BillOfRatingModal;
