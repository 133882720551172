/* eslint-disable camelcase */
import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Row,
  Icon,
  Popover,
  Link,
  Tooltip,
} from "../../common/UIComponents";
import { checkNegitive, doFormate, ellipseText, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import ShipperDetails from "../stops/ShipperDetails";
import AppConfig from "../../config/AppConfig";
import { formatSurfaceArea, getOrderStatus } from "../../helpers/orders";
import { withRouter } from "react-router";
import WarehouseDetails from "../stops/WarehouseDetails";
import { formatDimension } from "../../helpers/orders";
import { checkAccessExistance, checkServiceExistance, convertToUnitsWithBothLabels, getWeightUnits, getWeightWithBothUnits } from "../../helpers/common";
import { AccountUnitsContext } from "../../context/AccountUnitsContext";
import { OrgContext } from "../../context/OrgContext";
import { UserContext } from "../../context/UserContext";

const Details = ({ order, history, isShipper }) => {
  const apptSet = doFormate(order.appt_set);
  const whName = doFormate(order.wh_location.name);
  const whPhone = doFormate(order.wh_location.phone);
  const whEmail = doFormate(order.wh_location.email);
  const whLocationCode = doFormate(order.wh_location.location_code);
  const billingNumber = doFormate(order.billing_number);
  // const contactCustomer = order.type_of_order
  //   ? getOrderStatus(order.type_of_order)
  //   : "NA";
  const isTransferOrder = order.type_of_order == "T" 
  const cr = doFormate(order.cr);
  const returnAuthorization = doFormate(order.return_authorization);
  const inspectedHouse = doFormate(order.inspected_house);
  const orderTZShortName = order.order_tz_short_name
    ? ` (${order.order_tz_short_name})`
    : "";
  const weight = doFormate(order.weight);
  const mileage = doFormate(order.mileage);
  const serviceDuration = order.service_duration
    ? `${order.service_duration} ${I18n.t("general.min_short_cut")}`
    : "NA";
  const navigateToWarehouse = (history) => {
    return history.push(`/locations`);
  };
  const warehouse = !isEmpty(whName) ? whName : whLocationCode

  const navigateToLos = (order) => {
    const { account_id, account_code, los, primary_account_id } = order;
    const navigateTo = !isEmpty(primary_account_id) ? primary_account_id : (
      !isEmpty(account_id) ? account_id : ''
    );
    return !isShipper && !isEmpty(navigateTo) && checkAccessExistance('ACCOUNT_CONFIG') ? (
      <Link
        onClick={() => {
          history.push(`/accounts/${navigateTo}/settings?tab=service_levels_adjustments`);
        }}
      >
        {los}
      </Link>
    ) : (
      <Fragment>{los}</Fragment>
    );
  };

  const userContext = useContext(UserContext);

  // use lodash to get the value
  const roundOffDecimals = _.get(userContext, 'orgRules.org_billing_rules.round_off_decimals', 2);

  return (
    <div>
      <Row>
        <Col sm={23} xs={23} md={10} lg={10}>
          <div className="details_heading">{I18n.t("general.warehouse")}</div>
        </Col>
        <Col xs={1} className="details_heading">
          :
        </Col>
        <Col sm={24} xs={24} md={13} lg={13}>
          <div className="details_value">
            <Popover
              size="small"
              content={
                <div className="table-contact-info fontsize12">
                  <WarehouseDetails
                    data={order.wh_location}
                    showImage={false}
                  />
                </div>
              }
              title={I18n.t("preplan.warehouse_details")}
            >
              {!isShipper && checkServiceExistance("WHI") ?
              <Link
                onClick={() => navigateToWarehouse(history)}
              >
                <Tooltip placement="topLeft" title={warehouse}>
                  {ellipseText(warehouse, 15)}
                   </Tooltip>
              </Link>
              :
              <Tooltip placement="topLeft" title={warehouse}>
                {ellipseText(warehouse, 15)}
               </Tooltip>
              }
            </Popover>
          </div>
        </Col>
      </Row>

      {!isTransferOrder && (
      <Row>
        <Col sm={23} xs={23} md={10} lg={10}>
          <div className="details_heading">{I18n.t("los.label")}</div>
        </Col>
        <Col xs={1} className="details_heading">
          :
        </Col>
        <Col sm={24} xs={24} md={13} lg={13}>
          <div>
            {!isEmpty(order.los) && (
              <span className="details_value">{navigateToLos(order)}</span>
            )}
          </div>
        </Col>
      </Row>
      )}

      <Row>
        <Col sm={23} xs={23} md={10} lg={10}>
          <div className="details_heading">{I18n.t("order.qty")}</div>
        </Col>
        <Col xs={1} className="details_heading">
          :
        </Col>
        <Col sm={24} xs={24} md={13} lg={13}>
          <div>
          {order.quantity ? (order.quantity) : 0}
          </div>
        </Col>
      </Row>

      <Row>
        <Col sm={23} xs={23} md={10} lg={10}>
          <div className="details_heading">{I18n.t("order.pallets")}</div>
        </Col>
        <Col xs={1} className="details_heading">
          :
        </Col>
        <Col sm={24} xs={24} md={13} lg={13}>
          <div className="details_value">
            <span>{doFormate(order.pallets)}</span>
          </div>
        </Col>

        {/* <Col sm={7} xs={10} md={6} lg={6}>
                <span className="textBold">
                  <Icon type="shopping" /> &nbsp;{I18n.t("order.weight")}
                </span>
              </Col>
              <Col xs={1}>:</Col>
              <Col sm={14} xs={11} md={15} lg={15}>
                <div className="info-text">
                  <span>
                    {weight} ({AppConfig.weight_unit})
                  </span>
                </div>
              </Col> */}
      </Row>

      <Row>
        <Col sm={23} xs={23} md={10} lg={10}>
          <div className="details_heading">{I18n.t("order.weight")}</div>
        </Col>
        <Col xs={1} className="details_heading">
          :
        </Col>
        <Col sm={24} xs={24} md={13} lg={13}>
          <div className="details_value">
            <span>
              {/* {getWeightWithBothUnits(weight, order.account_code, accountUnitContext.accountWeightUnits)} */}
              {!isEmpty(weight) && weight !== "NA" && (
                <>
                  {Number(order.weight).toFixed(Number(userContext?.orgRules?.org_billing_rules?.round_off_decimals || 2))} {"lbs"} /
                  {Number(order.weight_in_kgs).toFixed(Number(userContext?.orgRules?.org_billing_rules?.round_off_decimals || 2))} {"kgs"}
                </>
                // <>{getFormattedWeight(order.weight,order.weight_in_kgs)}</>
              )}
            </span>
          </div>
        </Col>
        <Col sm={23} xs={23} md={10} lg={10}>
          <div className="details_heading">{"Mileage"}</div>
        </Col>
        <Col xs={1} className="details_heading">
          :
        </Col>
        <Col sm={24} xs={24} md={13} lg={13}>
          <div className="details_value">
            <span>
              {mileage} 
              {!isEmpty(mileage) && mileage !== "NA" && (
                <> {"Mi"}</>
              )}
            </span>
          </div>
        </Col>
        {/* <Col sm={9} xs={12} md={8} lg={8}>
                <span className="textBold">
                  <Icon type="table" />
                  &nbsp;{I18n.t("order.pallets")}
                </span>
              </Col>
              <Col xs={1}>:</Col>
              <Col sm={14} xs={11} md={15} lg={15}>
                <div className="info-text">
                  <span>{doFormate(order.pallets)}</span>
                </div>
              </Col> */}
      </Row>

      <Row>
        <Col sm={23} xs={23} md={10} lg={10}>
          <div className="details_heading">{I18n.t("order.shipper")}</div>
        </Col>
        <Col xs={1} className="details_heading">
          :
        </Col>
        <Col sm={24} xs={24} md={13} lg={13}>
          <div className="details_value">
            {!isEmpty(order.shipr_first_name) ? (
              <Popover
                size="small"
                content={
                  <div className="table-contact-info fontsize12">
                    <ShipperDetails data={order || {}} showImage={false} />
                  </div>
                }
                title={I18n.t("order.shipper_details")}
                overlayStyle={{
                  width: "16rem",
                }}
              >
                <span>
                  <u className="text-decoration-dashed">{`${
                    order.shipr_first_name
                  } ${
                    !isEmpty(order.shipr_last_name) ? order.shipr_last_name : ""
                  }`}</u>
                </span>
              </Popover>
            ) : (
              "NA"
            )}
          </div>
        </Col>
      </Row>

      {/* <Row>
        <Col sm={23} xs={23} md={10} lg={10}>
          <div className="details_heading">{I18n.t("order.dimensions")}</div>
        </Col>
        <Col xs={1} className="details_heading">
          :
        </Col>
        <Col sm={24} xs={24} md={13} lg={13}>
          <div className="details_value">
            {formatDimension(0, "", 0, "", 0, "", true)}
          </div>
        </Col>
      </Row> */}

      <Row>
        <Col sm={23} xs={23} md={10} lg={10}>
          <div className="details_heading">{I18n.t("order.is_hazardous")}</div>
        </Col>
        <Col xs={1} className="details_heading">
          :
        </Col>
        <Col sm={24} xs={24} md={13} lg={13}>
          <div className="details_value">
            {order.is_hazardous ? "Yes" : "No"}
          </div>
        </Col>
      </Row>
       <Row>
        <Col sm={23} xs={23} md={10} lg={10}>
          <div className="details_heading">{I18n.t("order.surface_area")}</div>
        </Col>
        <Col xs={1} className="details_heading">
          :
        </Col>
        <Col sm={24} xs={24} md={13} lg={13}>
          <div className="details_value">
            {formatSurfaceArea(order.surface_area)}
          </div>
        </Col>
      </Row>
      {/* {<Row>
        <Col sm={23} xs={23} md={10} lg={10}>
          <div className="details_heading">{I18n.t("quotes.quote_no")}</div>
        </Col>
        <Col xs={1} className="details_heading">
          :
        </Col>
        <Col sm={24} xs={24} md={13} lg={13}>
          <div className="details_value">
            {order.quote_order_number ? (order.quote_order_number) : 'NA'}
          </div>
        </Col>
      </Row>
      } */}
      {<Row>
        <Col sm={23} xs={23} md={10} lg={10}>
          <div className="details_heading">{I18n.t("order.address_type")}</div>
        </Col>
        <Col xs={1} className="details_heading">
          :
        </Col>
        <Col sm={24} xs={24} md={13} lg={13}>
          <div className="details_value">
            {order.address_type ? order.address_type === 'RESEDENTIAL' ? 
              I18n.t("order.resedential_type") : 
              I18n.t("order.commercial_type") : 'NA'}
          </div>
        </Col>
      </Row>
      }
      {<Row>
        <Col sm={23} xs={23} md={10} lg={10}>
          <div className="details_heading">{I18n.t("order.vehicle_type")}</div>
        </Col>
        <Col xs={1} className="details_heading">
          :
        </Col>
        {/* <Col sm={24} xs={24} md={13} lg={13}>
          <div className="details_value noWrap">
            {order.vehicle_type ? (order.vehicle_type) : 'NA'}
          </div>
        </Col> */}
         <Col sm={24} xs={24} md={13} lg={13}>
            <div
              className={`details_value ${
                order.vehicle_type && order.vehicle_type.length > 10
                  ? "noWrap"
                  : ""
              }`}
            >
              {order.vehicle_type ? (
                order.vehicle_type.length > 10 ? (
                  <Tooltip title={order.vehicle_type}>
                    {order.vehicle_type}
                  </Tooltip>
                ) : (
                  order.vehicle_type
                )
              ) : (
                "NA"
              )}
            </div>
          </Col>
                
      </Row>
      }
      <Row>
        <Col sm={23} xs={23} md={10} lg={10}>
          <div className="details_heading">{I18n.t("configurations.priority")}</div>
        </Col>
        <Col xs={1} className="details_heading">
          :
        </Col>
        <Col sm={24} xs={24} md={13} lg={13}>
          <div className="details_value">
            {order.priority === null || order.priority === false ? "No" : "Yes"}
          </div>
        </Col>
      </Row>
      {/* {<Row>
        <Col sm={23} xs={23} md={10} lg={10}>
          <div className="details_heading">{I18n.t("order.quotation_amount")}</div>
        </Col>
        <Col xs={1} className="details_heading">
          :
        </Col>
        <Col sm={24} xs={24} md={13} lg={13}>
          <div className="details_value">
            <b>
             {order.quote_amount !== 0 ? checkNegitive(order.quote_amount) : "NA"}
            </b>
          </div>
        </Col>
      </Row>
      } */}
      {/* <Row>
        <Col sm={23} xs={23} md={10} lg={10}>
          <div className="details_heading">{I18n.t("general.hawb")}</div>
        </Col>
        <Col xs={1} className="details_heading">
          :
        </Col>
        <Col sm={24} xs={24} md={13} lg={13}>
          <div className="details_value">{doFormate(order.hawb)}</div>
        </Col>
      </Row>

      <Row>
        <Col sm={23} xs={23} md={10} lg={10}>
          <div className="details_heading">{I18n.t("general.mawb")}</div>
        </Col>
        <Col xs={1} className="details_heading">
          :
        </Col>
        <Col sm={24} xs={24} md={13} lg={13}>
          <div className="details_value">{doFormate(order.mawb)}</div>
        </Col>
      </Row> */}
    </div>
  );
};
Details.propTypes = {
  order: PropTypes.shape().isRequired,
};
export default withRouter(Details);