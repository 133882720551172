import { isEmpty } from 'lodash';
import { Call, ApiUrl, GenerateURL,ParseGeneralResponse, setObjectToFormData } from './ApiUtils';


export function saveQuestion(isNew, data) {
  const formData = setObjectToFormData(data);
  const url = isNew ? ApiUrl('questions') : ApiUrl(`questions/${data.questionId}`);
  const method = isNew ? 'post' : 'put';
  return Call(method, url, formData, ParseGeneralResponse, { responseDataKeys: { data: '' } }, false);
}

export function QuestionTypes() {
  const url = ApiUrl('Questions/QuestionTypes');
  return Call('get', url, {}, ParseGeneralResponse, { responseDataKeys: { types: '' } }, false);
}
export function DeleteQuestion(questionId, sopId) {
  const url = ApiUrl(`Questions/${sopId}/${questionId}`);
  return Call('delete', url, {}, ParseGeneralResponse, {}, true);
}

export function fetchSurveyQuestions(id) {
  const url = GenerateURL(`api/survey/details?order_id=${id}`);
  return Call('get', url, '', ParseGeneralResponse, { responseDataKeys: { survey_details: 'survey_details' } });
}

export function fetchSurveyResponses(id, loc_id ='') {
  let url = ApiUrl(`v2/survey/order_responses?order_id=${id}&operation_code=SRVYOR`);
  if(!isEmpty(loc_id)){
    url = `${url}&location_id=${loc_id}`;
  }
  return Call('get', url, '', ParseGeneralResponse, { responseDataKeys: { survey_details: 'survery_response' } });
}

export function saveSurvey(data) {
  const formData = setObjectToFormData(data);
  const url = GenerateURL('api/survey_responses');
  return Call('post', url, formData, ParseGeneralResponse, { responseDataKeys: { data: '' } }, false);
}
