import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Menu, SubMenu, Row, Col } from "./UIComponents";
import { UserContext } from "../context/UserContext";
import { _ } from "core-js";
import { renderBadge, renderIcon } from "./Common";
import { OrgContext } from "../context/OrgContext";

export function renderSuperAdminMenuItems(item) {
  const isCustomIcon = /\.(png|jpg|jpeg|gif|svg)$/i.test(item.icon);
  function renderMenuItem() {
    return (
      <Menu.Item key={item.key}>
        <NavLink to={item.link}>
          {renderIcon(isCustomIcon, item)}
          <span className={item.className}>{item.text}</span>
        </NavLink>
      </Menu.Item>
    );
  }

  function renderSubMenu() {
    return (
      <SubMenu
        key={item.key}
        title={
          <span>
            {renderIcon(isCustomIcon, item)}
            <span className={item.className}>{item.text}</span>
          </span>
        }
      >
        {item.subMenuItems.map(renderSuperAdminMenuItems)}
      </SubMenu>
    );
  }

  return item.subMenuItems ? renderSubMenu() : renderMenuItem();
}

export function renderAdminMenuItems(items, history) {
  const { rbacs, orgRules, currentRole,currentOrg } = useContext(UserContext);
  const {orgSettings} = useContext(OrgContext);
  const isCustomIcon = /\.(png|jpg|jpeg|gif|svg)$/i.test(items.icon);
  // const MonthlyCutOff = ["LYKES", "QA", "FLTENBL", "DEMO","FET","KENCO"];
  const MonthlyCutOff = orgSettings &&  _.get(orgSettings, "monthly_cutoff","false");
  const viewDockScan = [ "ACTFT", "DEMO", "FLTENBL" ];
  const viewFusionReport = orgSettings &&  _.get(orgSettings, "invoice_fusion_report","false");
  const viewStatusReport = orgSettings && _.get(orgSettings, "invoice_status_report","false");
  const viewInsightsMenu = orgSettings && _.get(orgSettings, "require_insights","false");
  const viewAIConverseMenu = ['QA', 'TESTQA', 'DEMO'].includes(currentOrg.code);
  const incomingOrgCode = _.get(currentOrg, "code");
  const creditLimitVal =
    orgRules && _.get(orgRules, "org_billing_rules.credit_limit_validation");
  const hasAccess =
    _.isEmpty(rbacs) ||
    _.some(_.get(items, "accessCodes", []), (code) => _.includes(rbacs, code));
  const navigateAccess = items.pathAccessCodes
    ? _.every(_.get(items, "pathAccessCodes", []), (code) =>
        _.includes(rbacs, code)
      )
    : _.every(_.get(items, "accessCodes", []), (code) =>
        _.includes(rbacs, code)
      );

  // if (items.key === "insights_menu" && viewInsightsMenu !== "true") {
  //   return null;
  // }
  if(items.key === "ai_converse" && !viewAIConverseMenu){
    return null
  }

  if (items.subMenuItems && hasAccess) {
    let accessibleSubItems = items.subMenuItems.filter(
      (subItem) =>
        _.isEmpty(rbacs) ||
        _.every(_.get(subItem, "accessCodes", []), (code) =>
          _.includes(rbacs, code)
        )
    );

     // remove the preplan beta for other orgs except NETMOVE in aceessibleSubItems
    //  accessibleSubItems = accessibleSubItems.filter(
    //   (subItem) => subItem.key == "/preplan_management_beta" ? viewPreplanBeta.includes(incomingOrgCode) : true
    // );

    //Remove the menu Items which doesnt have accessibleSubItems
    if(!_.isEmpty(items.key) && (accessibleSubItems.length === 0)){
      return null
    }
    //remove the invoice fusion and invoice status according to org settings
    if (items.key === "report_menu") {
      const reports = [
        { viewStatus: viewFusionReport, key: "/qb_report" },
        { viewStatus: viewStatusReport, key: "/invoice_status_report" },
      ];

      reports.forEach(report => {
        if (report.viewStatus === "false") {
          accessibleSubItems = accessibleSubItems.filter(
            (subItem) => subItem.key !== report.key
          );
        }
      });
    }
    //removing the dock scan report for other orgs except ACTFT

    if (items.key === "dock_scan_menu" && !viewDockScan.includes(incomingOrgCode)) {
      return null;
     }

    // If the key is 'billing_management_menu' and 'creditLimitVal' is 'false',
    // remove the submenu item with the key '/credit_limit'
    if (items.key === "billing_management_menu" && creditLimitVal === "false") {
      accessibleSubItems = accessibleSubItems.filter(
        (subItem) => subItem.key !== "/credit_limit"
      );
    }

    // If the key is 'billing_management_menu' and 'creditLimitVal' is 'false' and there are no accessible subItems,
    if (
      items.key === "billing_management_menu" &&
      creditLimitVal === "false" &&
      accessibleSubItems.length === 0
    ) {
      return null;
    }

    // if the key is 'billing_management_menu' and 'MonthlyCutOff' is not matching the incomingOrgCode,
    // remove the submenu item with the key '/monthly_cut_off'
    if (items.key === "billing_management_menu" && MonthlyCutOff === "false") {
      accessibleSubItems = accessibleSubItems.filter(
        (subItem) => subItem.key !== "/monthly_cutoff"
      );
    }

    if (items.key === "org_configuration") {
      // Check if there are accessible subItems other than 'roles'
      const hasOtherSubItems = accessibleSubItems.some(
        (subItem) => subItem.key !== "/roles"
      );
      // If there are no accessible subItems other than 'roles', don't render 'org_configuration'
      if (!hasOtherSubItems) {
        return null;
      }
    }

    return (
      <SubMenu
        key={items.key}
        title={
            <span
            onClick={() => {
              if (_.isEmpty(rbacs) || navigateAccess) {
                history.push(items.path);
              }
            }}
          >
            <Row type="flex">
              <Col>
                {renderIcon(isCustomIcon, items)}
              </Col>
              {/* <Col>
                <span className={items.className}>{items.text}</span>
              </Col> */}
              <Col>{renderBadge(items)}</Col>
            </Row>
          </span>
        }
      >
        {accessibleSubItems.map((item) => renderAdminMenuItems(item, history))}
      </SubMenu>
    );
  } else if (items.key === "org_configuration" && !hasAccess) {
    return null;
  } else if (
    items.key === "/roles"
      ? hasAccess && currentRole === items.RoleAccess
      : hasAccess
  ) {
    return (
      <Menu.Item key={items.key}>
        {items.link ? (
          <NavLink
            to={items.link}
            onClick={() => {
              if (typeof items.onClick === "function") {
                items.onClick();
              }
            }}
          >
            <Row type="flex">
              <Col>
                {renderIcon(isCustomIcon, items)}
              </Col>
              {/* <Col>
                <span className={items.className}>{items.text}</span>
              </Col> */}
              <Col>{renderBadge(items)}</Col>
            </Row>
          </NavLink>
        ) : (
          <div
            onClick={() => {
              items.onClick();
            }}
          >
            <Row type="flex">
              <Col>
                {renderIcon(isCustomIcon, items)}
              </Col>
              {/* <Col>
                <span className={items.className}>{items.text}</span>
              </Col> */}
              <Col>{renderBadge(items)}</Col>
            </Row>
          </div>
        )}
      </Menu.Item>
    );
  }
  return null;
}
