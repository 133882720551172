import React from 'react';
import { Alert, Icon, Typography, Empty } from 'antd';

const { Text } = Typography;

// Add the animation styles once when the file loads
const styleSheet = document.createElement('style');
styleSheet.textContent = `
  @keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0px); }
  }
  .floating-icon {
    animation: float 3s ease-in-out infinite;
  }
`;
document.head.appendChild(styleSheet);

const NoChangesAlert = ({ actionType }) => {
  const formattedActionType = actionType
    ?.split('_')
    .map(word => word.charAt(0) + word.slice(1).toLowerCase())
    .join(' ');

  const styles = {
    alertWrapper: {
      borderRadius: '8px',
      boxShadow: '0 2px 12px rgba(0, 0, 0, 0.08)',
      background: '#ffffff',
      border: '1px solid #e8e8e8',
      padding: '24px'
    },
    contentWrapper: {
      textAlign: 'center',
      padding: '16px 0'
    },
    iconWrapper: {
      margin: '16px 0'
    },
    title: {
      fontSize: '18px',
      color: '#262626',
      display: 'block',
      marginBottom: '12px'
    },
    subtitle: {
      fontSize: '14px',
      color: 'rgba(0, 0, 0, 0.45)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '8px'
    },
    actionType: {
      color: '#1890ff',
      margin: '0 6px',
      fontWeight: 500
    },
    refreshWrapper: {
      marginTop: '16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '8px',
      color: 'rgba(0, 0, 0, 0.45)'
    }
  };

  return (
    <div style={styles.alertWrapper}>
      <div style={styles.contentWrapper}>
        <div className="floating-icon" style={styles.iconWrapper}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={null}
          />
        </div>
        <div style={styles.subtitle}>
          <Icon type="info-circle" style={{ color: '#1890ff' }} />
          <span>
            No differences were found for the
            <span style={styles.actionType}>{formattedActionType}</span>
            activity
          </span>
        </div>

        <div style={styles.refreshWrapper}>
          <Text style={{ fontSize: '13px' }}>
            Try selecting a different activity
          </Text>
        </div>
      </div>
    </div>
  );
};

export default NoChangesAlert;