import React from 'react';
import { Typography, Alert, Icon, Row, Col, Divider, Table, Tag, List } from 'antd';
import BaseModal from '../BaseModal';
import { Card, Text } from '../../common/UIComponents';
import NoChangesAlert from './NoChangesAlert';
import _ from 'lodash';

const { Title, Paragraph } = Typography;



// Action type categories with their respective icons
const ACTION_CATEGORIES = {
  BILLING_DOCS: {
    types: ['BILL_GENERATION', 'PRINT', 'EMAIL', 'SAVE_BILL','CRON'],
    icon: 'file-text',
  },
  DRIVER_PICKUP: {
    types: ['ADD_DRIVER_PICKUP_RATE'],
    icon: 'car',
  },
  DRIVER_PAY: {
    types: ['DELETE_DRIVER_PAY_ADJUSTMENT', 'CREATE_DRIVER_PAY_ADJUSTMENT', 'UPDATE_DRIVER_PAY_ADJUSTMENT'],
    icon: 'dollar',
  },
  BILLING_GROUP: {
    types: ['REMOVE_ORDER_FROM_BILLING_GROUP', 'ADD_ORDER_TO_BILLING_GROUP'],
    icon: 'group',
  },
  TRANSPORTATION: {
    types: ['UPDATE_DRIVER_TRANSPORTATION_RATE'],
    icon: 'car',
  },
  DRIVER_CHANGE: {
    types: ['DRIVER_CHANGE_FROM_BILLING'],
    icon: 'user',
  },
  FUEL: {
    types: ['UPDATE_FUEL_FROM_BILLING'],
    icon: 'gas-station',
  },
  INVOICE: {
    types: ['ADD_INVOICE_LINE', 'UPDATE_INVOICE_LINE', 'DELETE_INVOICE_LINE', 'APPLY_SMP', 'APPLY_FMP', 'REGENERATE_BILL'],
    icon: 'account-book',
  }
};


// Field configurations with proper labels and icons for Driver Adjustment Pay
const FIELD_CONFIG = {
  description: {
    label: 'Description',
    icon: 'FileTextOutlined',
  },
  type_of_loc: {
    label: 'Order Type',
    icon: 'environment'
  },
  gl_code: {
    label: 'GL Code',
    icon: 'NumberOutlined',
  },
  amount: {
    label: 'Amount',
    icon: 'DollarCircleOutlined',
  },
};

// Field configurations with proper labels and icons for Driver updation

const DRIVER_CONFIG = {
  driver_name: {
    label: 'Driver Name',
    icon: 'user',
  },
  driver_code: {
    label: 'Driver Code',
    icon: 'idcard',
  },
  phone_number: {
    label: 'Phone Number',
    icon: 'phone',
  },
}

const TRANSPORT_CONFIG = {
  driver_name: { label: 'Driver Name', icon: 'user' },
  driver_charge_value: { label: 'Driver Charge Value', icon: 'car' },
};

const INVOICE_CONFIG = {
  type_of_loc: { label: 'Order Type', icon: 'environment' },
  gl_code: { label: 'GL Code', icon: 'audit' },
  los_code: { label: 'LOS' },
  invoice_quantity: { label: 'Units', icon: 'number' },
  invoice_value: { label: 'Invoice Amount', icon: 'dollar' },
};

// Configuration for transportation rates
const TRANSPORTATION_CONFIG = {
  driver_charge_value: {
    label: 'Charge Value',
  },
};

const BillingActivitiesModal = ({ visible, onCancel, currentActionType, differences , emails=[]}) => {

  const getActionCategory = (actionType) => {
    return Object.entries(ACTION_CATEGORIES).find(([_, category]) =>
      category.types.includes(actionType)
    )?.[1] || { icon: 'exclamation-circle' };
  };

  const currentCategory = getActionCategory(currentActionType);

  const renderDriverPayAdjustmentDetails = (differences) => {
    if (!differences?.length) return null;

    const { old_value = {}, new_value = {}, change_type } = differences[0] || {};

    const columns = Object.keys(FIELD_CONFIG).map((key) => ({
      title: FIELD_CONFIG[key].label,
      dataIndex: key,
      key,
      render: (value, record) => {
        const isAmountUpdated = key === 'amount' && old_value?.amount !== new_value?.amount;
        const isNewValueUpdated = isAmountUpdated && record.key === 'new';
        return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span
              style={{
                backgroundColor: isNewValueUpdated ? 'yellow' : 'transparent',
                padding: '2px 4px',
                borderRadius: '4px'
              }}
            >
              <span>
                {key === 'amount'
                  ? (value !== null && value !== undefined && !isNaN(value) && value !== ''
                    ? `$${Number(value).toFixed(2)}`
                    : '$0.00')
                  : value}
              </span>
            </span>

        </div>
      );
      },
    }));

    return (
      <BaseModal
        title={
          <div >
            <Icon type={currentCategory.icon} style={{ marginRight: 8 }} />
            {convertedCurrentActionType}
          </div>
        }
        visible={visible}
        onCancel={onCancel}
        footer={null}
        width={1000}
        bodyStyle={{
          maxHeight: 'calc(100vh - 300px)',
          overflowY: "scroll",
          padding: 20,
        }}
      >        {change_type === 'ADDED' && new_value && (
          <Card title="New Entry" bordered={false}>
            <Table
              columns={columns}
              dataSource={[{ ...new_value, key: 'new' }]}
              pagination={false}
              rowKey="key"
              bordered
            />
          </Card>
        )}
        {change_type === 'MODIFIED' && old_value && new_value && (
          <>
            <Card title="Previous Details" bordered={false}>
              <Table
                columns={columns}
                dataSource={[{ ...old_value, key: 'old' }]}
                pagination={false}
                rowKey="key"
                bordered
              />
            </Card>
            <Card title="Updated Details" bordered={false} style={{ marginTop: 24 }}>
              <Table
                columns={columns}
                dataSource={[{ ...new_value, key: 'new' }]}
                pagination={false}
                rowKey="key"
                bordered
              />
            </Card>
          </>
        )}
        {change_type === 'REMOVED' && old_value && (
          <Card title="Deleted Entry" bordered={false}>
            <Table
              columns={columns}
              dataSource={[{ ...old_value, key: 'old' }]}
              pagination={false}
              rowKey="key"
              bordered
            />
          </Card>
        )}
        <Divider>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <Icon type="clock-circle" style={{ marginRight: 4 }} />
            <Text type="secondary" style={{ fontSize: '12px' }}>
              {change_type === 'MODIFIED'
                ? 'Changes Applied'
                : change_type === 'ADDED'
                  ? 'New Entry'
                  : 'Deleted Entry'}
            </Text>
          </span>
        </Divider>
      </BaseModal>
    );
  };

  const renderDriverInfoUpdates = (differences) => {
    if (!differences?.length) return null;
  
    const { old_value = {}, new_value = {} } = differences[0];  // Safeguard against undefined old_value/new_value
    const driver_charge_type = old_value?.driver_charge_type;
  
    const formatValue = (key, value, isUpdated = false) => {
      if (key === 'driver_charge_value') {
        const valueWithIcon = (
          <span>
            {['PERCENTAGE','percentage'].includes(driver_charge_type) ? (
              <>
                {
                  value !== null && value !== undefined && !isNaN(value) && value !== ''
                    ? `${Number(value).toFixed(2)}`
                    : `0.00`
                }
              <Icon 
                  type="percentage"
                  style={{ marginLeft: 4 }}
                />
              </>
            ) : (
              <>
                <Icon
                  type="dollar"
                  style={{ marginRight: 4 }}
                />
                  {
                    value !== null && value !== undefined && !isNaN(value) && value !== '' 
                      ? `${Number(value).toFixed(2)}`
                      : `0.00`
                  }

              </>
            )}
          </span>
        );
        return isUpdated ? <div style={{ backgroundColor: 'yellow', padding: '4px' }}>{valueWithIcon}</div> : valueWithIcon;
      }
      return value || '-';  // If value is undefined or null, return a fallback value like '-'
    };
  
    return (
      <BaseModal
        title={
          <div >
            <Icon type={currentCategory.icon} style={{ marginRight: 8 }} />
            {convertedCurrentActionType}
          </div>
        }
        visible={visible}
        onCancel={onCancel}
        footer={null}
        width={1000}
        bodyStyle={{
          maxHeight: 'calc(100vh - 550px)',
          overflowY: "scroll",
          padding: 20,
        }}
      >
        <div style={{ display: 'flex', gap: '24px' }}>
          <Card title="Previous Details" bordered={false} style={{ flex: 1 }}>
            {Object.keys(TRANSPORT_CONFIG).map((key) => (
              old_value?.[key] !== undefined && old_value?.[key] !== null ? (
                <div 
                  key={key} 
                  style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    marginBottom: 12 
                  }}
                >
                  <Icon 
                    type={TRANSPORT_CONFIG[key].icon} 
                    style={{ marginRight: 8, fontSize: '16px' }} 
                  />
                  <Text>{TRANSPORT_CONFIG[key].label}:</Text>
                  <Text type="secondary" style={{ marginLeft: 8 }}>
                    {formatValue(key, old_value[key])}
                  </Text>
                </div>
              ) : null
            ))}
          </Card>
          <Card title="Updated Details" bordered={false} style={{ flex: 1 }}>
            {Object.keys(TRANSPORT_CONFIG).map((key) => (
              new_value?.[key] !== undefined && new_value?.[key] !== null ? (
                <div 
                  key={key} 
                  style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    marginBottom: 12 
                  }}
                >
                  <Icon 
                    type={TRANSPORT_CONFIG[key].icon} 
                    style={{ marginRight: 8, fontSize: '16px' }} 
                  />
                  <Text>{TRANSPORT_CONFIG[key].label}:</Text>
                  <Text type="secondary" style={{ marginLeft: 8 }}>
                    {formatValue(key, new_value[key], old_value?.[key] !== new_value?.[key])}
                  </Text>
                </div>
              ) : null
            ))}
          </Card>
        </div>
      </BaseModal>
    );
  };

  const renderEmailDifferences = (emails) => {
    return (
      <BaseModal
        title={
          <div>
            <Icon type={currentCategory.icon} style={{ marginRight: 8 }} />
            {'Emails List'}
          </div>
        }
        visible={visible}
        onCancel={onCancel}
        footer={null}
        width="850px"
        bodyStyle={{ padding: '20px', background: 'linear-gradient(135deg, #f0f4ff, #ffffff)' }}
      >
        <Card
          bordered={false}
          style={{
            borderRadius: '10px',
            boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)',
            padding: '16px',
            background: '#ffffff',
            maxHeight: '600px', // Sets the max height for scroll
            overflowY: 'auto', // Enables scrolling when content exceeds max height
          }}
        >
          <List
            dataSource={emails}
            renderItem={(email, idx) => (
              <List.Item
                key={idx}
                style={{
                  padding: '14px 20px',
                  borderRadius: '8px',
                  marginBottom: '8px',
                  background: '#f9f9f9',
                  display: 'block',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
                  transition: 'transform 0.2s, background 0.3s',
                  cursor: 'pointer',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.background = '#f0f4ff';
                  e.currentTarget.style.transform = 'translateY(-3px)';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.background = '#f9f9f9';
                  e.currentTarget.style.transform = 'translateY(0)';
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Icon type="mail" style={{ marginRight: '12px' }} />
                  <div style={{ fontSize: '15px', fontWeight: '500', textAlign: 'left', flexGrow: 1 }}>
                    {email}
                  </div>
                </div>
              </List.Item>
            )}
          />
        </Card>
      </BaseModal>
    );
  };
  
  


  const ChangeCard = ({ title, oldValue, newValue }) => {
    const isUpdated = oldValue !== newValue;
  
    return (
      <div
        style={{
          padding: '10px',
          border: '1px solid #ddd',
          borderRadius: '5px',
        }}
      >
        <h3><strong>{title}</strong></h3>
        <p>
          <strong>Previous Value:</strong>
          <span
            style={{
              // backgroundColor: isUpdated ? 'yellow' : 'transparent', // Highlight only if updated
              // padding: isUpdated ? '2px 4px' : '0',
              borderRadius: '4px',
            }}
          >
            {oldValue}
          </span>
        </p>
        <p>
          <strong>New Value:</strong>
          <span
            style={{
              backgroundColor: isUpdated ? 'yellow' : 'transparent', // Highlight only if updated
              padding: isUpdated ? '2px 4px' : '0',
              borderRadius: '4px',
            }}
          >
            {newValue}
          </span>
        </p>
      </div>
    );
  };

  const renderChangesModal = (differences) => {
    // Guard clause for when differences is undefined or not an array
    if (!differences || !Array.isArray(differences)) {
      return null;
    }
    
    const formatValue = (value, objType) => {
      // Return 'N/A' for falsy values (null, undefined, empty string)
      if (value === null || value === undefined || value === '') {
        return 'N/A';
      }
  
      // If the value is an array, return a comma-separated string
      if (Array.isArray(value)) {
        return value.join(', ');
      }
    
      // Check if the value is an object and convert to a string if necessary
      if (typeof value === 'object') {
        // Ensure the value is not null before accessing properties
        if (value && value.invoice_number) {
          return value.invoice_number; // Return invoice number if it's present
        }
        // Return a string representation of the object if it doesn't contain `invoice_number`
        return JSON.stringify(value);
      }
    
      // Handle `invoice_total` separately, applying the dollar sign
      if (objType === 'invoice_total') {
        const numericValue = parseFloat(value);
        if (!isNaN(numericValue)) {
          return `$${numericValue.toFixed(2)}`;
        }
      }
    
      // For `INVOICE_NUMBERS` and `ORDER_NUMBERS`, return as is without dollar sign
      if (objType !== 'invoice_total') {
        return value;
      }
    
      // Handle numeric values (e.g., totals, amounts) for other cases
      if (typeof value === 'number' || (!isNaN(value) && value !== null && value !== undefined)) {
        const parsedValue = parseFloat(value);
        if (!isNaN(parsedValue)) {
          return `$${parsedValue.toFixed(2)}`;
        }
      }
    
      // Return the value as is for non-monetary and non-numeric values
      return value;
    };
    

    
    const getCardTitle = (diff) => {
      const titles = {
        invoice_total: 'Total Invoice Amount',
        ORDER_NUMBERS: 'Order Numbers',
        INVOICE_NUMBERS: 'Invoice Number'
      };
  
      if (diff?.obj_type) {
        return titles[diff.obj_type] || diff.obj_type;
      }
  
      // For invoice number changes that come without obj_type
      if (diff?.old_value?.invoice_number || diff?.new_value?.invoice_number) {
        return 'Invoice Number';
      }
  
      return 'Changes';
    };
  
    return (
      <BaseModal
        title={
          <div>
            <Icon type="diff" style={{ marginRight: 8 }} />
            {convertedCurrentActionType}
          </div>
        }
        visible={visible}
        onCancel={onCancel}
        footer={null}
        width="500px"
      >
        <div className="space-y-4">
          {differences.map((diff, index) => (
            <ChangeCard
              key={index}
              title={getCardTitle(diff)}
              oldValue={formatValue(diff.old_value,diff.obj_type)}
              newValue={formatValue(diff.new_value,diff.obj_type)}
            />
          ))}
        </div>
      </BaseModal>
    );
  };
  
  

  const renderUpdatedInvoiceLines = (differences) => {
    if (!differences?.length) return null;
  
    const billingTotal = differences.find(diff => diff?.obj_type === 'billing_total');
    const tableDifferences = differences.filter(diff => !diff?.obj_type);
  
    const groupedDifferences = tableDifferences.reduce((acc, diff) => {
      const itemType = diff?.new_value?.item_type ?? diff?.old_value?.item_type ?? 'Unknown';
      if (!acc[itemType]) {
        acc[itemType] = {
          ADDED: [],
          REMOVED: [],
          MODIFIED: []
        };
      }
      acc[itemType][diff?.change_type]?.push(diff);
      return acc;
    }, {});
  
    const columns = Object.keys(INVOICE_CONFIG).map((key) => ({
        title: INVOICE_CONFIG[key]?.label ?? 'N/A',
        dataIndex: key,
        key,
        render: (value, record) => (
          <div className="flex items-center">
            {record?.added_from === "MANUAL" ? (
              <sup className="textRed textBold">M</sup>
            ) : null}
            <span>{typeof value === 'number' ? value.toFixed(2) : value ?? 'N/A'}</span>
          </div>
        ),
      }));

      const baseTableColumns = [
        {
          title: 'Description',
          dataIndex: 'invoice_name',
          key: 'invoice_name',
          width: '30%',
          render: (value, record) => (
            <div className="flex items-center">
              <span>{value ?? 'N/A'}</span>
              {record?.added_from === "MANUAL" ? (
                <sup className="textRed textBold">M</sup>
              ) : null}
            </div>
          ),
        },
        ...columns.map((column) => ({
        ...column,
          render: (value) => (
            <div className="flex items-center">
              {column.key === 'invoice_value' && <span className="icon-dollar" style={{ marginRight: 4 }}>$</span>}
              <span>
                {column.key === 'invoice_value'
                  ? (value !== null && value !== undefined && !isNaN(value) && value !== '' 
                      ? Number(value).toFixed(2)
                      : '0.00')
                  : (value ?? 'N/A')}
              </span>
            </div>
          ),
        })),
      ];
      



    const mapChangesToDataSource = (changes, type) =>
      changes.map((change, index) => ({
        ...(change?.[type] ?? {}),
        added_from: change?.[type]?.added_from ?? null,
        key: `${type}-${index}`,
      }));


    const renderModifiedGroup = (changes) => {
      if (!changes?.length) return null;
      return (
        <div className="mb-6">
           <h4 className="ant-typography ant-typography-md font-medium mb-3" style={{ textAlign: 'left', fontSize: '18px' }}>Modified Entries</h4>
          <h5 className="ant-typography ant-typography-sm font-medium mb-2" style={{ textAlign: 'left', color: '#666' }}>Previous Values
          
            </h5>
          <div className="mb-4">
            <Card>
              <Table
                columns={baseTableColumns}
                dataSource={mapChangesToDataSource(changes, 'old_value')}
                pagination={false}
                bordered
              />
            </Card>
          </div>
           <h5 className="ant-typography ant-typography-sm font-medium mb-2" style={{ textAlign: 'left', color: '#666' }}>
              Updated Values
            </h5>
          <div>
            <Card>
              <Table
                columns={baseTableColumns}
                dataSource={mapChangesToDataSource(changes, 'new_value')}
                pagination={false}
                bordered
              />
            </Card>
          </div>
        </div>
      );
    };
  
    const renderRegularGroup = (changes, title, type) => {
      if (!changes?.length) return null;

      return (
        <div className="mb-6">
          <h4 className="ant-typography ant-typography-md font-medium mb-3" style={{ textAlign: 'left', fontSize: '16px' }}>{title}</h4>
          <Card>
            <Table
              columns={baseTableColumns}
              dataSource={changes.map((change, index) => ({
                ...(type === 'ADDED' ? change?.new_value : change?.old_value) ?? {},
                key: index,
              }))}
              pagination={false}
              bordered
            />
          </Card>
        </div>
      );
    };
  
    return (
      <BaseModal
        title={
          <div >
            <Icon type={currentCategory.icon} style={{ marginRight: 8 }} />
            {convertedCurrentActionType}
          </div>
        }
        visible={visible}
        onCancel={onCancel}
        footer={null}
        width={1000}
        bodyStyle={{
          maxHeight: 'calc(100vh - 200px)', overflowY: "scroll",
          padding: 20,
        }}
      >
        {billingTotal && (
          <Card className="p-6">
            <h4 className="ant-typography ant-typography-md font-medium mb-3">Total Billing Amount</h4>
            <div className="space-y-2">
              <p className="text-gray-700">
                Previous Amount: $
                {billingTotal?.old_value !== null && billingTotal?.old_value !== undefined && !isNaN(billingTotal?.old_value) && billingTotal?.old_value !== ''
                  ? Number(billingTotal?.old_value).toFixed(2)
                  : '0.00'}
              </p>
              <p className="text-gray-700">
                Updated Amount:
                <span
                  style={{
                    backgroundColor: billingTotal?.new_value !== billingTotal?.old_value ? '#FFEB3B' : 'transparent',
                    padding: '2px 4px',
                    borderRadius: '4px',
                  }}
                >
                  $
                  {billingTotal?.new_value !== null && billingTotal?.new_value !== undefined && !isNaN(billingTotal?.new_value) && billingTotal?.new_value !== ''
                    ? Number(billingTotal?.new_value).toFixed(2)
                    : '0.00'}
                </span>
              </p>
            </div>
          </Card>
        )}

        {Object.entries(groupedDifferences).map(([itemType, changeGroups]) => (
          <div key={itemType} className="mt-6">
            <h4 className="ant-typography ant-typography-md font-medium mb-3" style={{ textAlign: 'left', fontSize: '18px' }}>
              <span style={{ fontSize: '20px', color: '#395484' }}>{`${itemType} ${itemType !== 'Fuel Surcharges' ? 'Charges' : ''}`}</span>
            </h4>
            {renderRegularGroup(changeGroups.REMOVED, 'Previous Entries', 'REMOVED')}
            {renderRegularGroup(changeGroups.ADDED, 'New Entries', 'ADDED')}
            {renderModifiedGroup(changeGroups.MODIFIED)}
          </div>
        ))}
      </BaseModal>
    );
  };

  const renderPickupDriverPays = (differences) => {
    if (!differences?.length) return null;

    const { old_value = [], new_value = [], change_type } = differences[0] || {};

    const columns = [
      {
        title: 'Driver Name',
        dataIndex: 'driver_name',
        key: 'driver_name',
        render: (text) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{text || 'N/A'}</span>
          </div>
        ),
      },
      {
        title: 'Driver Code',
        dataIndex: 'driver_code',
        key: 'driver_code',
        render: (text) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{text || 'N/A'}</span>
          </div>
        ),
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (text) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>
              {text !== null && text !== undefined && !isNaN(text) && text !== ''
                ? `$${Number(text).toFixed(2)}`
                : '$0.00'}
            </span>
          </div>
        ),
      },
    ];

    return (
      <BaseModal
        title={
          <div >
            <Icon type={currentCategory.icon} style={{ marginRight: 8 }} />
            {convertedCurrentActionType}
          </div>
        }
        visible={visible}
        onCancel={onCancel}
        footer={null}
        width={1000}
        bodyStyle={{
          maxHeight: 'calc(100vh - 300px)',
          overflowY: "scroll",
          padding: 20,
        }}
      >  
        {change_type === 'ADDED' && (
          <Card title="New Entry" bordered={false}>
            <Table
              columns={columns}
              dataSource={new_value.map((item, index) => ({ ...item, key: `new-${index}` }))}
              pagination={false}
              bordered
            />
          </Card>)}

        {change_type === 'MODIFIED' && old_value?.length > 0 && new_value?.length > 0 && (
          <>
            <Card title="Previous Entry" bordered={false}>
              <Table
                columns={columns}
                dataSource={old_value.map((item, index) => ({ ...item, key: `old-${index}` }))}
                pagination={false}
                bordered
              />
            </Card>
            <Card title="Updated Entry" bordered={false} style={{ marginTop: 24 }}>
              <Table
                columns={columns}
                dataSource={new_value.map((item, index) => ({ ...item, key: `new-${index}` }))}
                pagination={false}
                bordered
              />
            </Card>
          </>
        )}
        {change_type === 'REMOVED' && old_value?.length > 0 && (
          <Card title="Deleted Entry" bordered={false}>
            <Table
              columns={columns}
              dataSource={old_value.map((item, index) => ({ ...item, key: `old-${index}` }))}
              pagination={false}
              bordered
            />
          </Card>
        )}
        <Divider>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <Icon type="clock-circle" style={{ marginRight: 4 }} />
            <Text type="secondary" style={{ fontSize: '12px' }}>
              {change_type === 'MODIFIED'
                ? 'Changes Applied'
                : change_type === 'ADDED'
                  ? 'New Entry'
                  : 'Deleted Entry'}
            </Text>
          </span>
        </Divider>
      </BaseModal>
    );
  };

  const renderDriverSwitchDetails = (differences) => {
    if (!differences?.length) return null;
  
    // Safely destructure values with fallback to empty objects if undefined
    const { old_value: driverOldValue = {}, new_value: driverNewValue = {} } = differences[0] || {};
    const { old_value: ratesOldValue = {}, new_value: ratesNewValue = {} } = differences[1] || {};
    const locationType = driverOldValue?.type_of_loc || 'N/A';  // Fallback if type_of_loc is missing
  
    const renderValueCard = (values, isOld = false, config, title) => {
      // Safely destructure values and exclude type_of_loc, fallback to empty object if undefined
      const { type_of_loc, ...details } = values || {};
  
      return (
        <Card
          style={{
            background: '#fff',
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.09)',
            marginBottom: '16px'
          }}
        >
          <div style={{ borderBottom: '1px solid #f0f0f0', marginBottom: 16, paddingBottom: 8 }}>
            <Title level={4} style={{ margin: 0, color: isOld ? '#595959' : '#1890ff' }}>
              <Icon
                type={isOld ? 'profile' : 'solution'}
                style={{
                  marginRight: 8,
                  fontSize: '20px',
                  color: isOld ? '#595959' : '#1890ff'
                }}
              />
              {isOld ? `Previous ${title}` : `Updated ${title}`}
            </Title>
          </div>
  
          <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            {Object.entries(details).map(([key, value]) => {
              const fieldConfig = config[key];
              if (!fieldConfig) return null;
  
              const isChargeValue = key.includes('charge_value');
              const isPercentage = key.includes('percentage');
              const isDriverCharge = key === 'driver_charge_value';

              return (
                <div
                  key={key}
                  style={{
                    padding: '8px 12px',
                    background: '#fafafa',
                    borderRadius: '4px',
                    transition: 'all 0.3s'
                  }}
                >
                  <Row type="flex" align="middle">
                    <Col span={24}>
                      <div style={{ display: 'grid', gridTemplateColumns: '24px 150px auto', alignItems: 'center', gap: '8px' }}>
                        <Icon
                          type={fieldConfig.icon}
                          style={{
                            color: isOld ? '#8c8c8c' : '#1890ff',
                            fontSize: '16px'
                          }}
                        />
                        <Text strong style={{ color: '#262626' }}>
                          {fieldConfig.label}:
                        </Text>
                        <Text
                          style={{
                            color: isOld ? '#8c8c8c' : '#262626'
                          }}
                        >
                          {isChargeValue ? (
                            <>
                              {isDriverCharge && ['PERCENTAGE', 'percentage'].includes(values?.driver_charge_type) ? (
                                `${Number(value).toFixed(2)}%`
                              ) : (
                                `$${Number(value).toFixed(2)}`
                              )}
                            </>
                          ) : (
                            value
                          )}
                        </Text>
                      </div>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
        </Card>
      );
    };
  
    return (
      <BaseModal
        title={
          <div>
            <Icon type={currentCategory.icon} className="mr-2" />
            {convertedCurrentActionType}
          </div>
        }
        visible={visible}
        onCancel={onCancel}
        footer={null}
        width={1000}
        bodyStyle={{
          maxHeight: 'calc(100vh - 150px)',
          overflowY: "scroll",
          padding: 20,
        }}
      >
        {/* Location Type indicator */}
        <div className="mb-6 text-center">
          <Card className="inline-block bg-gray-50">
            <div className="flex items-center gap-2">
              <Icon type="environment" className="text-blue-500" />
              <Text strong>
                Location Type: <span className="text-blue-500">{locationType}</span>
              </Text>
            </div>
          </Card>
        </div>
  
        {/* Driver Details Section */}
        <Row gutter={24} type="flex" justify="space-between">
          <Col span={12}>
            {renderValueCard(driverOldValue, true, DRIVER_CONFIG, 'Driver Details')}
          </Col>
          <Col span={12}>
            {renderValueCard(driverNewValue, false, DRIVER_CONFIG, 'Driver Details')}
          </Col>
        </Row>
  
        <Divider>
          <Text type="secondary" style={{ fontSize: '12px' }}>
            <Icon type="clock-circle" style={{ marginRight: 4 }} />
            Changes Applied
          </Text>
        </Divider>
  
        {/* Transportation Rates Section */}
        <div className="mb-6 text-center">
          <Card className="inline-block bg-gray-50">
            <div className="flex items-center gap-2">
              <Icon type="car" className="text-blue-500" />
              <Text strong>
                Transportation Driver Rates
              </Text>
            </div>
          </Card>
        </div>
  
        <Row gutter={24} type="flex" justify="space-between">
          <Col span={12}>
            {renderValueCard(ratesOldValue, true, TRANSPORTATION_CONFIG, 'Transportation Rates')}
          </Col>
          <Col span={12}>
            {renderValueCard(ratesNewValue, false, TRANSPORTATION_CONFIG, 'Transportation Rates')}
          </Col>
        </Row>
  
        <Divider>
          <Text type="secondary" style={{ fontSize: '12px' }}>
            <Icon type="clock-circle" style={{ marginRight: 4 }} />
            Changes Applied
          </Text>
        </Divider>
      </BaseModal>
    );
  };
  const convertedCurrentActionType = _.startCase(currentActionType.toLowerCase().split('_').join(' '));
  return (
    <>
      {['DRIVER_CHANGE_FROM_BILLING'].includes(currentActionType) && differences.length ? (
        renderDriverSwitchDetails(differences)
      ) : ['DELETE_DRIVER_PAY_ADJUSTMENT', 'CREATE_DRIVER_PAY_ADJUSTMENT', 'UPDATE_DRIVER_PAY_ADJUSTMENT'].includes(currentActionType) && differences.length ? (
        renderDriverPayAdjustmentDetails(differences)
      ) : ['ADD_DRIVER_PICKUP_RATE'].includes(currentActionType) && differences.length ? (
        renderPickupDriverPays(differences)
      ) : ['ADD_INVOICE_LINE', 'UPDATE_INVOICE_LINE', 'DELETE_INVOICE_LINE', 'APPLY_SMP', 'APPLY_FMP', 'REGENERATE_BILL', 'REMOVE_ORDER_FROM_BILLING_GROUP', 'ADD_ORDER_TO_BILLING_GROUP', 'UPDATE_FUEL_FROM_BILLING'].includes(currentActionType) && differences.length ? (
        renderUpdatedInvoiceLines(differences)
      ) : ['UPDATE_DRIVER_TRANSPORTATION_RATE'].includes(currentActionType) && differences.length ? (
        renderDriverInfoUpdates(differences)
      ) : ['EMAIL', 'CRON'].includes(currentActionType) ? (
        renderEmailDifferences(emails)
      ) : ['INVOICE_REGENERATE', 'INVOICE_GENERATED', 'REMOVE_ORDER', 'ADD_ORDER', 'MANUAL_INVOICE_NUMBER_CHANGE'].includes(currentActionType) && differences.length ? (
        renderChangesModal(differences)
      ) : (
        <>
          <BaseModal
            title={
              <div>
                <Icon type={currentCategory.icon} style={{ marginRight: 8 }} />
                {convertedCurrentActionType}
              </div>
            }
            visible={visible}
            onCancel={onCancel}
            footer={null}
            width={1000}
            bodyStyle={{
              maxHeight: 'calc(100vh - 250px)',
              overflowY: 'scroll',
              padding: 20,
            }}
          >
            <NoChangesAlert actionType={convertedCurrentActionType} onCancel={onCancel} />
          </BaseModal>
        </>
      )}
    </>
  );

};

export default BillingActivitiesModal;
