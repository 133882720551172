import React, { useEffect, useState } from "react";
import noImg from "../../assets/images/noImg.png";
import I18n from "./I18n";
import PropTypes from "prop-types";
import { Icon, Tooltip } from "../../../src/javascripts/common/UIComponents";
import Gallery from "../components/common/Gallery";

export const TableImageColumn = ({ record,width,height,borderRadius,marginLeft,isBase64Data, renderType = 'image' }) => {
  const [galleryImgs, setGalleryImgs] = useState([]);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const ImgSrc = record ? record : noImg;
  const tooltipTitle = record ? undefined : I18n.t("vehicleTypes.noImage");
  const [errorImage, setErrorImage] = useState({});

  const handleImageClick = () => {
    if (record) {
      setLightboxIsOpen(true);
      setGalleryImgs([{ src: isBase64Data ? `data:image/jpeg;base64,${ImgSrc}` : ImgSrc }]);
    }
  };

  const handleCloseGallery = () => {
    setLightboxIsOpen(false);
    setGalleryImgs([]);
  };

const handleEscapeKey = (event) => {
  if (event.key === "Escape") {
    handleCloseGallery();
  }
};

useEffect(() => {
  document.addEventListener("keydown", handleEscapeKey);
  return () => {
    document.removeEventListener("keydown", handleEscapeKey);
  };
}, []);

  return (
    <Tooltip title={tooltipTitle} placement="left">
      {
        renderType === 'image' ? <img
        src={isBase64Data ? `data:image/png;base64,${ImgSrc}` : ImgSrc}
        alt="image"
        style={{ width: `${width}px`, height:`${height}px`,borderRadius: `${borderRadius}px`, cursor: "pointer",marginLeft:`${marginLeft}px` }}
        onClick={handleImageClick}
        onError={(e) => {
          setErrorImage(prevStatus => ({...prevStatus, [record] : true}));
          e.target.src = noImg
        }}
      />
      :
      <Icon type="eye" theme="twoTone" onClick={handleImageClick} style={{fontSize: "20px",paddingTop:'2px'}} />
      }
      {lightboxIsOpen && galleryImgs.length > 0 && (
        <Gallery images={galleryImgs} onClose={handleCloseGallery} />
      )}
    </Tooltip>
  );
};

TableImageColumn.propTypes = {
  record: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height:PropTypes.string.isRequired,
  isBase64Data: PropTypes.bool,
};

TableImageColumn.defaultProps = {
  isBase64Data: false
};
