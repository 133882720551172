import React, { useEffect } from 'react';
import { Icon, Input, AutoComplete, Spin, Typography } from 'antd';
import { debounce } from 'lodash';
import { parseAddressComponents, removeUnnecessaryCommas } from '../../helpers/common';
import { fetchPredefinedWHLocs } from '../../api/LocationsApi';

const { Option, OptGroup } = AutoComplete;
const { Text } = Typography;

function AddressSearch (props) {
  const { hidePredefined = false, hideWebResults = false , doesAddressExist } = props
  const [ searchText, setSearchText ] = React.useState('');
  const [ filteredOptions, setFilteredOptions ] = React.useState([]);
  const [ loading, setLoading ] = React.useState(false);

  useEffect(() => {
    if(!doesAddressExist){
      setSearchText('');
      setFilteredOptions([]);
      setLoading(false);
    }
  }, [doesAddressExist]);

  const fetchPredefinedStops = async (searchText) => {
    setLoading(true);
    try {
      const data = await fetchPredefinedWHLocs({
        search_text: searchText,
      });
      return data.locations.map((stop) => ({ ...stop, type : 'predefined' }));
    } catch (error) {
      console.error('Error fetching predefined stops:', error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  const fetchGooglePlaceDetails = async (value) => {
    const google = window.google;
    const autocompleteService = new google.maps.places.AutocompleteService();
    const predictions = await new Promise((resolve) => {
      autocompleteService.getPlacePredictions({ 
        input: value,
        // types: ['establishment'] // Focus on businesses/establishments
      }, (predictions) => {
        resolve(predictions);
      });
    });

    const placesService = new google.maps.places.PlacesService(document.createElement('div'));

    const placeDetailsPromises = Array.isArray(predictions) ? predictions.map((prediction) => {
      return new Promise((resolve) => {
        placesService.getDetails({
          placeId: prediction.place_id,
          fields: ['name', 'formatted_address', 'geometry', 'address_components', 'business_status', 'types' , 'place_id' ]
        }, (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            if (place.geometry && place.geometry.location && lat && lng) { 
              const { l_address } = parseAddressComponents(place.address_components);
              
              // Get business name if it's an establishment
              const business_name = place.types.includes('establishment') ? place.name : 
                place.address_components.find(
                  component => component.types.includes('establishment')
                )?.long_name || "";

              resolve({
                id: place.place_id,
                name: place.name, // Original place name
                company_name: business_name, // New separate business name field
                address: place.formatted_address,
                l_address: {...l_address, coordinates: [lng, lat]},
                business_status: place.business_status,
              });
            } else {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        });
      });
    }) : [];

    const placeDetails = await Promise.all(placeDetailsPromises);
    const filteredPlaceDetails = placeDetails.filter((place) => place !== null);

    return filteredPlaceDetails.map((place) => ({ ...place, type : 'web' }));
  };

  const debouncedFetchStops = React.useMemo(
    () =>
      debounce(async (searchText) => {
        const [ predefinedStops, googlePlaceDetails ] = await Promise.all([
          !hidePredefined ? fetchPredefinedStops(searchText) : [],
          !hideWebResults ? fetchGooglePlaceDetails(searchText) : [],
        ]);

        setFilteredOptions([ ...predefinedStops, ...googlePlaceDetails ]);
      }, 500),
    [ hidePredefined, hideWebResults ]
  );

  const handleSearch = (value) => {
    setSearchText(value);
    debouncedFetchStops(value);
  };


  const format_l_address = (selectedAddress) => selectedAddress.l_address ? selectedAddress.l_address.address_line1 + ", " + selectedAddress.l_address.address_line2 + ", " + selectedAddress.l_address.city + ", " + selectedAddress.l_address.state + ", " + selectedAddress.l_address.zipcode + ", " + selectedAddress.l_address.country : "";

  const handleSelect = (value) => {
    const selectedAddress = filteredOptions.find((address) => address.id === value);
    props.onSelect(selectedAddress);

    setSearchText(removeUnnecessaryCommas(format_l_address(selectedAddress)));
  };

  // Define a function to generate option elements for a given type of address
  const generateOptions = (addresses, formatFunction) =>
    addresses.map((address) => (
      <Option key={ address.id } value={address.id}> 
        <Text strong>{ address.name }</Text>
        { ' ' }
        <Text type="secondary">{ formatFunction(address) }</Text>
      </Option>
    ));

  // Filter addresses based on type and generate options for predefined addresses
  const predefinedStopsOptions = generateOptions(
    filteredOptions.filter((address) => address.type === 'predefined'),
    format_l_address
  );

  // Filter addresses based on type and generate options for web addresses
  const webResultsOptions = generateOptions(
    filteredOptions.filter((address) => address.type === 'web'),
    (address) => address.address
  );


  return (
    <AutoComplete
      className="certain-category-search"
      dropdownClassName="certain-category-search-dropdown"
      dropdownMatchSelectWidth={ false }
      dropdownStyle={ { width: 300 } }
      size="default"
      style={ { width: '100%', marginBottom: 10 } }
      value={ searchText }
      dataSource={
        loading ? (
          [ <Option key="loading" disabled><Spin /> Fetching addresses</Option> ]
        ) : (
          [
            !hidePredefined && (
              <OptGroup key="predefined" label="Predefined">
                { predefinedStopsOptions.length === 0 ? (
                  <Option key="no-results" disabled>No results found</Option>
                ) : (
                  predefinedStopsOptions
                ) }
              </OptGroup>
            ),
            !hideWebResults && (
              <OptGroup key="web" label="Web Results">
                { webResultsOptions.length === 0 ? (
                  <Option key="no-results" disabled>No results found</Option>
                ) : (
                  webResultsOptions
                ) }
              </OptGroup>
            ),
          ].filter(Boolean)
        )
      }
      onChange={ (value) =>  value?.length === 0 && setFilteredOptions([]) }
      onSearch={ handleSearch }
      onSelect={ handleSelect }
      placeholder="Search addresses"
      optionLabelProp="value"
    >
      <Input suffix={ <Icon type="search" className="certain-category-icon" /> } />
    </AutoComplete>
  );
}

export default AddressSearch;
