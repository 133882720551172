/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { Fragment, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from "lodash";
import {
  Col,
  Row,
  FormItem,
  Input,
  SideBySideFormItem,
  Checkbox
} from '../../common/UIComponents'

import AddressForm from '../common/AddressForm'
import MobileInput from '../common/MobileInput'
import AddressSearch from '../../stories/components/AddressSearch';
import I18n from '../../common/I18n';
import LabelInfo from '../configurations/LabelInfo';
import { OrgContext } from '../../context/OrgContext';

const ConsigneeDetailsForm = (props) => {
    const { currentOrg } = useContext(OrgContext)
    const {
    formData = {},
    handleOnAddressChange,
    handleOnChange,
    map,
    editableFields = [],
    includePredefinedStops = false,
    predefined_locations,
    showpredefined_loc = false,
    reqFrom = "",
    gridSize = {
      xs: 24,
      sm: 24,
      md: 12,
      lg: 4,
      xl: 4,
    },
    size = "default", 
    requireSideBySide=false,
    orderType,
    customerReqFields = [],
    reqMinimumDetails = false,
    accountConfigs={},
    isPickup = false
  } = props

  const requireCheck = !_.isEmpty(accountConfigs) && _.get(accountConfigs,  'move_to_exception_on_invalid_number', "false") === "true" ? true : false

  const isLKTOrg = currentOrg.code === 'LK'

  const onConsigneeLocationChange = (value) => {
    const { first_name, last_name, email, phone,company_name,acc_loc_code, l_address, l_type } = value
    handleOnChange('customer_first_name', first_name)
    handleOnChange('customer_last_name', last_name)
    handleOnChange('is_predefined_loc', is_predefined_loc)
    handleOnChange('email', email)
    handleOnChange('customer_phone_one', phone)
    handleOnChange('company_name', company_name)
    handleOnChange('acc_loc_code', acc_loc_code)
    handleOnChange('l_type', l_type === 'WH' && reqFrom !== 'orders' ? 'WH' : 'CS')
    handleOnAddressChange('', '', l_address)
  }

  const renderContactForm = (isDisabled) => {
    const {isDeliveryRequired} = props;
    return(
    <Fragment>
      {/* <h4>Contact Details</h4> */}
      {
        reqMinimumDetails ?
        <Row gutter={[8,8]} className='consignee-details-form marginTop10'>
          <Col { ...{...gridSize, lg: 12, xl: 12} } require={isDeliveryRequired}>
            <SideBySideFormItem label={ I18n.t('location.contact_name')} 
              labelCol={{lg: 7, xl: 7, xxl: 7}}
              wrapperCol={{lg: 17, xl: 17, xxl: 17}}
              require={customerReqFields.length > 0 && customerReqFields.includes('customer_first_name')}
            >
              <Input
                value={ formData.customer_first_name }
                onChange={ (e) => handleOnChange('customer_first_name', e.target.value) }
                disabled={ isDisabled }
                size={size}
                placeholder={ I18n.t('location.contact_name')}
              />
            </SideBySideFormItem>
          </Col>
          <Col { ...{...gridSize, lg: 12, xl: 12} }>
            <SideBySideFormItem label={`${I18n.t('general.contact')} No`} 
            require={isDeliveryRequired}
            labelCol={{lg: 7, xl: 7, xxl: 7}}
            wrapperCol={{lg: 17, xl: 17, xxl: 17}}
            className={"alignRight"}
            >
              <MobileInput
                handleInputChange={ (value, data) =>
                  handleOnChange('customer_phone_one', value, data)
                }
                value={ formData.customer_phone_one || '' }
                disabled={ !editableFields.includes('customer_phone_one') || isDisabled }
                size={size}
                inputStyle={size === 'small' ? { width: '100%' , height : 20 } : null}
              />
            </SideBySideFormItem>
          </Col>
      </Row>
      :
      <Row gutter={[8,8]} className='consignee-details-form marginTop10'>
          <Col { ...{...gridSize, lg: 12, xl: 12} } require={isDeliveryRequired}>
            <SideBySideFormItem label={ I18n.t('general.first_name')} 
              labelCol={{lg: 7, xl: 7, xxl: 7}}
              wrapperCol={{lg: 17, xl: 17, xxl: 17}}
              // require={(customerReqFields.length > 0 && customerReqFields.includes('customer_first_name')) || isDeliveryRequired}
              // turned of validation entirely for now
              require={false}
            >
              <Input
                value={ formData.customer_first_name }
                onChange={ (e) => handleOnChange('customer_first_name', e.target.value) }
                disabled={ isDisabled }
                size={size}
                placeholder={ I18n.t('general.first_name')}
              />
            </SideBySideFormItem>
          </Col>
          <Col { ...{...gridSize, lg: 12, xl: 12} }>
            <SideBySideFormItem label={ I18n.t('general.last_name')} 
              labelCol={{lg: 7, xl: 7, xxl: 7}}
              wrapperCol={{lg: 17, xl: 17, xxl: 17}}
            >
              {" "}
               <Input
                value={ formData.customer_last_name }
                onChange={ (e) => handleOnChange('customer_last_name', e.target.value) }
                disabled={ isDisabled }
                size={size}
                placeholder={ I18n.t('general.last_name')}
              />
            </SideBySideFormItem>
          </Col>
          <Col { ...{...gridSize, lg: 12, xl: 12} }>
            <SideBySideFormItem label={ I18n.t("general.company") }
              labelCol={{lg: 7, xl: 7, xxl: 7}}
              wrapperCol={{lg: 17, xl: 17, xxl: 17}}
              require={(isLKTOrg && isPickup) || (isLKTOrg && isDeliveryRequired)}
            >
              <Input
                value={ formData.company_name }
                onChange={ (e) => handleOnChange("company_name", e.target.value) }
                disabled={ isDisabled }
                size={size}
              />
            </SideBySideFormItem>
          </Col>
          {/* <Col { ...gridSize }>
            <SideBySideFormItem label={ I18n.t('general.last_name') }>
             
            </SideBySideFormItem>
          </Col> */}
          <Col { ...{...gridSize, lg: 12, xl: 12} }
          >
            <SideBySideFormItem label={ I18n.t('general.email') } className="alignRight paddingRight5"
            labelCol={{lg: 7, xl: 7, xxl: 7}}
            wrapperCol={{lg: 17, xl: 17, xxl: 17}}
            >
              <Input
                value={ formData.customer_email }
                onChange={ (e) => handleOnChange('customer_email', e.target.value) }
                disabled={ !editableFields.includes('email') || isDisabled }
                size={size}
              />
            </SideBySideFormItem>
          </Col>
          <Col { ...{...gridSize, lg: 8, xl: 8} }>
            <SideBySideFormItem
             label={
              <span >
                {"ALIC"}
                <LabelInfo
                  title={I18n.t(
                    "configurations.definitions.acc_loc_code"
                  )}
              />
              </span>
            } 
              labelCol={{lg: 7, xl: 7, xxl: 7}}
              wrapperCol={{lg: 17, xl: 17, xxl: 17}}
            >
               <Input
                value={ formData.acc_loc_code }
                onChange={ (e) => handleOnChange('acc_loc_code', e.target.value) }
                disabled={ isDisabled }
                size={size}
                className="textUpperCase"
              />
            </SideBySideFormItem>
          </Col>
          <Col { ...{...gridSize, lg: 8, xl: 8} }>
            <SideBySideFormItem label={`${I18n.t('general.contact')} 1`} 
            require={requireCheck}
            labelCol={{lg: 7, xl: 7, xxl: 7}}
            wrapperCol={{lg: 17, xl: 17, xxl: 17}
          }
            >
              <MobileInput
                handleInputChange={ (value, data) =>
                  handleOnChange('customer_phone_one', value, data)
                }
                value={ formData.customer_phone_one || '' }
                disabled={ !editableFields.includes('customer_phone_one') || isDisabled }
                size={size}
                inputStyle={size === 'small' ? { width: '100%' , height : 20 } : null}
              />
            </SideBySideFormItem>
          </Col>
          <Col { ...{...gridSize, lg: 8, xl: 8} }>
            <SideBySideFormItem label={`${I18n.t('general.contact')} 2`} 
             labelCol={{lg: 7, xl: 7, xxl: 7}}
             wrapperCol={{lg: 17, xl: 17, xxl: 17}}
            >
              <MobileInput
                handleInputChange={ (value, data) =>
                  handleOnChange('customer_phone_two', value, data)
                }
                value={ formData.customer_phone_two || '' }
                disabled={ !editableFields.includes('customer_phone_two') || isDisabled }
                size={size}
                inputStyle={size === 'small' ? { width: '100%', height : 20 } : null}
              />
            </SideBySideFormItem>
          </Col>
{
  showpredefined_loc&&
  <Col {...{ ...gridSize, lg: 24, xl: 24 }}>

         
  <div style={{ display: "flex", alignItems: "center" }}>
  <Checkbox
  style={{ marginRight: "8px" }}
  checked={formData.is_predefined_loc}
   onChange={(e) => handleOnChange("is_predefined_loc", e.target.checked)}
      >
   <b>Save As Predefined Location</b>
   </Checkbox>
     </div>
    </Col>
}
        
      </Row>

      }
      
    </Fragment>)
  }

  const renderAddressDetails = (isDisabled) => {
    const {isDeliveryRequired} = props;
    return (editableFields.includes('address') && window.google && map && (
      <Row className='consignee-details-form' >
        <Col xs={24}>
          <AddressForm
            autoComplete={editableFields.includes('address')}
            includePredefinedStops={false}
            requiredFields={ isDeliveryRequired ? null : [] }
            address={
              formData.cs_location && formData.cs_location.l_address
                ? formData.cs_location.l_address
                : {}
            }
            onChange={handleOnAddressChange}
            onConsigneeLocationChange={onConsigneeLocationChange}
            isAdmin
            disabled={!editableFields.includes('address') || isDisabled}
            gridSize={gridSize}
            size={size}
            FormItem={SideBySideFormItem}
          />
        </Col>
      </Row>
    ))
  }

  const renderForm = () => {
    const isDisabled = formData && formData.l_type === 'WH' ? true : false;
    const doesAddressExist = orderType === "transfer_params" ? _.get(formData, `cs_location`) : true;
    return (<Fragment>
      <AddressSearch onSelect={props.onAddressSelect} doesAddressExist={doesAddressExist} />
      { 
      requireSideBySide ? 
      <Row gutter={16}>
        <Col xs={12} className="padding5">
        <h4>Address Details</h4>
          {renderAddressDetails(isDisabled)}
        </Col>
        <Col xs={12} className="padding5" style={{ borderLeft: '1px solid #CCC'}}>
          <h4>Contact Details</h4>
          {renderContactForm(isDisabled)}
        </Col>
      </Row> :
      <Fragment>
        {renderAddressDetails(isDisabled)}
        <hr style={{ borderColor: "#ffefef4d", marginBottom: 10}}/>
        {renderContactForm(isDisabled)}
      </Fragment>
      }

        

    </Fragment>)
  }
  return <Fragment>{renderForm()}</Fragment>
}
export default ConsigneeDetailsForm

ConsigneeDetailsForm.propTypes = {
  formData: PropTypes.shape().isRequired,
  handleOnAddressChange: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  predefined_locations : PropTypes.string,
  isPickup: PropTypes.bool,
}

ConsigneeDetailsForm.defaultProps = {
  predefined_locations : "true",
  isPickup: false
}
