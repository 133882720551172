/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { Fragment, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Col,
  Row,
  Icon,
  Button,
  Card,
  Divider,
  Select,
  Modal,
  Form,
  Popconfirm,
  Collapse,
  Spin,
} from "../../common/UIComponents";
import { alertMessage, checkNegitive, isEmpty } from "../../common/Common";
import I18n from "../../common/I18n";
import Profile from "../common/Profile";
import InvoiceContact from "./InvoiceContact";
import AppConfig from "../../config/AppConfig";
import imagePlaceholder from "../../../assets/images/not_available.png";
import { checkServiceExistance, renderAlertMessage } from "../../helpers/common";
import AddressInfo from "../common/AddressInfo";
import EditIcon from "../common/EditIcon";
import BaseModal from "../BaseModal";
import { fetchUsers } from "../../api/UsersApi";
import { UpdateDriver, fetchBillingDetails, fetchInvoices } from "../../api/Billing";
import { WarehouseContext } from "../../context/WarehouseContext";
import EndUserTitle from "../common/EndUserTitle";
import { importDeclaration } from "@babel/types";
import BillingPdfDownload from "./BillingPdfDownload";
import BranchesIcon from "../common/BranchesIcon";

const Header = (props) => {
  const {
    invoice,
    isReadyOnly,
    levelOfServices,
    handleOnChange,
    currentLos,
    reGenerate,
    showAddOrdersModal,
    showAddOrders,
    isLocked,
    isAdjusted,
    showActions,
    getInvoices,
    showEmailModal,
    isSendingMail,
    isConsolidated,
    DriverSettlementApproved,
    consolidatedOrderNumbers,
    driversList
  } = props;
  const gridSize = invoice?.drivers_info?.length > 1 ? 6 : 8;
  // const driverGridSize = invoice?.drivers_info?.length > 1 ? 9 : 8;
  // const accDetailGridSize = invoice?.drivers_info?.length > 1 ? 6 : 8;
  // const customerDetailGridSize = invoice?.customers_details?.length > 1 ? 11 : 8;
  // const zoneDetailGridSize = invoice?.orders_info?.length > 1 ? 7 : 8;
  const driverGridSize = invoice?.drivers_info?.length > 1 ? 7 : 6;
  const accDetailGridSize = 6;
  const customerDetailGridSize = 12;
  const zoneDetailGridSize = 6;
  const isManualInvoice = invoice.order_group_type === "MANUAL";
  const [currentDriverId,setCurrentDriverId] = useState( invoice?.driver_details?.driver_id);
  const[showEditModal, setShowEditModal] = useState(false);
  const [drivers, setDrivers] = useState(driversList);
  const [inProgress, setInProgress] = useState(false);
  const[selectedDriver, setSelectedDriver ] = useState('');
  const[driverName, setDriverName] = useState(invoice?.driver_details?.name); 
  const[driverCode, setDriverCode] = useState(invoice?.driver_details?.code); 
  const[driverPhNo, setDriverPhNo] = useState(invoice?.driver_details?.contact);
  const sortedDriverInfo = _.sortBy((invoice?.drivers_info || []), "loc_order_sequence")
  const[driversInfo, setDriversInfo]  = useState(sortedDriverInfo);
  const warehouseFilter = useContext(WarehouseContext);
  const [warehouses, setWarehouses] = useState([]); 
  const { Panel } = Collapse; 
  const orderGroupType = invoice?.order_group_type;

  useEffect(() => {
    setWarehouses(warehouseFilter.warehouses);
  }, [
    warehouseFilter.selectedWarehouses,
    warehouseFilter.warehouses,
  ]);

  useEffect(() => {
    const driversData = invoice?.drivers_info || [];
    setDriversInfo(_.sortBy(driversData, "loc_order_sequence"))
  }, [
    invoice.drivers_info,
  ]);

  useEffect(() => {
    setDrivers(driversList)
  },[driversList])


  const currentWh =
    typeof warehouseFilter.selectedWarehouses === "string"
      ? _.find(warehouseFilter.warehouses, {
          id: warehouseFilter.selectedWarehouses,
        })
      : _.find(warehouseFilter.warehouses, {
          id: warehouseFilter.selectedWarehouses[0],
        });


  const handleDriverChnage = (value) => {
    setSelectedDriver(value);
  }

  const handleEdit = () => {
    setShowEditModal(true);
  };

  const handleSubmit = async () => {
    const order = invoice.order_info?.order_id;
    const orderIds = invoice.transportation_charges.map(
      (charge) => charge.order_id
    );
    const payload = {
      order_ids: orderIds,
      old_driver_id: currentDriverId,
      new_driver_id: selectedDriver,
      warehouse_id: currentWh ? currentWh.id : '',
    };

    try {
      const updateResult = await UpdateDriver(payload);
      if (updateResult.success) {
        alertMessage("Driver Updated Successfully", "success", 10);
        setShowEditModal(false);
        const billingResult = await fetchBillingDetails(order);
        if (billingResult.success) {
          const drivers_info = billingResult.invoice.drivers_info || [];
          const driverInfo = billingResult?.invoice.driver_details || {};
          const driverId = driverInfo?.driver_id;
          const driverName = driverInfo?.name;
          const driverCode = driverInfo?.code;
          const driverNo = driverInfo?.contact;
          setCurrentDriverId(driverId);
          setDriverName(driverName);
          setDriverCode(driverCode);
          setDriverPhNo(driverNo);
          setDriversInfo(_.sortBy(drivers_info, "loc_order_sequence"))
          getInvoices();
          setSelectedDriver('');
        }
        console.log("billingResult",billingResult)
      }
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

  const RenderModalFalseConsolidation = () => {
    return (
      <div>
        <Row>
          <h3 style={{ fontWeight: "bold" }}>
          Driver Settlement already approved for this order, New amount will not be affected!
          </h3>
        </Row>
        <Row>
          <h4
            style={{
              paddingTop: "18px",
              paddingLeft: "50px",
              fontWeight: "600",
            }}
          >
            Do you want to continue ?
          </h4>
        </Row>
      </div>
    );
  }

  const RenderModalForAdjustedOrders = () => {
    return (
      <div>
        <Row>
          <h3>
            Do you want to continue ?
          </h3>
</Row>
        <Row>
          <h4
            style={{
              paddingTop: "18px",
              paddingLeft: "50px",
            }}
          >
            Reprocessing this order will undo its adjustments and affect any linked adjusted orders, requiring further modifications
          </h4>
        </Row>
      </div>
    );
  }

  const RenderModalFalseDriverSettlement = () => {
    return (
      <div>
        <Row>
            <h4 style={{ fontWeight: "bold" }}>
            This is a consolidated order we are going to reprocess all the orders shown in below
            </h4>
          </Row>
          <Row>
            <div
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px",
                marginTop: "10px",
                padding: "10px",
              }}
            >
              
              <p style={{ maxHeight: 70, overflowY: "auto" }}>
                {consolidatedOrderNumbers.join(", ")}
              </p>
            </div>
          </Row>
          <Row>
            <h4
              style={{
                paddingTop: "18px",
                paddingLeft: "3px",
                fontWeight: "600",
                // fontSize: "19px",
              }}
            >
              Do you want to continue ?
            </h4>
          </Row>
      </div>
    );
  }

  const RenderModalTrueDriverSettlementConsolidation = () => {
    return (
      <div>
        <Row>
            <h4 style={{ fontWeight: "bold" }}>
            Driver Settlement already approved for this order, New amount will not be affected!
            </h4>
            <h4 style={{ fontWeight: "bold",paddingLeft:'56px' }}>
            This is a consolidated order we are going to reprocess all the orders shown in below
            </h4>
          </Row>
          <Row>
            <div
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px",
                marginTop: "10px",
                padding: "10px",
              }}
            >
              <p style={{ maxHeight: 70, overflowY: "auto" }}>
                {consolidatedOrderNumbers.join(", ")}
              </p>
            </div>
          </Row>
          <Row>
            <h4
              style={{
                paddingTop: "18px",
                paddingLeft: "3px",
                fontWeight: "600",
                // fontSize: "19px",
              }}
            >
              Do you want to continue ?
            </h4>
          </Row>
      </div>
    );
  }

  const handelCancel = () => {  
    setShowEditModal(false);  
  };

  const renderModalContent = () => {
    if (isAdjusted) {
      return RenderModalForAdjustedOrders();
    } else if (!isLocked && DriverSettlementApproved && !isConsolidated) {
      return RenderModalFalseConsolidation();
    } else if (!isLocked && !DriverSettlementApproved && isConsolidated) {
      return RenderModalFalseDriverSettlement();
    } else if (!isLocked && DriverSettlementApproved && isConsolidated) {
      return RenderModalTrueDriverSettlementConsolidation();
    }
    return null;
  };


  const renderEditModal = () => {
    return (
      <Modal
        title={"Change Driver"}
        onOk={handleSubmit}
        onCancel={handelCancel}
        bodyStyle={{ padding: 10 }}
        visible={showEditModal}
      >
        <div>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form>
                  <span className="textBold">{I18n.t('general.Driver')}:</span>
                  <Select
                    showSearch
                    value={selectedDriver ? selectedDriver : ""}
                    onChange={handleDriverChnage}
                    filterOption={(input, option) => {
                      if (option.props.children) {
                        return option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    }
                      
                    }
                    style={{ width: "80%", height: "20%", marginLeft:10 }}
                  >
                    <Select.Option key={"driver"} value={""}>
                      --select--
                    </Select.Option>
                    {drivers
                      .filter((driver) => driver.id !== currentDriverId)
                      .map((driver) => (
                        <Select.Option key={driver.id} value={driver.id}>
                          {driver.employee_code}
                        </Select.Option>
                      ))}
                  </Select>
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  };
  
  return (
    invoice && (
      <Fragment>
        {renderEditModal()}
        <Row style={{
          padding: "0 10px",
        }}>
          <Col xs={12}>
            <img
              src={props.logoUrl}
              alt="Fleetenable"
              style={{ maxWidth: "10rem", marginBottom: "1rem", height: 50 }}
            />
          </Col>
          <Col xs={12}>
            <h4 className="alignRight fontSize25 paddingRight20">
              {I18n.t("invoices.total")}:
              {props.isLoading ? (
                <>
                  <Icon type="loading" style={{ fontSize: 18,marginLeft: 4 }} spin />{" "}
                  <span style={{ fontSize: 18 }}>Fetching...</span>
                </>
              ) : (
                checkNegitive(invoice.total_amount)
              )}
            </h4>
          </Col>
        </Row>
        {false && (
          <Fragment>
            <Col sm={24} xs={24} md={12} lg={12}>
              <h4>
                {I18n.t("invoices.total")}:{" "}
                {checkNegitive(invoice.total_amount)}
              </h4>
            </Col>
            <Col sm={24} xs={24} md={12} lg={12} className="alignRight">
              {!isReadyOnly && (
                <Button type="link" size="small" onClick={() => this.addItem()}>
                  <Icon type="plus-circle" />
                  &nbsp;{I18n.t("invoices.add_charge")}
                </Button>
              )}
            </Col>
          </Fragment>
        )}
        <Card
          size="small"
          className="alignLeft"
          title={I18n.t("general.details")}
          style={{ boxShadow: "0 0 10px rgb(0 0 0 / 7%)" }}
          bodyStyle={{ textAlign: "left", padding: "5px 0px" }}
          extra={
            <Fragment>
              {showAddOrders !== false && showActions === true && orderGroupType !=="TEMPLATE" ? (
                <Button
                  type="primary"
                  size="small"
                  disabled={isLocked || isAdjusted}
                  onClick={showAddOrdersModal}
                  className="fontSize13 cursorPointer textBold marginRight5"
                  icon="plus"
                >
                  Add Orders
                </Button>
              ) : (
                <Fragment />
              )}
              {checkServiceExistance("UINLOS") &&
                (showActions === true || !props.isInvoiceLocked) && orderGroupType !=="TEMPLATE" &&(
                  <>
                    <Button
                      type="primary"
                      size="small"
                      disabled={isLocked}
                      className="fontSize13 cursorPointer textBold buttonTeal"
                      icon="form"
                      onClick={() => {
                        const modalContent = renderModalContent();
                        if (!modalContent) {
                          reGenerate();
                        } else {
                          Modal.confirm({
                            content: modalContent,
                            width: 520,
                            icon: (
                              <Icon
                                type="warning"
                                style={{ fontSize: isAdjusted ? "80px" : "40px" }}
                              />
                            ),
                            onOk: () => {
                              reGenerate();
                            },
                            okText: "Yes",
                            okType: "primary",
                            cancelText: "No",
                            cancelType: "danger",
                            onCancel: () => {},
                          });
                        }
                      }}
                    >
                      Regenerate
                    </Button>
                  </>
                )}
              <Button
                type="primary"
                size="small"
                icon="mail"
                onClick={showEmailModal}
                loading={isSendingMail}
                className="fontSize13 cursorPointer textBold marginLeft5"
                style={{
                  backgroundColor: "#1890ff",
                  borderColor: "#1890ff",
                  color: "#fff",
                }}
              >
                {I18n.t("general.send_item", {
                  item: I18n.t("invoices.billing"),
                })}
              </Button>
              {showActions === false && (
                <BillingPdfDownload
                  className={"buttonCoolBlue marginLeft10"}
                  buttonType="primary"
                  size="small"
                  label={I18n.t("general.print")}
                  isDisabled={props?.selectedInfo?.selectedKeys.length === 0}
                  handleSuccess={() => {}}
                  orderIds={
                    props.selectedInfo?.selectedKeys
                      ? [...props.selectedInfo.selectedKeys]
                      : []
                  }
                />
              )}
            </Fragment>
          }
        >
          {orderGroupType !== "TEMPLATE" && <Row>
            <Col md={accDetailGridSize} className="smallContent headingBox">
              <Card size="small" bodyStyle={{ height: 185, fontSize: 12 }}>
                <h3 className="main_heading primaryText fontSize16">
                  {I18n.t("account.details")}
                </h3>
                <span className="textBold">
                  <Fragment>{invoice.account_details.name}</Fragment>
                </span>
                <div>
                  <Col xs={1}>
                    <Icon type="environment" />
                  </Col>
                  <Col xs={23}>
                    <InvoiceContact
                      data={invoice.account_details}
                      // showIcon
                      showAddr
                      displayType="mixFormat"
                    />
                  </Col>
                </div>
              </Card>
            </Col>
            {isManualInvoice && (
              <Col md={gridSize} className="smallContent headingBox">
                <Card size="small" bodyStyle={{ height: 185, fontSize: 12 }}>
                  <h3 className="main_heading primaryText fontSize16">
                    Warehouse Details
                  </h3>
                  <h4 className="main_heading primaryText fontSize16">
                    {invoice.warehouse_details.name}
                  </h4>
                  <InvoiceContact data={invoice.warehouse_details} showIcon />
                </Card>
              </Col>
            )}
            {!isManualInvoice && (
              <Col
                md={customerDetailGridSize}
                className="smallContent headingBox"
              >
                <Card size="small" bodyStyle={{ height: 185, fontSize: 12 }}>
                  {/* <h3 className="main_heading primaryText fontSize16">
                    <EndUserTitle stringToAppend={I18n.t("general.details")} />
                  </h3> */}
                  {invoice?.pickup_address && (
                      <Col xs={12}>
                        <h3 className="main_heading primaryText fontSize16">
                            {I18n.t("general.pickup_destination")}
                        </h3>
          <Row>
            <Col xs={24}>
              <Profile
                address={invoice.pickup_address.address ? invoice.pickup_address.address : {}}
                profile={(({
                  name,
                  contact,
                  customer_phone_two,
                  email,
                  company_name,
                }) => ({
                  image: imagePlaceholder,
                  full_name: `${name}`,
                  mobile_number: `${contact} ${
                    !isEmpty(customer_phone_two) ? `,${customer_phone_two}` : ""
                  }`,
                  email,
                  company_name,
                }))(invoice.pickup_address)}
                displayType="line"
                showImage={false}
                withLables={false}
                inBilling={true}
                styleObj={{ fontSize: 13, fontWeight: "bold" }}
              />
            </Col>
            
            <Col xs={24} style={{ marginLeft: 5 }}>
              <AddressInfo
                address={invoice.pickup_address.address}
                showIcon={true}
              />
            </Col>
          </Row>
        </Col>
      )}

      {invoice?.delivery_address && (
        <Col xs={12}>
          <h3 className="main_heading primaryText fontSize16">
            {I18n.t("general.delivery_destination")}
          </h3>
                        <Row>
                          {/* {detail.location_type && (
                            <Col xs={24} className="textBold">
                              {detail.location_type
                                ? `${detail.location_type}: `
                                : ""}
                            </Col>
                          )} */}
                          <Col xs={24}>
                            <Profile
                              address={invoice.delivery_address.address ? invoice.delivery_address.address : {}}
                              profile={(({
                                name,
                                contact,
                                customer_phone_two,
                                email,
                                company_name,
                              }) => ({
                                image: imagePlaceholder,
                                full_name: `${name}`,
                                mobile_number: `${contact} ${
                                  !isEmpty(customer_phone_two)
                                    ? `,${customer_phone_two}`
                                    : ""
                                }`,
                                email,
                                company_name,
                              }))(invoice.delivery_address)}
                              displayType="line"
                              showImage={false}
                              withLables={false}
                              inBilling={true}
                              styleObj={{ fontSize: 13, fontWeight: "bold" }}
                            />
                          </Col>
                          <Col xs={24} style={{ marginLeft: 5 }}>
                            <AddressInfo
                              address={invoice.delivery_address.address}
                              showIcon={true}
                            />
                          </Col>
                        </Row>
                      </Col>
                  )}
                </Card>
              </Col>
            )}
            {false && !isEmpty(driversInfo) && driversInfo?.length && (
              <Col
                md={driverGridSize}
                className="smallContent paddingLeft5 headingBox"
              >
                <h3 className="main_heading">Driver Details</h3>
                <Row>
                  {driversInfo.map((rec) => (
                    <Col
                      md={24 / driversInfo.length}
                      className="smallContent paddingLeft5 headingBox"
                    >
                      {rec.type_of_location && (
                        <h4 className="main_heading textBold fontSize14">
                          {rec.type_of_location}
                        </h4>
                      )}
                      <h4 className="main_heading textBold fontSize13">
                        {rec.driver_name}
                        &nbsp;&nbsp;
                        {!["LH", "T"].includes(rec.type_of_order) &&
                          invoice.status ===
                            I18n.t("invoices.ready_for_billing") &&
                          showActions === true && (
                            <EditIcon handleClick={handleEdit} />
                          )}
                      </h4>
                      <h4 className="main_heading textBold fontSize15">
                        <Icon type="number" />
                        &nbsp;
                        {rec.driver_code}
                      </h4>
                      <InvoiceContact
                        data={rec}
                        showIcon
                        driverNo={rec.driver_number}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            )}
            {false && !isEmpty(invoice.driver_details) && (
              <Col
                md={gridSize}
                className="smallContent paddingLeft5 headingBox"
              >
                <h3 className="main_heading">Driver Details</h3>
                <h4 className="main_heading textBold fontSize14">
                  {driverName}
                  &nbsp;&nbsp;
                  {invoice.status === I18n.t("invoices.ready_for_billing") &&
                    showActions === true && (
                      <EditIcon handleClick={handleEdit} />
                    )}
                </h4>
                <h4 className="main_heading textBold fontSize15">
                  <Icon type="number" />
                  &nbsp;
                  {driverCode}
                </h4>
                <InvoiceContact
                  data={invoice.driver_details}
                  showIcon
                  driverNo={driverPhNo}
                />
              </Col>
            )}
            {!isManualInvoice && (
              <Col md={zoneDetailGridSize} className="smallContent headingBox">
                <Card size="small" bodyStyle={{ height: 185, fontSize: 12 }}>
                  <h3 className="main_heading primaryText fontSize16">
                    Zone Details
                  </h3>
                  <Row>
                    {invoice?.orders_info?.length ? (
                       invoice.orders_info.sort((a, b) => (a.segment_type === 'PICKUP' ? -1 : 1)).map((zone, index) => (
                        <Col key={index} md={24 / invoice.orders_info.length}>
                          <Row>
                            {zone.segment_type && (
                              <Col className="textBold">
                                {zone.segment_type
                                  ? `${zone.segment_type}: `
                                  : ""}
                              </Col>
                            )}
                            <Col>
                              <Row>
                                <Col md={24}>
                                  <div className="textBold">
                                    <span>
                                      {" "}
                                      <Icon type="account-book" />
                                      &nbsp;
                                      {I18n.t("zones.zonename")}&nbsp;:&nbsp;
                                    </span>
                                    <span>{zone.zone_name || "NA"}</span>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            <Col>
                              <Row>
                                <Col md={24}>
                                  <div className="textBold">
                                    <span>
                                      {" "}
                                      <Icon type="account-book" />
                                      &nbsp;
                                      {I18n.t("zones.type")}&nbsp;:&nbsp;
                                    </span>
                                    <span>{zone.zone_type || "NA"}</span>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            {zone.zone_type === "location" && (
                              <Col>
                                <Row>
                                  <Col md={24}>
                                    <div className="textBold">
                                      <span>
                                        {" "}
                                        <Icon type="environment" />
                                        &nbsp;
                                        {I18n.t("general.zipcode")}&nbsp;:&nbsp;
                                      </span>
                                      <span>{zone.zipcode || "NA"}</span>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            )}
                            {
                              <Col>
                                <Row>
                                  <Col md={24}>
                                    <div className="textBold">
                                      <span>
                                        <BranchesIcon />
                                        &nbsp;
                                        {I18n.t("general.distance")}
                                        &nbsp;:&nbsp;
                                      </span>
                                      <span>
                                        {" "}
                                        {!isEmpty(zone.distance)
                                          ? `${parseFloat(zone.distance)} ${
                                              AppConfig.distance_unit
                                            }`
                                          : "NA"}
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            }
                            <Col className="alignRight">
                              {/* { checkServiceExistance('UINLOS') && <div className="textCenter">
                            
                            <Button type="primary"  onClick={reGenerate}  className="fontSize13 cursorPointer textBold">Regenerate</Button>
                          </div>
                          } */}
                            </Col>
                          </Row>
                        </Col>
                      ))
                    ) : (
                      <Fragment />
                    )}
                  </Row>
                </Card>
              </Col>
            )}
          </Row>}
          {false && !isManualInvoice && (
            <Fragment>
              <Divider style={{ margin: "6px 0px 6px" }} />
              <Row>
                <Col
                  md={24}
                  className="smallContent"
                  style={{ padding: "3px 5px" }}
                >
                  {invoice?.orders_info?.length ? (
                    invoice.orders_info.map((zone, index) => (
                      <Row key={index}>
                        {zone.segment_type && (
                          <Col md={2} className="textBold">
                            {zone.segment_type ? `${zone.segment_type}: ` : ""}
                          </Col>
                        )}
                        <Col md={5}>
                          <Row>
                            <Col md={24}>
                              <div className="textBold">
                                <span>
                                  {" "}
                                  <Icon type="account-book" />
                                  &nbsp;
                                  {I18n.t("zones.zonename")}&nbsp;:&nbsp;
                                </span>
                                <span>{zone.zone_name || "NA"}</span>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={5}>
                          <Row>
                            <Col md={24}>
                              <div className="textBold">
                                <span>
                                  {" "}
                                  <Icon type="account-book" />
                                  &nbsp;
                                  {I18n.t("zones.type")}&nbsp;:&nbsp;
                                </span>
                                <span>{zone.zone_type || "NA"}</span>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        {zone.zone_type === "location" && (
                          <Col md={5}>
                            <Row>
                              <Col md={24}>
                                <div className="textBold">
                                  <span>
                                    {" "}
                                    <Icon type="environment" />
                                    &nbsp;
                                    {I18n.t("general.zipcode")}&nbsp;:&nbsp;
                                  </span>
                                  <span>{zone.zipcode || "NA"}</span>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        )}
                        {
                          <Col md={5}>
                            <Row>
                              <Col md={24}>
                                <div className="textBold">
                                  <span>
                                    <BranchesIcon />
                                    &nbsp;
                                    {I18n.t("general.distance")}&nbsp;:&nbsp;
                                  </span>
                                  <span>
                                    {" "}
                                    {!isEmpty(zone.distance)
                                      ? `${parseFloat(zone.distance)} ${
                                          AppConfig.distance_unit
                                        }`
                                      : "NA"}
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        }
                        <Col md={7} className="alignRight">
                          {/* { checkServiceExistance('UINLOS') && <div className="textCenter">
                          
                          <Button type="primary"  onClick={reGenerate}  className="fontSize13 cursorPointer textBold">Regenerate</Button>
                        </div>
                        } */}
                        </Col>
                      </Row>
                    ))
                  ) : (
                    <Fragment />
                  )}
                  {/* <Row>
                    <Col md={5}>
                      <Row>
                        <Col md={24}>
                          <div className="textBold">
                            <span>
                              {" "}
                              <Icon type="account-book" />
                              &nbsp;
                              {I18n.t("zones.zonename")}&nbsp;:&nbsp;
                            </span>
                            <span>{invoice.order_info.zone_name || "NA"}</span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={5}>
                      <Row>
                        <Col md={24}>
                          <div className="textBold">
                            <span>
                              {" "}
                              <Icon type="account-book" />
                              &nbsp;
                              {I18n.t("zones.type")}&nbsp;:&nbsp;
                            </span>
                            <span>{invoice.order_info.zone_type || "NA"}</span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    {invoice.order_info.zone_type === "location" && (
                      <Col md={5}>
                        <Row>
                          <Col md={24}>
                            <div className="textBold">
                              <span>
                                {" "}
                                <Icon type="environment" />
                                &nbsp;
                                {I18n.t("general.zipcode")}&nbsp;:&nbsp;
                              </span>
                              <span>{invoice.order_info.zipcode || "NA"}</span>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    )}
                    {
                      <Col md={5}>
                        <Row>
                          <Col md={24}>
                            <div className="textBold">
                              <span>
                                <BranchesIcon />
                                &nbsp;
                                {I18n.t("general.distance")}&nbsp;:&nbsp;
                              </span>
                              <span>
                                {" "}
                                {!isEmpty(invoice.order_info.distance)
                                  ? `${parseFloat(
                                      invoice.order_info.distance
                                    )} ${AppConfig.distance_unit}`
                                  : "NA"}
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    }
                    <Col md={9} className="alignRight">
                     
                    </Col>
                  </Row> */}
                </Col>
              </Row>
            </Fragment>
          )}
        </Card>
      </Fragment>
    )
  );
};

export default Header;

Header.propTypes = {
  invoice: PropTypes.shape().isRequired,
  isReadyOnly: PropTypes.bool.isRequired,
  levelOfServices: PropTypes.arrayOf(PropTypes.shape()),
  handleOnChange: PropTypes.func.isRequired,
  showAddOrdersModal : PropTypes.bool.isRequired,
};

Header.defaultProps = {
  levelOfServices: [],
  showAddOrdersModal : false,
};
