import React from "react";
import BaseList from "../BaseList";
import TypeOfOrder from "../orders/TypeOfOrder";
import { formatByTimeConfig } from "../../helpers/common";
import { momentTime } from "../../helpers/date_functions";
import moment from "moment/moment";
import expandIcon from '../../../assets/images/file-expand-icon.png';
import activeExpandIcon from '../../../assets/images/activeExpand.png';
import disabledExpandIcon from '../../../assets/images/disabledExpand.png';
import BaseModal from "../BaseModal";
import { Icon, Popover } from "antd";
import BillingActivitiesModal from "./BillingActivitiesModal";
import { difference } from "lodash";
import DisplayTime from "../common/DisplayTime";
import AppConfig from "../../config/AppConfig";

class BillingActivitiesList extends BaseList {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            currentActionType: '',
            differences: [],
        };

        this.columns = [
            {
                title: 'Order Number',
                dataIndex: 'customer_order_number',
                key: 'customer_order_number',
            },
            {
                title: 'Order Type',
                dataIndex: 'type_of_order',
                key: 'type_of_order',
                render: (data, record) => (
                    <div>
                        <TypeOfOrder
                            order={record}
                            orderTypeKey="type_of_order"
                            relatedOrderKey="related_order"
                            placement="topLeft"
                            showBadge={true}
                        />
                    </div>
                )
            },
            {
                title: 'Account Code',
                dataIndex: 'account_code',
                key: 'account_code',
            },
            {
                title: 'Level Of Service',
                dataIndex: 'los_code',
                key: 'los_code',
            },
            {
                title: 'Billing Amount',
                dataIndex: 'billing_amount',
                key: 'billing_amount',
                render: (value) => {
                    // Check if the value is null, undefined, or not a valid number
                    const numericValue = parseFloat(value);
            
                    // If the value is a valid number (including 0), format it with a dollar sign
                    if (!isNaN(numericValue)) {
                        return `$${numericValue.toFixed(2)}`;
                    }
            
                    // If the value is invalid or falsy, return 'N/A'
                    return 'N/A';
                },
            },                            
            {
                title: 'Activity By',
                dataIndex: 'activity_by',
                key: 'activity_by',
            },
            {
                title: 'Activity At',
                key: 'activity_at',
                dataIndex: "activity_at",
                render: (data) =>
                  data
                  ? 
                  <DisplayTime
                  dateTimeString={data}
                  timeFormat={AppConfig.dateTimeFormat}
                  displayWithDate={true}
                  isUTC={false}
                  />
                    : "",
                sorter: (a, b) => new Date(a.created_on) - new Date(b.created_on),
                width: 200,
            },
            {
                title: 'Description',
                dataIndex: 'action_type',
                key: 'action_type',
            },
            {
                title: 'Detail View',
                key: 'detail_view',
                render: (text, record) => {
                    if (record?.differences && record?.differences.length > 0 && !isEmpty(record?.activity_type)) {
                        return (
                            <img
                                src={activeExpandIcon}
                                style={{ marginLeft: "10px", cursor: "pointer", width: "20px", height: "20px" }}
                                alt="Detail View"
                                onClick={() => this.handleExpandIconClick(record?.activity_type, record?.differences)}
                            />
                        );
                    } else {
                        return (
                            <span
                            style={{ marginLeft: "10px" }}
                            >N/A</span>
                        );
                    }
                },
            }

        ];
    }

    handleExpandIconClick = (actionType, differences) => {
        this.setState({
            isModalVisible: true,
            currentActionType: actionType,
            differences: differences
        });
    };

    handleModalClose = () => {
        this.setState({
            isModalVisible: false,
            currentActionType: '',
            differences: []
        });
    };

    render() {
        return (
            <div>
                {super.render()}
                <BillingActivitiesModal
                    visible={this.state.isModalVisible}
                    onCancel={this.handleModalClose}
                    currentActionType={this.state.currentActionType}
                    differences={this.state.differences}
                />
            </div>
        );
    }
}

export default BillingActivitiesList;
