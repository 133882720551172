/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/sort-comp */
import React, { Component, Fragment, useContext } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { Col, Row, Icon, Spin, Button, Popconfirm, Modal, Tooltip, FormItem } from '../../common/UIComponents'
import {
  isEmpty,
  checkNegitive,
  findTotal,
  alertMessage,
  currencyConversion,
  doFormate,
  findNumberTotal,
} from '../../common/Common'
import I18n from '../../common/I18n'
import { fetchInvoiceDetails, removeOrderFromInvoice } from '../../api/BillingScreenAPI'
import AppConfig from '../../config/AppConfig'
import { fetchOrganizationDetails } from '../../api/Organisations'

import {
  invoiceHeading,
  mainHeading,
  shipperHeading,
  invoiceItem,
  totalItems,
  marginTop40,
  marginTop20,
  marginBottom10,
  textBold,
  marginTop10,
  fontSize10,
  paddingTop20,
  smallContent,
  commentSection,
  paddingLeft15,
  borderLine,
  heading,
  alignCenter,
  alignRight,
  padding5,
  marginLeft10,
  floatLeft,
  paddingTop5,
  fontSize11,
  borderCollapseInitial,
  paddingLeft5
} from '../../helpers/invoice_styles'
import AddressInfo from '../common/AddressInfo'
import logo from '../../../assets/images/logo-thumbnail.png'
import userStore from '../../stores/UserStore'
import { momentTime } from '../../helpers/date_functions'
import { findIfInvoicePartiallyPaid, findSpanValue } from '../../helpers/billing'
import UnlockInvoice from './UnlockInvoice'
import DeleteIcon from '../common/DeleteIcon'
import { OrderConfigContext } from '../../context/OrderConfigContext'
import { convertToKilograms, convertToUnitsWithBothLabels, formatUSPhoneNumber, getFormattedWeight, getWeightUnits, getWeightWithBothUnits, renderAlertMessage } from '../../helpers/common'
import { UserContext } from '../../context/UserContext'
import { OrgContext } from '../../context/OrgContext'
import DisplayTime from '../common/DisplayTime';
import { AccountUnitsContext } from '../../context/AccountUnitsContext';
import { withRouter } from 'react-router-dom';

class Invoice extends Component {
  static contextType = OrderConfigContext;
  constructor(props) {
    super(props)
    this.state = {
      accountId: this.props.accountId ? this.props.accountId : {},
      invoiceNo: this.props.invoiceNo ? this.props.invoiceNo : '',
      account_invoice_id: this.props.account_invoice_id ? this.props.account_invoice_id : '',
      account: this.props.account ? this.props.account : {},
      isFileGenerated: this.props.isFileGenerated ? this.props.isFileGenerated : false,
      organization: {},
      invoiceDetails: [],
      invoiceDate: null,
      invoice: !isEmpty(this.props.currentInvoice)
        ? this.props.currentInvoice
        : {
            account_details: {},
            warehouse_details: {},
            driver_details: {},
            order_info: {},
          },
      logo,
      chargeTypes: [
        'transportation_charges',
        'accessorial_charges',
        'adjustment_charges',
      ],
      showHeading:
        this.props.showHeading === false ? this.props.showHeading : true,
      showOtherDetails:
        this.props.showOtherDetails === false
          ? this.props.showOtherDetails
          : true,
      inProgress: false,
      isCustomizedTransportationLabel: false,
      invoiceLinesSummary: [],
      invoice_logo_address: {},
      billing_company_details: {},
      onCancelPress : true,
      totalTemplateGroupLines: []
    }
  }

  componentDidMount() {
    this.getOrgLogo()
    this.setInvoiceDetails()
    this.getInvoiceDetails()
  }


  componentWillUnmount() {
    const invoiceNo = this.state.invoiceNo
    const onCancelPress = this.state.onCancelPress
    if (this.props.onUnmount) {
      this.props.onUnmount(invoiceNo,onCancelPress);
    }
  }

  setInvoiceDetails = () => {
    if (this.state.callInvoceDetails === true) {
      this.getBillingDetails()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.invoiceDetails !== this.state.invoiceDetails ) {
      this.setState({
        invoiceLinesSummary: this.calculateInvoiceLinesSummary(this.state.invoiceDetails)
      })
    }
  }

  getInvoiceDetails = () => {
    this.setState({ inProgress: true })
    const isForQuote = false
    const data = {
      invoice_number: this.state.invoiceNo,
      account_id: this.state.accountId,
      organization_id: userStore.getStateValue('selectedOrg'),
      account_invoice_id:  this.state.account_invoice_id,
    }
    fetchInvoiceDetails(data).then((result) => {
      if (result.success) {
        const invoiceDetails = result.invoice || []
        const smpDetails = result.smp_details || []
        const billingCompanyDetails = result.billing_company_details || {};
        const account =
          invoiceDetails.length > 0 && invoiceDetails[0].account_details
            ? invoiceDetails[0].account_details
            : {}
        const invoiceDate =
          invoiceDetails.length > 0 && invoiceDetails[0].billing_date
            ? moment(invoiceDetails[0].billing_date).format('DD MMM, YYYY')
            : {}
        const isLocked =  result?.is_locked && result.is_locked == "true";
        const vendorNo = result.vendor_no ? result.vendor_no : "";
        const CustomedOrNot = result?.customize_transportation_line_name ? result.customize_transportation_line_name : "false";
        if(invoiceDetails){
          invoiceDetails.is_locked = isLocked;
          invoiceDetails.vendor_no = vendorNo;
          invoiceDetails.is_paid = result?.is_paid && result.is_paid == "true";
          invoiceDetails.ar_status = result?.ar_status ? result.ar_status : 'OPEN';
          result?.is_locked && result.is_locked == "true";
        }
        this.setState({
          invoiceDetails,
          smpDetails,
          inProgress: false,
          account,
          invoiceDate,
          invoiceLinesSummary : this.calculateInvoiceLinesSummary(invoiceDetails),
          isLocked,
          invoice_logo_address: result.invoice_logo_address || {},
          billing_company_details: result.billing_company_details || {},
          isCustomizedTransportationLabel: CustomedOrNot,

        })
        this.props.setInvoiceDetails({ invoiceDetails: invoiceDetails[0], isLocked, totalDetails: invoiceDetails, vendorNo, billingCompanyDetails })
      } else {
        renderAlertMessage(result.errors)
        this.setState({ inProgress: false })
      }
    })
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.setData === true) {
      this.setInvoiceDetails()
    }
  }

  removeOrder = (customer_order_id, isRequired) => {
    const invoiceDetails = this.state?.invoiceDetails
      ? this.state.invoiceDetails
      : [];
    this.setState({ inProgress: true });
  
    const data = {
      customer_order_id: customer_order_id,
      account_invoice_id: this.state.account_invoice_id,
      create_new_invoice_flag: isRequired,
    };
  
    removeOrderFromInvoice(data).then((result) => {
      if (result.success) {
        // handle success response
        const message = result.message ? result.message : I18n.t('messages.deleted')
        alertMessage(message);
        // const updatedInvoiceDetails = this.state.invoiceDetails.filter((item) => item.customer_order_id !== customer_order_id);
        // this.setState({ invoiceDetails: updatedInvoiceDetails });
        this.getInvoiceDetails();
        this.setState({ inProgress: false });
      } else {
        // handle error response
        alertMessage(result.errors ? result.errors[0] : "Failed to remove order from invoice", 'error', 10);
        //  alertMessage(result.errors[0], 'error', 10)
        this.setState({ inProgress: false });
      }
    });
  };


  getOrgLogo = () => {
    const orgId = userStore.getStateValue('selectedOrg')
    fetchOrganizationDetails(orgId).then((result) => {
      if (result.success) {
        const organization = result.organization || {};
        this.setState({
          organization,
        })
        organization.logo =
          !isEmpty(organization.logo) && organization.logo.url
            ? organization.logo.url
            : ''

        if (organization.logo) {
          this.setState({
            logo: organization.logo,
          })
        } else if (!isEmpty(orgId)) {
          const urlToFile = `${AppConfig.baseUrl}/org_logos/${orgId}/org_logo.png`
          fetch(urlToFile).then((response) => {
            if (response.status === 200) {
              this.setState({
                logo: urlToFile,
              })
            }
          })
        }
      }
    })
  }

  renderTermsNConditions = () => (
    <div style={{ ...marginTop40, ...borderLine }}>
      <Row>
        <Col xs={24} className="heading">
          {I18n.t('invoices.termsNconditions')}
        </Col>
      </Row>
      {AppConfig.termsList.map((term, index) => (
        <p className="paddingLeft5">
          {index + 1}. {term}
        </p>
      ))}
    </div>
  )

  renderComments = () => (
    <div style={{ ...marginTop40, ...borderLine }}>
      <div style={{ ...heading }}>{I18n.t('invoices.comments')}</div>
      <div style={{ ...commentSection }} />
    </div>
  )

  renderWHDetails = () => {
    const whDetails = this.state.invoice.warehouse_details || {};
    return (
      <table width="100%">
        <tbody>
          <tr>
            <td>
              <img src={this.state.logo} alt="" style={{ maxWidth: '10rem' }} />
              <small></small>
            </td>
          </tr>
          <tr>
            <td width="65%">
              <h2 style={{ ...mainHeading, ...textBold }}>{whDetails.name}</h2>
            </td>
            <td width="35%">
              <h2 style={{ ...alignCenter, ...invoiceHeading, ...textBold }}>
                INVOICE
              </h2>
            </td>
          </tr>
          <tr>
            <td width="65%" style={{ ...smallContent }}>
              <table width="100%" style={{ ...marginTop10 }} cellpadding="0px">
                <tbody>
                  <tr>
                    <td style={{ ...smallContent }}>
                      <AddressInfo
                        address={(whDetails && whDetails.address) || {}}
                        showIcon
                        displayType="line"
                      />
                    </td>
                  </tr>
                  {!isEmpty(whDetails.email) && (
                    <tr>
                      <td>
                        <Icon type="mail" /> &nbsp; {whDetails.email}
                      </td>
                    </tr>
                  )}
                  {!isEmpty(whDetails.contact) && (
                    <tr>
                      <td>
                        <Icon type="phone" /> &nbsp;{' '}
                        {formatUSPhoneNumber(whDetails.contact)}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </td>
            <td width="35%" style={{ ...smallContent }}>
              <table cellpadding="2px" cellspacing="0px" width="100%">
                <tbody>
                  <tr>
                    <td width="46%">{I18n.t('order.order_no')}</td>
                    <td width="4%">:</td>
                    <td width="50%">#{this.state.invoice.order_number} </td>
                  </tr>
                  <tr>
                    <td>{I18n.t('general.date')}</td>
                    <td>:</td>
                    <td>
                      {moment(this.state.invoice.date).format('MMM Do YYYY')}{' '}
                    </td>
                  </tr>
                   
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  calculateInvoiceLinesSummary = (invoiceDetails) => {
    const invoiceLinesTotals = invoiceDetails.reduce((acc, curr) => {
      const { billing_info: { accessorial_charges: { invoice_lines } } } = curr;
      return invoice_lines.reduce((acc, curr) => {
        const { item_name, item_value } = curr;
        const sum = acc[ item_name ] ? acc[ item_name ] + Number(item_value) : Number(item_value);
        return { ...acc, [ item_name ]: sum };
      }, acc);
    }, {});
    return invoiceLinesTotals;
  }

  renderAccountDetails = () => {
    const userContext = useContext(UserContext);
    const invoice_date_type = !_.isEmpty(userContext.orgRules.org_billing_rules?.invoice_date_type) ? userContext.orgRules.org_billing_rules?.invoice_date_type : "";
    const invoiceDetails = this.state?.invoiceDetails ? this.state.invoiceDetails : [];
    const accountDetails = this.state.account || {}
    const orgDetails = this.state.organization || {}
    const { isLocked, invoice_logo_address, billing_company_details , isFileGenerated} = this.state;
    const { is_paid, ar_status = {} } = invoiceDetails;
    const isClosed = ar_status === 'CLOSE'
    const totalAmount = findTotal(invoiceDetails, 'total_amount', null, true);
    const balanceAmount = findTotal(invoiceDetails, 'balance_amount', null, true);
    const vendorNo = invoiceDetails.vendor_no ? invoiceDetails.vendor_no : "";
    const billingCompanyAddress =
      billing_company_details?.address &&
      !_.isEmpty(billing_company_details.address)
        ? billing_company_details.address
        : null;
    const billingAddress = invoice_logo_address || {}
    const pobox_address_obj = _.pick(billingCompanyAddress, ['city', 'state', 'zipcode']);
    const pobox_values = !_.isEmpty(pobox_address_obj) ? (_.compact(Object.values(pobox_address_obj))).join(',') : '';
    return (
      <table width="100%">
        <tbody>
          <tr>
            {
              billing_company_details?.name || billing_company_details?.logo?.url ?
              <td>
              <img src={billing_company_details?.logo?.url} alt="" style={{ maxWidth: '10rem' }} />
              <small>
              {
                !billing_company_details?.logo?.url && billing_company_details?.name && <div className='textBold'>{billing_company_details.name}</div>
              }
              {
                false && billingCompanyAddress &&
                <AddressInfo
                  // address={
                  //   (orgDetails && orgDetails.address) || {}
                  // }
                  address={billingCompanyAddress || {}}
                  showIcon
                  displayType="line"
                />
              }
              <div className='textBold'>{I18n.t('general.po_box_no')}: &nbsp;{billing_company_details?.po_box_no ? `${billing_company_details.po_box_no}${pobox_values? `, ${pobox_values}` : ''}` : 'NA'}</div>
              </small>
            </td>
            :
            <td>
            <img src={this.state.logo} alt="" style={{ maxWidth: '10rem' }} />
            <small>
              <AddressInfo
                // address={
                //   (orgDetails && orgDetails.address) || {}
                // }
                address={billingAddress || {}}
                showIcon
                displayType="line"
              />
            </small>
          </td>
            }
           
            <td align='right'>
            {
              isClosed && this.props.showActions? <Button
              type='primary'
              icon="check-circle" 
              className={'buttonGreen'}
              size={'small'}
            >
              {I18n.t('general.closed')}
            </Button>
            :
            !is_paid && isLocked && this.props.showActions ?
            <Tooltip title={I18n.t("general.locked")}>
             <UnlockInvoice
             isFileGenerated={isFileGenerated}
            buttonType="primary"
            isDisabled={!isLocked || isFileGenerated}
            label={I18n.t('invoices.unlock')}
            invoiceIds={this.state.account_invoice_id}
            handleUnmount={this.props.onUnmount}
            selectedInfo={!_.isEmpty(this.props.selectedInfo) ? this.props.selectedInfo : this.props.invoiceNo}
            indexList={true}
            size="small"
            handleOnModalClose={this.props.handleOnModalClose}
          />
          </Tooltip>
          :
          <Fragment />
          }
            </td>
          </tr>
          <tr>
            <td width="54%" style={{ ...smallContent }}>
              <table width="100%" style={{ ...marginTop10 }} cellpadding="0px">
                <tbody>
                  <tr>
                    <td style={{ ...smallContent }}>
                       <h3 style={{ ...mainHeading, ...textBold }}>{accountDetails.name}({accountDetails.code})</h3>
                       <h4 style={{ ...shipperHeading, ...textBold }}>{I18n.t("invoices.billed_to")}</h4>
                      {this.billingAddress()}
                    </td>
                  </tr>
                  {!isEmpty(accountDetails.email) && (
                    <tr>
                      <td style={{ ...smallContent }}>
                        <Icon type="mail" /> &nbsp; {accountDetails.email}
                      </td>
                    </tr>
                  )}
                  {!isEmpty(accountDetails.contact) && (
                    <tr>
                      <td style={{ ...smallContent }}>
                        <Icon type="phone" /> &nbsp;{' '}
                        {formatUSPhoneNumber(accountDetails.contact)}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </td>
            <td width="46%" style={{ ...smallContent }}>
              <h2 style={{ ...alignCenter, ...invoiceHeading, ...textBold, ...marginBottom10 }}>
                INVOICE
              </h2>
              <table cellpadding="2px" cellspacing="0px" width="100%">
                <tbody>
                  <tr>
                    <td width="38%">{I18n.t('invoices.invoice_date')}</td>
                    <td>:</td>
                    <td width="60%">
                      {
                        <DisplayTime
                          dateTimeString={
                            invoice_date_type == "invoice_approval_date" && this.props.status == "APPROVED" 
                              ? this.props.invoiceApprovalDate || this.props.approvalDate
                              : this.props.approvalDate
                          }
                          timeFormat={ AppConfig.dateFormat }
                          displayWithDate={ true }
                          isUTC={ true }
                        />
                      }
                    </td>
                  </tr>
                  { vendorNo && (<tr>
                    <td width="38%">{I18n.t('general.vendor_no')}</td>
                    <td>:</td>
                    <td width="60%">
                      {vendorNo}{' '}
                    </td>
                  </tr>)}
                   <tr>
                    <td width="38%">{I18n.t('invoices.number')}</td>
                    <td>:</td>
                    <td width="60%" >
                      {this.state.invoiceNo}
                    </td>
                  </tr>
                  <tr>
                    <td>{I18n.t('invoices.total_amount')}</td>
                    <td>:</td>
                    <td>
                      {totalAmount}
                    </td>
                  </tr>
                  <tr>
                    <td>{I18n.t('invoices.balance_invoice_amount')}</td>
                    <td>:</td>
                    <td>
                      {balanceAmount}
                    </td>
                  </tr>
                  
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  renderZoneDetails = (order, orderRef) => {
    const data = order.billing_info || {};
    const { orderConfig, getOrderStatus } = this.context;
    const userContextData = useContext(UserContext);
    const accountWeightUnits = this.props.accountUnitContext;
    return (
      <table width="100%">
        <tbody>
          <tr>
             <td>
              {I18n.t('invoices.completion_date')}:&nbsp;
              {order.billing_date ? momentTime(AppConfig.dateFormat, order.billing_date, order.time_zone_id) : 'NA'}
            </td>
             <td>
               {I18n.t('order.type')}:&nbsp;
               {getOrderStatus(order.customer_order_type)}
            </td>
            <td>
              {I18n.t('zones.zonename')}:&nbsp;
              {data.zone_name || 'NA'}
            </td>
            <td style={{ ...alignRight }}>
              {data.zone_type !== 'location' ? (
                <Fragment>
                  {I18n.t('general.zipcode')}:&nbsp;
                  {data.zipcode || 'NA'}
                </Fragment>
              ) : (
                <Fragment>
                  {I18n.t('general.distance')}:&nbsp;
                  {!isEmpty(this.state.invoice.order_info.distance)
                    ? `${parseFloat(
                        this.state.invoice.order_info.distance,
                      ).toFixed(2)} ${AppConfig.distance_unit}`
                    : 'NA'}
                </Fragment>
              )}
            </td>
          </tr>
          <tr>
          <td>
              {I18n.t('general.weight')}:&nbsp;
              { accountWeightUnits[ this.state.account.code ] === 'kgs'
                ? `${Number(order.weight_in_kgs || 0).toFixed(
                  Number(_.get(userContextData, "orgRules.org_billing_rules.round_off_decimals", "2"))
                )} kgs`
                : `${Number(order.weight || 0).toFixed(
                  Number(_.get(userContextData, "orgRules.org_billing_rules.round_off_decimals", "2"))
                )} lbs`
              }        </td>
             <td>
               {"Skids/Pallets"}:&nbsp;
               {order.pallets || 'NA'}
            </td>
            <td>
               {"Balance Amount"}:&nbsp;
               {order.balance_amount || 'NA'}
            </td>
            {!isEmpty(orderRef) && !isEmpty(orderRef.mawb) && (<td>
                <span>{I18n.t("general.mawb")}:&nbsp;</span>
                <span>{doFormate(orderRef.mawb)}</span>
            </td>)}
            <Fragment>
            {!isEmpty(orderRef) && !isEmpty(orderRef.hawb) && (<td>
              <span>{I18n.t("general.hawb")}:&nbsp;</span>
              <span>{doFormate(orderRef.hawb)}</span>
            </td>)}
            </Fragment>
          </tr>
        </tbody>
      </table>
    )
  }

  billingAddress=()=>{
    const accountDetails = this.state.account || {};
    const { address, billing_address } = accountDetails;
    var accountAddress={};
    if(!isEmpty(billing_address)){
      accountAddress = billing_address;
    }
    else if(!isEmpty(address)){
      accountAddress = address;
    }
    else{
      accountAddress='NA';
    }
    return (
      <AddressInfo
        address={accountAddress || {}}
        showIcon
        displayType="line"
      />
    )
  }

  renderShipperDetails = () => {
    const data = this.state.invoice.account_details || {}
    return this.state.invoice.order_group_type !== 'MANUAL' ? (
      <table width="100%" style={{ ...marginTop10 }} cellpadding="0px">
        <tbody>
          <tr>
            <td width="65%">
              <table width="100%">
                <tbody>
                  <tr>
                    <td style={{ ...shipperHeading, ...textBold }}>
                      <h3>{I18n.t('invoices.billed_to')}</h3>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ ...smallContent }}>
                      <h4>{this.state.invoice.account_details.name}</h4>
                      {this.billingAddress()}
                    </td>
                  </tr>
                  {!isEmpty(data.email) && (
                    <tr>
                      <td>
                        <Icon type="mail" /> &nbsp; {data.email}
                      </td>
                    </tr>
                  )}
                  {!isEmpty(data.contact) && (
                    <tr>
                      <td>
                        <Icon type="phone" /> &nbsp;{' '}
                        {formatUSPhoneNumber(data.contact)}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </td>
            <td width="35%">
              <table>
                <tbody>
                  {/* <tr>
                  <td>{I18n.t('order.weight')}</td><td>:</td><td>{this.state.invoice.order_info.order_weight ? `${this.state.invoice.order_info.order_weight} ${AppConfig.weight_unit}` : 'NA'}</td>
                </tr> */}
                  <tr>
                    <td>{I18n.t('general.Driver')}</td>
                    <td>:</td>
                    <td>
                      {this.state.invoice.driver_details
                        ? this.state.invoice.driver_details.code ||
                          this.state.invoice.driver_details.name ||
                          'NA'
                        : 'NA'}
                    </td>
                  </tr>
                  <tr>
                    <td>{I18n.t('zones.zonename')}</td>
                    <td>:</td>
                    <td>{this.state.invoice.order_info.zone_name || 'NA'}</td>
                  </tr>
                  <tr>
                    <td>{I18n.t('zones.type')}</td>
                    <td>:</td>
                    <td>{this.state.invoice.order_info.zone_type || 'NA'}</td>
                  </tr>
                  {this.state.invoice.order_info.zone_type === 'location' && (
                    <tr>
                      <td>{I18n.t('general.zipcode')}</td>
                      <td>:</td>
                      <td>{this.state.invoice.order_info.zipcode || 'NA'} </td>
                    </tr>
                  )}
                  {this.state.invoice.order_info.zone_type === 'distance' && (
                    <tr>
                      <td>{I18n.t('general.distance')}</td>
                      <td>:</td>
                      <td>
                        {!isEmpty(this.state.invoice.order_info.distance)
                          ? `${parseFloat(
                              this.state.invoice.order_info.distance,
                            ).toFixed(2)} ${AppConfig.distance_unit}`
                          : 'NA'}{' '}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>{I18n.t('los.label')}</td>
                    <td>:</td>
                    <td>
                      {this.state.invoice.order_info.level_of_service || 'NA'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    ) : (
      <table width="100%" style={{ ...marginTop10 }} cellpadding="0px">
        <tbody>
          <tr>
            <td width="65%">
              <table width="100%">
                <tbody>
                  <tr>
                    <td style={{ ...shipperHeading, ...textBold }}>
                      <h3>{I18n.t('invoices.billed_to')}</h3>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ ...smallContent }}>
                      <h4>{this.state.invoice.account_details.name}</h4>
                      {this.billingAddress()}
                    </td>
                  </tr>
                  {!isEmpty(data.email) && (
                    <tr>
                      <td>
                        <Icon type="mail" /> &nbsp; {data.email}
                      </td>
                    </tr>
                  )}
                  {!isEmpty(data.contact) && (
                    <tr>
                      <td>
                        <Icon type="phone" /> &nbsp;{' '}
                        {formatUSPhoneNumber(data.contact)}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </td>
            <td width="35%" />
          </tr>
        </tbody>
      </table>
    )
  }
   renderReference =(order) => {
    return (
      <table width="100%">
        <tbody>
          <tr>
             <td>
              <span>{I18n.t("general.mawb")}&nbsp;&nbsp;:&nbsp;&nbsp;</span>
              <span>{doFormate(order.mawb)}</span>
             </td>
             <td>
              <span>{I18n.t("general.hawb")}&nbsp;&nbsp;:&nbsp;&nbsp;</span>
              <span>{doFormate(order.hawb)}</span>
             </td>
              <td />
              <td />
            </tr>
        </tbody>
      </table>
    );
  }

  renderCombinedAdjustmentCharges = (data) => {
    const combinedAdjustmentCharges = data.reduce((acc, item) => {
      item.billing_info.adjustments_charges.invoice_lines.forEach(line => {
        if (acc[line.item_name]) {
          acc[line.item_name] += parseFloat(line.item_value);
        } else {
          acc[line.item_name] = parseFloat(line.item_value);
        }
      });
      return acc;
    }, {});
    return Object.entries(combinedAdjustmentCharges).map(([itemName, itemValue], index) => (
      <tr key={index}>
        <td style={{ ...invoiceItem }}>
          {itemName}
        </td>
        <td style={{ ...invoiceItem, ...alignRight }}>
          {checkNegitive(itemValue)}
        </td>
      </tr>
    ));
  };

  getTemplateGroup = (chargeLines) => {
    const result = chargeLines.reduce((acc, item) => {
      const existing = acc.find((i) => i.item_name === item.item_name);
      if (existing) {
        existing.item_total_value += parseFloat(item.item_value);
      } else {
        acc.push({
          item_name: item.item_name,
          item_total_value: parseFloat(item.item_value),
        });
      }
      return acc;
    }, []);
    return result;
  };

  renderDetails = () => {
    const userContextData = useContext(UserContext);
    let totalTransportCharges = 0.0
    let totalFuelSurchargesCharges = 0.0
    let totalAccessorialCharges = 0.0
    let totalAdjustmentsCharges = 0.0
    const invoiceDetails = this.state?.invoiceDetails ? this.state.invoiceDetails : [];
    const totalAmount = findTotal(invoiceDetails, 'total_amount', null, true);
    const smpDetails = this.state?.smpDetails ? this.state.smpDetails : [];
    const { isLocked } = this.state;
    const renderAdjForTable = this.renderCombinedAdjustmentCharges(this.state.invoiceDetails);
    let allTransportationChargesLines = [];
    let groupedTemplatedLines = []
    const orderGroupInvoices = this.state.invoiceDetails.filter(rec => rec.order_group_type === "ORDER")
    const hasOrderGroupedInvoice = orderGroupInvoices?.length > 0 ? true : false;
    const groupAndSumTransportCharges = (invoiceDetails) => {
      const allTransportLines = invoiceDetails.flatMap(order => {
        const lines = order.billing_info.transportation_charges?.invoice_lines || [];
        // Flatten nested arrays if they exist
        return Array.isArray(lines[0]) ? lines.flat() : lines;
      });
      // const groupedCharges = _.groupBy(allTransportLines, 'transportation_charge_name');

      // Group by transportation_charge_name, but use "Transportation Charges" if the name is missing
      const groupedCharges = _.groupBy(allTransportLines, line => {
        if (line.transportation_charge_name) {
          return line.transportation_charge_name;
        } else if (line.added_from === "MANUAL") {
          return 'Manual Transportation Charges';
        } else {
          return 'Transportation Charges';
        }
      });
      return Object.entries(groupedCharges).map(([name, lines]) => ({
        name,
        total: lines.reduce((sum, line) => sum + (parseFloat(line.item_value) || 0), 0)
      }));
    };
    const groupedTransportChargesArray = groupAndSumTransportCharges(this.state.invoiceDetails);
    const renderCustomTransportationSummary = () => (
      <Fragment>
        <tr>
          <td width="62.5%" style={{ ...invoiceItem }}>
            {I18n.t("invoices.invoice_types.transportation_charges")} {" "}
            charges
          </td>
          <td width="37.5%" style={{ ...invoiceItem, ...alignRight }}>
            {checkNegitive(totalTransportCharges)}
          </td>
        </tr>
        <tr>
          <td style={{ ...invoiceItem }}>
            {I18n.t("invoices.invoice_types.fuel_surcharges")}
          </td>
          <td style={{ ...invoiceItem, ...alignRight }}>
            {checkNegitive(totalFuelSurchargesCharges)}
          </td>
        </tr>
      </Fragment>
    );


    const renderDefaultSummary = () => (
      <Fragment>
      {groupedTransportChargesArray?.map((group, index) => (
        <tr key={index}>
          <td width="62.5%" style={{ ...invoiceItem }}>
            {group.name || 'Transportation Charge'}
          </td>
          <td width="37.5%" style={{ ...invoiceItem, ...alignRight }}>
            {group.total === 0 ? '$0.00' : checkNegitive(group.total)}
          </td>
        </tr>
      ))}
      {groupedTransportChargesArray.length === 0 && (
      <tr>
        <td width="62.5%" style={{ ...invoiceItem }}>
            Transportation Charges
        </td>
        <td width="37.5%" style={{ ...invoiceItem, ...alignRight }}>
            $0.00
        </td>
      </tr>
      )}
      <tr>
        <td style={{ ...invoiceItem }}>
          {I18n.t("invoices.invoice_types.fuel_surcharges")}
        </td>
        <td style={{ ...invoiceItem, ...alignRight }}>
          {checkNegitive(totalFuelSurchargesCharges)}
        </td>
      </tr>
    </Fragment>
    );

    const accountWeightUnits = this.props.accountUnitContext;
    return (
      <Fragment>
        {this.state.callInvoceDetails && (
          <div style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              size="small"
              onClick={() => this.handleGenerateQuotation(this.state.order_id)}
            >
              {I18n.t('general.recalculate')}
            </Button>
          </div>
        )}
        {this.state.invoiceDetails.map((order) => {
          const orderRefObj = _.find(smpDetails, {customer_order_number : order.customer_order_number});
          const transportInfo = order?.order_group_type === "TEMPLATE" ? { invoice_lines: [] } : order.billing_info.transportation_charges || { invoice_lines: [] };
          const accessorialInfo = order.billing_info.accessorial_charges || { invoice_lines: []};
          const adjustmentsInfo = order.billing_info.adjustments_charges.invoice_lines
          const totalAdjustmentsCharges = adjustmentsInfo.reduce((acc, curr) => acc + parseFloat(curr.item_value), 0);
          const fuelSuchargesInfo = order?.order_group_type === "TEMPLATE" ? {} : order.billing_info.fuel_surcharges || {};
          const transportationCharge = !_.isEmpty(order.billing_info?.transportation_charges) ? order.billing_info.transportation_charges : {}
          const transportationChargesLines = !_.isEmpty(transportationCharge) && transportationCharge?.invoice_lines?.length > 0 ? transportationCharge.invoice_lines : []
          // const adjustmentsCharges = checkNegitive(
          //   adjustmentsInfo.total_amount,
          //   false,
          // )
          const renderInvoiceLines = adjustmentsInfo.map((line, index) => (
            <tr key={index}>
              <td style={{ ...invoiceItem,...textBold, ...paddingLeft15  }}                               
              colspan="3"
              >
                {line.item_name}{line.added_from === "MANUAL" && <sup className="textRed textBold paddingLeft10">M</sup>}
              </td>
              <td style={{ ...invoiceItem, ...alignRight }}>
                {checkNegitive(line.item_value)}
              </td>
            </tr>
          ));
          const addTotalAmount = (total, info) => {
            const amount = _.get(info, 'total_amount');
            return total + (amount ? parseFloat(amount) : 0);
          };
          totalTransportCharges = addTotalAmount(totalTransportCharges, transportInfo);
          totalAccessorialCharges = addTotalAmount(totalAccessorialCharges, accessorialInfo);
          // totalAdjustmentsCharges = addTotalAmount(totalAdjustmentsCharges, adjustmentsInfo);
          totalFuelSurchargesCharges = addTotalAmount(totalFuelSurchargesCharges, fuelSuchargesInfo);

          const csDetails = order.cs_details || {}
          const whDetails = order.wh_details || {}
          const accessorialLines = accessorialInfo.invoice_lines || [];
          const transportLines = transportInfo?.invoice_lines || [];
          const autoTransportLines = transportLines.filter(line => line.added_from !== 'MANUAL')
          const manualTransportLines = transportLines.filter(line => line.added_from === 'MANUAL')
          const fuelSuchargeLines = fuelSuchargesInfo?.invoice_lines || [];
          const autoFuelSuchargeLines = fuelSuchargeLines.filter(line => line.added_from !== 'MANUAL')
          const manualFuelSuchargeLines = fuelSuchargeLines.filter(line => line.added_from === 'MANUAL')




          let groupedLinesArray = [];

          // If there are transportation lines, group by transportation_charge_name
          if (autoTransportLines.length > 0) {
            // Group by transportation_charge_name, but set default value if missing
            const groupedLines = _.groupBy(autoTransportLines, (line) =>
            this.state.isCustomizedTransportationLabel === "true" ? line?.transportation_charge_name || 'Transportation Charges' : 'Transportation Charges'

            );
            // Convert groupedLines object to an array of { name, total } objects
            groupedLinesArray = Object.keys(groupedLines).map(name => ({
              name,
              total: findNumberTotal(groupedLines[name], 'item_value')
            })
          );
          } else {
            // If no transport lines, manually add "Transportation charges" with $0.00
            groupedLinesArray = [
              {
                name: 'Transportation charges',
                total: '0.00'
              }
            ];
          }

          let i = 0;
          if (order?.order_group_type === "TEMPLATE") {
            allTransportationChargesLines.push(...transportationChargesLines);
          }
          groupedTemplatedLines = this.getTemplateGroup(
            allTransportationChargesLines
          );

          return (
            <div style={{ ...borderLine, ...marginTop20, ...padding5, ...fontSize11 }}>
              <table width="100%">
                <tbody>
                  <tr>
                    <td width="6%">From</td>
                    <td width="1%">:</td>
                    <td width="50%">
                      <AddressInfo
                        address={(whDetails && whDetails.address) || {}}
                        showIcon={false}
                        displayType="line"
                      />
                    </td>
                    <td width="43%" style={{ ...alignRight, ...textBold }}>
                      {I18n.t('order.label')}:&nbsp;&nbsp;{order?.account_details?.code || ''}&nbsp;{order.customer_order_number}
                      {
                      !isLocked && invoiceDetails.length > 1 && <Fragment>
                        &nbsp;
                        <Popconfirm
                          placement="topRight"
                          title={I18n.t("messages.action_confirm", { action: 'delete', field: 'from invoice'})}
                          onConfirm={() => Modal.confirm({
                            title: I18n.t("messages.delete_message_in_invoice", { field: order.customer_order_number}),
                            width: "40%",
                            onOk: () => {
                              this.removeOrder(order.customer_order_id, true)
                            },
                            okText: "Yes",
                            cancelText: "No",
                            onCancel: () => {
                              this.removeOrder(order.customer_order_id, false)
                            }
                          })
                          }
                          okText="Yes"
                          cancelText="No"
                          onCancel={() => {}}
                        >
                          &nbsp; &nbsp;
                          {/* <Icon type="delete" className="textRed cursorPointer fontSize17" /> */}
                          {this.props.showInvoiceActions === true && (
                          <DeleteIcon className="cursorPointer fontSize20"/>
                          )}
                        </Popconfirm>
                      </Fragment>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td width="6%">To</td>
                    <td width="1%">:</td>
                    <td width="50%">
                      {
                        csDetails.company_name ? csDetails.company_name : ''
                      }
                      <AddressInfo
                        address={(csDetails && csDetails.address) || {}}
                        showIcon={false}
                        displayType="line"
                      />
                    </td>
                    <td width="43%" style={{ ...alignRight, ...textBold }}>
                      {I18n.t('order.customer_ref_no')}:&nbsp;
                      {order.customer_order_reference_number ? order.customer_order_reference_number : 'NA'}
                    </td>
                  </tr>
                  <tr>
                    <td colspan='3'></td>
                    <td width="43%" style={{ ...alignRight, ...textBold }}>
                      {I18n.t('order.contact_number')}:&nbsp;
                      {
                        order.consignee_contact ? formatUSPhoneNumber(order.consignee_contact) : 'NA'
                      }
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4">
                      <hr />
                    </td>
                  </tr>
                </tbody>
              </table>
               <table width="100%">
                <tbody>
                  <tr>
                    <td colspan="4" style={{ ...padding5 }}>
                      <Fragment>
                        {this.renderZoneDetails(order, orderRefObj)}
                        {order.order_items && (
                          <table
                            border="1px"
                            cellpadding="5px"
                            cellspacing="0px"
                            width="100%"
                            style={{ ...borderCollapseInitial }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  width="4%"
                                  style={{ ...heading, ...alignCenter }}
                                >
                                  #
                                </td>
                                <td width={this.state.organization.code == 'LK' ? "34%" : "38%"} className="heading">
                                  {I18n.t('order.item_name')}
                                </td>
                                { this.state.organization.code == 'LK' && (
                                  <td width="4%" className="heading">
                                    {I18n.t('order.item_class')}
                                  </td>
                                )}
                                <td width="16%" className="heading">
                                  {I18n.t('order.model_no')}
                                </td>
                                <td width="20%" className="heading">
                                  {I18n.t('order.serial_no')}
                                </td>
                                <td width="4%" className="heading">
                                  {I18n.t('order.pieces')}
                                </td>
                                <td width="18%" className="heading">
                                  {I18n.t('order.weight')}
                                </td>
                              </tr>
                              {
                                //order.order_items.sort((a, b) => (a.name || '').localeCompare(b.name || '')).map((item, index) => {

                                order.order_items.map((item, index) => {
                                const rowSpan = findSpanValue(order.order_items, item, index)
                                i = rowSpan !== 0 ? i + 1 : i;
                                return (
                                  <Fragment>
                                  <tr>
                                    {rowSpan !== 0 && <td
                                      style={{ ...invoiceItem, ...alignCenter }}
                                      rowSpan={rowSpan}
                                    >
                                      {i}
                                    </td>
                                    }
                                    <td style={{ ...invoiceItem }}>
                                      {item.name}
                                    </td>
                                    { this.state.organization.code == 'LK' && (
                                      <td style={{ ...invoiceItem }}>
                                        {item.item_class || ''}
                                      </td>
                                    )}
                                    <td style={{ ...invoiceItem }}>
                                      {item.item_model}
                                    </td>
                                    <td style={{ ...invoiceItem }}>
                                      {item.serial_number}
                                    </td>
                                    <td
                                      style={{ ...invoiceItem, ...alignCenter }}
                                    >
                                      {item?.item_quantity}
                                    </td>
                                    <td
                                      style={{ ...invoiceItem, ...alignCenter }}
                                    >
                                        {accountWeightUnits[this.state.account.code] === 'kgs' 
                                            ? `${Number(item.weight_in_kgs || 0).toFixed(
                                                Number(_.get(userContextData, "orgRules.org_billing_rules.round_off_decimals", "2"))
                                              )} kgs`
                                            : `${Number(item.weight || 0).toFixed(
                                                Number(_.get(userContextData, "orgRules.org_billing_rules.round_off_decimals", "2"))
                                              )} lbs`
                                        }
                                    </td>
                                  </tr>

                                  {/* {order.price_list // filter(invoiceItem => invoiceItem.invoice_value !== 0)
                        .map((item, itemIndex) => (
                          <tr>
                            <td style={{ ...invoiceItem, ...alignCenter }}>
                              {sectionType === "adjustment_charges"
                                ? itemIndex + 1
                                : ""}
                            </td>
                            <td style={{ ...invoiceItem }}>
                              {item.invoice_name}{" "}
                              {item.item_description ? (
                                <i style={{ ...fontSize10 }}>
                                  {" "}
                                  {item.item_description}{" "}
                                </i>
                              ) : (
                                ""
                              )}
                            </td>
                            <td style={{ ...invoiceItem }}>
                              {!isEmpty(item.invoice_quantity) &&
                              item.invoice_quantity !== 0 ? (
                                item.invoice_quantity
                              ) : (
                                <Fragment>&nbsp;</Fragment>
                              )}
                            </td>
                            <td style={{ ...invoiceItem, ...alignRight }}>
                              {checkNegitive(item.invoice_value)}
                            </td>
                          </tr>
                        ))} */}
                                </Fragment>)
                             })}
                             {/* Totals for quantity and weight */}
                              <tr>
                                <td colSpan={this.state.organization.code == 'LK' ? "5" : "4"} style={{ textAlign: "right" }}>
                                  Total:
                                </td>
                                <td style={{ ...invoiceItem, ...alignCenter }}>
                                  { !isEmpty(order.total_quantity) ? order.total_quantity : 0 }
                                </td>
                                <td style={{ ...invoiceItem, ...alignCenter }}>
                                  {accountWeightUnits[this.state.account.code] === 'kgs'
                                      ? `${Number(order.weight_in_kgs || 0).toFixed(
                                          Number(_.get(userContextData, "orgRules.org_billing_rules.round_off_decimals", "2"))
                                        )} kgs`
                                      : `${Number(order.total_weight || 0).toFixed(
                                          Number(_.get(userContextData, "orgRules.org_billing_rules.round_off_decimals", "2"))
                                        )} lbs`
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        )}
                      </Fragment>
                    </td>
                  </tr>
                  <tr>
                        <td
                          style={{ ...invoiceItem, ...textBold, height: 20 }}
                          colspan="5"
                        >
                        
                        </td>
                  </tr>
                  {order.billing_info && (
                    <Fragment>
                      <tr>
                        <td
                          style={{ ...invoiceItem, ...textBold }}
                          colspan="4"
                        >
                          Charges
                        </td>
                      </tr>
                      {order?.order_group_type === "TEMPLATE" ? (
                        transportationChargesLines.map((line, index) => (
                        <tr key={index}>
                          <td
                            style={{ ...invoiceItem, ...textBold, ...paddingLeft15 }}
                            colSpan="3"
                          >
                            {line.item_name}
                          </td>
                          <td style={{ ...invoiceItem, ...alignRight }}>
                            {currencyConversion(line.item_value)}
                          </td>
                        </tr>
                      ))
                      ) : (
                        <Fragment>
                            {groupedLinesArray.map((group, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                ...invoiceItem,
                                ...textBold,
                                ...paddingLeft15,
                              }}
                              colspan="3"
                            >
                             {group.name}
                            </td>
                            <td style={{ ...invoiceItem, ...alignRight }}>
                              {/* {currencyConversion(transportationCharges)} */}
                              {currencyConversion(group.total)}
                            </td>
                          </tr>
                         ))}
                          {manualTransportLines.map((line) => (
                            <tr>
                              <td
                                style={{
                                  ...invoiceItem,
                                  ...textBold,
                                  ...paddingLeft15,
                                }}
                                colspan="3"
                              >
                                <span style={{ ...paddingLeft5 }}>
                                  {line.item_name}
                                </span>
                                {line.added_from === "MANUAL" && (
                                  <sup className="textRed textBold paddingLeft10">
                                    M
                                  </sup>
                                )}
                              </td>
                              <td style={{ ...invoiceItem, ...alignRight }}>
                                {checkNegitive(line.item_value)}
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td
                              style={{
                                ...invoiceItem,
                                ...textBold,
                                ...paddingLeft15,
                              }}
                              colspan="3"
                            >
                              {I18n.t("invoices.invoice_types.fuel_surcharges")}{" "}
                            </td>
                            <td style={{ ...invoiceItem, ...alignRight }}>
                              {/* {currencyConversion(fuelsurCharges)} */}
                              {currencyConversion(
                                findNumberTotal(
                                  autoFuelSuchargeLines,
                                  "item_value"
                                )
                              )}
                            </td>
                          </tr>
                          {manualFuelSuchargeLines.map((line) => (
                            <tr>
                              <td
                                style={{
                                  ...invoiceItem,
                                  ...textBold,
                                  ...paddingLeft15,
                                }}
                                colspan="3"
                              >
                                <span style={{ ...paddingLeft5 }}>
                                  {line.item_name}
                                </span>
                                {line.added_from === "MANUAL" && (
                                  <sup className="textRed textBold paddingLeft10">
                                    M
                                  </sup>
                                )}
                              </td>
                              <td style={{ ...invoiceItem, ...alignRight }}>
                                {checkNegitive(line.item_value)}
                              </td>
                            </tr>
                          ))}
                          {accessorialLines
                            .sort((a, b) =>
                              (a.item_name || "").localeCompare(
                                b.item_name || ""
                              )
                            )
                            .map((line) => (
                              <tr>
                                <td
                                  style={{
                                    ...invoiceItem,
                                    ...textBold,
                                    ...paddingLeft15,
                                  }}
                                  colspan="3"
                                >
                                  {line.item_name}
                                  {line.added_from === "MANUAL" && (
                                    <sup className="textRed textBold paddingLeft10">
                                      M
                                    </sup>
                                  )}
                                </td>
                                <td style={{ ...invoiceItem, ...alignRight }}>
                                  {checkNegitive(line.item_value)}
                                </td>
                              </tr>
                            ))}
                        </Fragment>
                      )}
                   {totalAdjustmentsCharges > 0 && (
                        <>
                          <tr>
                            <td style={{ ...invoiceItem, ...textBold}}>
                              {I18n.t(
                                "invoices.invoice_types.adjustment_charges"
                              )}{" "}
                              charges
                            </td>
                          </tr>
                          {renderInvoiceLines}
                        </>
                      )}
                    </Fragment>
                  )}
                  <tr>
                    <td
                      style={{ ...invoiceItem, ...alignRight, ...textBold }}
                      colspan="3"
                    >
                      {I18n.t('invoices.total')}
                    </td>
                    <td style={{ ...invoiceItem, ...alignRight }}>
                      {checkNegitive(order.total_amount)}
                    </td>
                  </tr>
                  {/*<table width="100%" style={{ ...marginTop20 }}>
              <tbody>
                <tr>
                  <td width="62.5%" style={{ ...marginTop40 }} />
                  <td width="37.5%">
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td
                            width="42%"
                            style={{ ...alignRight, ...totalItems }}
                          >
                            {I18n.t("invoices.sub_total")}
                          </td>
                          <td
                            width="58%"
                            style={{
                              ...alignRight,
                              ...totalItems,
                              ...textBold,
                            }}
                          >
                            {this.findSectionTotal(sectionType)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table> */}
                </tbody>
              </table>
            </div>
          )
        })}

        <table width="100%" style={{ ...marginTop20 }} border="1">
          <tbody>
            <tr>
              {' '}
              <td
                colspan="2"
                style={{ ...alignCenter, ...textBold, ...paddingTop5 }}
              >
                {' '}
                *** Summary ***{' '}
              </td>
            </tr>
            
            {groupedTemplatedLines?.length > 0 && groupedTemplatedLines.map((item, index) => (
              <tr key={index}>
                <td width="62.5%" style={{ ...invoiceItem }}>
                  {item.item_name}
                </td>
                <td width="37.5%" style={{ ...invoiceItem, ...alignRight }}>
                  {checkNegitive(item.item_total_value)}
                </td>
              </tr>
            ))}
            
            {hasOrderGroupedInvoice && (
              this.state.isCustomizedTransportationLabel === "false" ? renderCustomTransportationSummary() : renderDefaultSummary()
            )}

            {Object.entries(this.state.invoiceLinesSummary).sort(([keyA], [keyB]) => keyA.localeCompare(keyB)).map(([key, value]) => {
              return (<tr> <td style={ { ...invoiceItem } }> { key } </td> <td style={ { ...invoiceItem, ...alignRight } }> { checkNegitive(value) } </td> </tr>);
            }
            ) }
            {renderAdjForTable}
          </tbody>
        </table>
        <table width="100%">
          <tbody>
            <tr>
              <td width="62.5%" style={{ ...marginTop40 }}>
                {this.state.showOtherDetails && this.renderComments()}
              </td>
              <td width="37.5%" style={{ ...paddingTop20 }}>
                <table width="100%">
                  <tbody>
                    <tr>
                      <td width="42%" style={{ ...alignRight, ...totalItems }}>
                        {I18n.t('invoices.taxable')}
                      </td>
                      <td
                        width="58%"
                        style={{ ...alignRight, ...totalItems, ...textBold }}
                      >
                        {checkNegitive(this.state.invoice.taxable)}
                      </td>
                    </tr>
                    <tr>
                      <td
                        width="42%"
                        style={{ ...alignRight, ...totalItems, ...textBold }}
                      >
                        {I18n.t('invoices.total')}
                      </td>
                      <td
                        width="58%"
                        style={{ ...alignRight, ...totalItems, ...textBold }}
                      >
                        {/* {currencyConversion(
                          totalTransportCharges +
                            totalAccessorialCharges +
                            totalAdjustmentsCharges +
                            totalFuelSurchargesCharges
                        )} */}
                        {totalAmount}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" style={{ height: 85 }} />
                    </tr>
                    {/* {this.state.showOtherDetails && (
                      <tr>
                        <td
                          colspan="2"
                          style={{
                            ...alignCenter,
                            ...textBold,
                            ...totalItems,
                          }}
                        >
                          [{' '}
                          {this.state.invoice.organization_name ||
                            'Company Name'}{' '}
                          ]
                        </td>
                      </tr>
                    )} */}
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </Fragment>
    )
  }
  renderSignatutes = () => {
    const podPics = this.state.invoice.pod_pictures
      ? this.state.invoice.pod_pictures
      : []
    const signaturePics = []
    podPics.forEach((picData) => {
      if (picData.pictures) {
        const signs = picData.pictures
          .filter(
            (pic) =>
              pic.image_type === 'signature' && pic.picture && pic.picture.url,
          )
          .map((pic) => {
            return { url: pic.picture.url, sign_by: pic.sign_by }
          })
        if (signs.length > 0) {
          signaturePics.push({
            sign: signs[0].url,
            order_number: picData.customer_order_number,
            sign_by: signs[0].sign_by,
          })
        }
      }
    })
    return (
      <Fragment>
        {signaturePics.map((pic, index) =>
          index === 0 ? (
            <div
              class="textCenter"
              style={{
                width: '100%',
                ...floatLeft,
                ...borderLine,
                ...padding5,
                ...marginTop10,
                ...marginLeft10,
              }}
            >
              <div>
                <img src={pic.sign} alt="sign" style={{ width: '100%' }} />
              </div>
              <div style={{ ...alignCenter, ...textBold }}>
                Sign By: {pic.sign_by}
              </div>
            </div>
          ) : null,
        )}
      </Fragment>
    )
  }

  renderPodPictures = () => {
    const podPics = this.state.invoice.pod_pictures
      ? this.state.invoice.pod_pictures
      : []
    const normalPics = []
    podPics.forEach((picData) => {
      if (picData.pictures) {
        const signs = picData.pictures
          .filter(
            (pic) =>
              pic.image_type === 'normal' && pic.picture && pic.picture.url,
          )
          .map((pic) => {
            return { url: pic.picture.url, pic_title: pic.pic_title }
          })
        if (signs.length > 0) {
          normalPics.push({
            sign: signs[0].url,
            order_number: picData.customer_order_number,
          })
        }
      }
    })
    return (
      <Fragment>
        {normalPics.map((pic, index) => (
          <div
            class="textCenter"
            style={{
              width: '100%',
              ...floatLeft,
              ...borderLine,
              ...padding5,
              ...marginTop10,
              ...marginLeft10,
            }}
          >
            <div>
              <img src={pic.sign} alt="PIC" style={{ width: '100%' }} />
            </div>
            <div style={{ ...alignCenter }}>Order No: #{pic.order_number}</div>
          </div>
        ))}
      </Fragment>
    )
  }

  _renderInvoce = () => {
    return (
      <Spin spinning={this.state.inProgress} delay={1000}>
        {false && this.state.showHeading && this.renderWHDetails()}
        {this.state.showHeading && this.renderAccountDetails()}
        <hr />
        {false && this.state.showOtherDetails && this.renderShipperDetails()}
        {this.renderDetails()}
        {this.state.showOtherDetails && (
          <Fragment>
            {this.renderPodPictures()}
            {this.renderSignatutes()}
            <Row>
              <Col
                xs={24}
                className="alignCenter smallText marginTop40 fontSize15"
              >
                For all terms & conditions please refer{' '}
                <a href="https://fleetenable.com/">
                  <u className="text-decoration-dashed">here</u>
                </a>
              </Col>
            </Row>
            {this.props.organizationSettings?.org_invoice_disclaimer && (
              <>
            <hr/>
            <Row type="flex" className='marginTop5'>
              <Col span={24} className='fontSize11'>
              <span className='textBold fontSize13 marginRight10'>{"Disclaimer :"}</span>{this.props.organizationSettings.org_invoice_disclaimer}
              </Col>
            </Row>
            </>
          )}
          </Fragment>
        )}
      </Spin>
    )
  }

  render() {
    const { account, isLocked } = this.state
    return (
      <div className="invoice-box">
        <this._renderInvoce />
      </div>
    )
  }
}

// export default Invoice



export default withRouter((props) => {
  const accountUnitContext = useContext(AccountUnitsContext);
  return (
    <Invoice {...props} accountUnitContext={accountUnitContext.accountWeightUnits} />
  )
})

Invoice.propTypes = {
  setInvoiceDetails: PropTypes.func,
  showActions: PropTypes.bool,
};
 Invoice.defaultProps = {
  setInvoiceDetails: () => {},
  showActions: true
}
