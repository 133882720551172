import React, { Fragment } from "react";
import { Row, Col, Popover } from "antd";
import { Link } from "../../common/UIComponents";
import AppConfig from "../../config/AppConfig";
import AddressInfo from "../common/AddressInfo";
import { retrieveOrderValue } from "../../helpers/orders";
import I18n from "../../common/I18n";
import BaseList from "../BaseList";
import CustomerOrderDetails from "./CustomerOrderDetails";
import { isEmpty } from "../../common/Common";
import TypeOfOrder from "./TypeOfOrder";
import { getFormattedWeight } from "../../helpers/common";

export default class ConsolidatedMawbsList extends BaseList {
  constructor(props) {
    super(props);
    const setAddress = (data, type) => {
      const sourcePoint =
        data?.origin && Object.keys(data.origin).length !== 0
          ? data.origin
          : data.warehouse_address;
      const destinationPoint =
        data?.destination && Object.keys(data.destination).length !== 0
          ? data.destination
          : ["T", "LH"].includes(data.type_of_order)
          ? data.warehouse_address
          : data.customer_address;
      const pickUpAddress = (
        <Popover
        title={
            data?.origin?.city
              ? I18n.t("general.origin_location")
              : I18n.t("general.warehouse_address")
          }
          content={<AddressInfo address={sourcePoint} />}
          overlayStyle={{ width: 200 }}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        >
          {sourcePoint && sourcePoint.city
            ? `${
                sourcePoint.location_code
                  ? sourcePoint.location_code
                  : sourcePoint.city
              }`
            : "NA"}
        </Popover>
      );

      const dropAddress = (
        <Popover
          title={
            data?.destination?.city
              ? I18n.t("general.destination_location")
              : !["T", "LH"].includes(data.type_of_order)
              ? I18n.t("general.customer_address")
              : I18n.t("general.warehouse_address")
          }
          content={<AddressInfo address={destinationPoint} />}
          overlayStyle={{ width: 200 }}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        >
          {destinationPoint && destinationPoint.city
            ? `${
                destinationPoint.location_code
                  ? destinationPoint.location_code
                  : destinationPoint.city
              }`
            : "NA"}
        </Popover>
      );
      if (type === "origin") {
        if (["R", "TR"].includes(data.type_of_order)) {
          return dropAddress;
        }
        return pickUpAddress;
      }
      if (["R", "TR"].includes(data.type_of_order)) {
        return pickUpAddress;
      }
      return dropAddress;
    };
    this.columns = [
      {
        key: "customer_order_number",
        title: I18n.t("order.order_no"),
        dataIndex: "customer_order_number",
        render: (text, record) => {
          return (
          <Row type="flex" align="middle" gutter={ 4 }>
            <Col>
            <TypeOfOrder
              order={record}
              orderTypeKey="order_type"
              relatedOrderKey="related_order"
              placement="topLeft"
              showBadge={ true }
              // key={`TypeOfOrder${record.id}`}
            />
            </Col>
            <Col>
            <CustomerOrderDetails
              order_no={text}
              order={{
                id: record.customer_order_id,
                customer_order_number: text,
              }}
              showEdit={false}
            />
          </Col>
          </Row>
          )
        },
      },
      {
        key: "status",
        title: I18n.t("general.status"),
        dataIndex: "status",
        render: (data, record) => retrieveOrderValue(record, "status", true),
      },
      {
        key: "hawb",
        title: I18n.t("general.hawb"),
        dataIndex: "Hawb",
        render: (data, record) => <span>{data}</span>,
      },
      {
        key: "city",
        title: "Origin -> Destination",
        dataIndex: "warehouse_address",
        render: (text, data) => (
          <div style={ { display: "flex" } }>
            <div>{ setAddress(data, "origin") }</div>
            <span>&nbsp;{ " - " }&nbsp;</span>
            <div>{ setAddress(data, "destination") }</div>
          </div>
        ),
      },
      {
        key: "weight",
        title: (
          <Fragment>
            <Row>
              <Col className="alignCenter">
                {I18n.t("order.weight_short_form")}
              </Col>
            </Row>
          </Fragment>
        ),
        dataIndex: "weight",
        render: (data, record) => getFormattedWeight(data, record.weight_in_kgs, Number(this.props?.orgRules?.org_billing_rules?.round_off_decimals)) || '',
      },
      {
        key: "items_count",
        title: `${I18n.t("order.qty")}`,
        dataIndex: "pieces",
      },
      {
        key: "ready_for_billing",
        title: I18n.t("general.ready_for_billing"),
        dataIndex: "ready_for_billing",
        render: (data, record) => <span>{data}</span>,
      },
      {
        key: "invoice",
        title: I18n.t("general.invoice_stat"),
        dataIndex: "invoice",
        render: (data, record) => <span>{data}</span>,
      },
    ];
  }
}

